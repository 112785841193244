

@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-payment-row {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.patient-care-payment-row .h3 {
  font-weight: 700;
}

.patient-care-payment-item {
  align-items: center;
  display: flex;
}

.patient-care-status-list {
  border-radius: $border-radius-large;
}

.patient-care-status-list-disabled {
  opacity: .5;
  pointer-events: none;
}

.patient-care-status-item {
  border-radius: $border-radius-large;
  border: 1px solid $color-grey-light;
  color: $color-grey-dark;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: $ratio-small;
  min-width: $spacing-larger * 2 + $spacing-small;
  padding-bottom: ($spacing-smaller + 3px);
  padding-top: ($spacing-smaller + 3px);
  text-align: center;
  transition: background-color .3s;
  vertical-align: middle;
  background: $color-white;
}

.patient-care-status-item:first-child {
  margin-right: -$spacing-larger;
  padding-left: $spacing-small;
  padding-right: $spacing-small * 3;
}

.patient-care-status-item.active:first-child {
  padding-right: $spacing-small;
}

.patient-care-status-item:last-child {
  margin-left: -$spacing-larger;
  padding-left: $spacing-large * 2;
  padding-right: $spacing-large;
}

.patient-care-status-item.active:last-child {
  background-color: $color-status-success;
  color: $color-white;
  padding-left: $spacing-large;
}

.patient-care-status-item:hover {
  background-color: $color-grey-lighter;
}

.patient-care-status-item.active {
  background-color: $color-grey-light;
  box-shadow: $box-shadow-normal;
  color: $color-primary;
  margin: 0;
  position: relative;
  z-index: 1;
}
