// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-button-delete


// patient-care-button-delete
.patient-care-button-delete {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: $size-form-medium;
  width: $size-form-medium;
  border: 1px solid $color-grey-dark;
  border-radius: 50%;
  transition: background .3s ease-in-out, border .3s ease-in-out;
  position: relative;
}

.patient-care-button-delete i,
.patient-care-button-delete svg {
  height: $size-icon-medium;
  width: $size-icon-medium;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.patient-care-button-delete svg * {
  transition: fill .3s ease-in-out;
  fill: $color-grey-dark;
}

.patient-care-button-delete:hover {
  background: $color-status-error;
  border-color: $color-status-error;
}

.patient-care-button-delete:hover svg * {
  fill: $color-white;
}
