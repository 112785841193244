

@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-row-status {
  align-items: center;
  border-radius: $border-radius-small;
  color: $color-white;
  display: flex;
  height: $spacing-larger * 2 - $spacing-smaller / 2;
  margin-bottom: $spacing-medium;
  padding: $spacing-small $spacing-medium;
}

.patient-care-cancelled-status {
  background-color: $color-appointment-status;
  border-radius: $border-radius-small;
  color: $color-white;
  font-size: $font-size-large;
  margin-bottom: $spacing-medium;
  padding: $spacing-small $spacing-medium;
}

.patient-care-cancelled-status b {
  display: block;
}

.patient-care-row-status.past {
  background-color: $color-accent;
}

.patient-care-row-status.grey-light {
  background-color: $color-upcoming;
}

.patient-care-row-status.neutral-grey {
   background-color: $color-grey-lighter;
}

.patient-care-row-status.green {
  background-color: $color-service-provided;
}

.patient-care-row-status.orange {
  background-color: $color-support-two;
}

.patient-care-row-status.red {
  background-color: $color-appointment-status;
}

.patient-care-row-status.pending {
  background-color: $color-appointment-pending;
}

.patient-care-row-status.peach {
  background-color: $color-customer-not-attended;
}

.patient-care-row-status.pending {
  background-color: $color-appointment-pending;
}

.patient-care-big-input-select {
  width: 100%;
}

.patient-care-status-title {
  color: $color-white;
  font-weight: 700;
  margin-right: $spacing-smaller;
  white-space: nowrap;
}

.patient-care-row-status.grey-light .patient-care-status-title {
  color: $color-primary;
}

.patient-care-row-status.neutral-grey .patient-care-status-title {
  color: $color-primary;
}

.patient-care-row-status .patient-care-select .patient-care__control {
  border-color: $color-white;
}