
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-nav-list {
  align-items: center;
  display: flex;
}

.patient-care-nav-item {
  margin-right: $spacing-smaller;
  position: relative;
}

.patient-care-nav-item.has-dropdown {
  align-items: center;
  display: flex;
}

.patient-care-nav-item:first-child {
  @media (max-width: $desktop-small) {
    max-width: calc(100% - 83px);
  }
}

.patient-care-nav-item:last-child {
  margin-right: 0;

  @media (max-width: $desktop-small) {
    width: 83px;
  }
}

.patient-care-nav-link {
  width: 100%;
  color: $color-grey-dark;
  display: block;
  font-weight: 900;
  line-height: $ratio-small;
  padding: $spacing-larger $spacing-small $spacing-medium;
  position: relative;
  text-decoration: none;
}

.patient-care-nav-link.has-dropdown {
  padding: $spacing-larger 0 $spacing-medium $spacing-small;
}

.patient-care-nav-item.active a {
  color: $color-primary;
}

.patient-care-nav-item.active.active-calendar a {
  color: $color-accent;
}

.patient-care-nav-item.active:hover a,
.patient-care-nav-item:hover a {
  color: $color-accent;
}

.patient-care-nav-item.active svg * {
  fill: $color-primary;
}

.patient-care-link-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.patient-care-dropdown-opener .ui-kit-link-icon {
  padding: $spacing-larger $spacing-small $spacing-medium 0;
 }

 .patient-care-nav-item .patient-care-dropdown-opener svg * {
  fill: $color-grey-dark;
}

.patient-care-nav-item.active.active-calendar .patient-care-dropdown-opener svg *,
.patient-care-nav-item.active:hover .patient-care-dropdown-opener svg *,
.patient-care-nav-item:hover .patient-care-dropdown-opener svg * {
  fill: $color-accent;
}

.patient-care-nav-item.active .patient-care-dropdown-opener svg * {
  fill: $color-primary;
}

.patient-care-nav-item:after {
  background-color: $color-accent;
  bottom: 0;
  content: "";
  height: ($spacing-smaller - 2);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: width .3s;
  width: 0;
}

.patient-care-nav-item:hover:after,
.patient-care-nav-item.active:after {
  width: 100%;
}
