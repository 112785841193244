// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-basic-download:overlay-confirmation-content
// reports-basic-download:overlay-confirmation-buttons
// screen-medium
// screen-medium:reports-basic-download:overlay-confirmation-buttons


// reports-basic-download:overlay-confirmation-content
.reports-basic-download.overlay-confirmation-content p {
  margin: 0;
}


// reports-basic-download:overlay-confirmation-buttons
.reports-basic-download.overlay-confirmation-buttons > .ui-kit-button:hover {
  color: $color-white;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:reports-basic-download:overlay-confirmation-buttons
  .reports-basic-download.overlay-confirmation-buttons > .ui-kit-button + .ui-kit-button {
    margin-left: $spacing-small;
  }

}
