// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// reports-basic-chart:branch
// screen-desktop-small
// screen-desktop-small:reports-basic-chart:branch
// screen-desktop-medium
// screen-desktop-medium:reports-basic-chart:branch


// reports-basic-chart:branch
.reports-basic-chart.branch,
.reports-basic-chart.branch .reports-basic-loader {
  height: 480px; // non-standard
}

.reports-basic-chart.branch {
  overflow-y: auto;
  margin-right: -($spacing-smaller);
  margin-left: -($spacing-smaller);
}

.reports-basic-chart.branch .recharts-yAxis text {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  fill: $color-primary !important;
}

.reports-basic-chart.branch .recharts-text {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  fill: $color-grey-dark !important;
}


// screen-desktop-small
@media #{$screen-desktop-small} {

  // screen-desktop-small:reports-basic-chart:branch
  .reports-basic-chart.branch {
    height: 380px;  // non-standard
    margin-bottom: -($spacing-medium);
    padding-bottom: $spacing-medium;
  }

  .reports-basic-chart.branch .reports-basic-loader {
    height: 380px - $spacing-medium;
  }

}


// screen-desktop-medium
@media #{$screen-desktop-medium} {

  // screen-desktop-medium:reports-basic-chart:branch
  .reports-basic-chart.branch {
    height: 480px;  // non-standard
  }

  .reports-basic-chart.branch .reports-basic-loader {
    height: 480px - $spacing-medium;
  }

}
