// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-legend-item


// reports-legend-item
.reports-legend-item {
  align-items: center;
  font-size: $font-size-small;
  line-height: rounnd($font-size-small * $ratio-small);
  display: flex;
  margin: ($spacing-smaller / 2);
}

.reports-legend-item i {
  border-radius: $border-radius-large;
  display: block;
  flex-shrink: 0;
  height: $size-icon-medium;
  width: $size-icon-medium;
}

.reports-legend-item span {
  margin-left: $spacing-smaller;
  margin-right: $spacing-smaller;
}
