@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-availability-switch
// patient-care-availability-select


// patient-care-availability-switch
.patient-care-availability-switch {
  display: flex;
  align-items: center;
}

.patient-care-availability-switch > div {
  margin-left: $spacing-smaller;
}


// patient-care-availability-select
.patient-care-availability-select {
  width: ($size-medium / 2);
}

.patient-care-availability-select b {
  display: block;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  padding: 10px 17px; // non-standard
}
