// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:branch-services-summary


// react-table:branch-services-summary
.react-table.branch-services-summary tr th.cell-alert,
.react-table.branch-services-summary tr td.cell-alert {
  width: 90px;
}

.react-table.branch-services-summary tr td.cell-alert .group svg {
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.react-table.branch-services-summary tr td.cell-alert .group.warning svg * {
  fill: $color-status-error;
}

.react-table.branch-services-summary tr th.cell-main,
.react-table.branch-services-summary tr td.cell-main {
  width: 40%;
}
