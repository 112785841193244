@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';


// ui-kit-form:disabled-inputs
.ui-kit-form input[disabled] {
  opacity: .5;
}

// logo-nhs:colors
.patient-care-app svg.logo-nhs .logo-nhs-background {
  fill: $color-category-nhs;
}

.patient-care-app svg.logo-nhs .logo-nhs-logo {
  fill: $color-white;
}

// ui-kit-button-disabled-visually
.patient-care-app .ui-kit-button-disabled-visually {
  cursor: default;
  opacity: .5;
}

.patient-care-app .ui-kit-button-disabled-visually.ui-kit-button-secondary:hover {
  background: $color-accent;
}

.patient-care-app .ui-kit-form .input-search input {
  line-height: $size-form-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-app .ui-kit-form .input-search-icon svg * {
  fill: $color-accent;
}

.patient-care-app .ui-kit-form .input-radio ::before,
.patient-care-app .ui-kit-form .input-radio ::after {
  box-sizing: content-box;
}

//  browser specific prefix!!!
.patient-care-app .ui-kit-form ::-moz-placeholder {
  opacity: 1;
}

.patient-care-app .ui-kit-form .input-search-icon button:hover svg *,
.patient-care-app .ui-kit-form .input-search-icon button:focus svg * {
  fill: rgba($color-accent, .7);
}

.patient-care-app .ui-kit-form .input-checkbox span::before {
  box-sizing: content-box;
}


.patient-care-btn-in-group.pretend-disabled {
  opacity: .5;
}

.patient-care-btn-in-group.pretend-disabled:hover,
.patient-care-btn-in-group.pretend-disabled:focus {
  background: $color-accent;
  opacity: .5;
  cursor: default;
}

// snackbar styles
.patient-care-app .ui-kit-snackbar {
  z-index: 99999;
}

.ui-kit-snackbar p {
  margin-bottom: 0;
}

// pagination styles
.patient-care-app .ui-kit-pagination-active a,
.patient-care-app .ui-kit-pagination-active a:hover,
.patient-care-app .ui-kit-pagination-active a:focus,
.patient-care-app .ui-kit-pagination-active a:active {
  background: $color-accent;
}

.patient-care-app .ui-kit-pagination-button a:hover {

  @media (min-width: $desktop-small) {
    background: $color-accent;
    border-color: $color-accent;
  }
}

.ui-kit-overlay .ui-kit-overlay-appointment .overlay-appointment-content form {
  margin-top: $spacing-small;
}

// table styles
.patient-care-app .handsontable {
  position: static;
  z-index: 1;
}

.patient-care-app .innerBorderTop + .handsontable thead {
  box-shadow: $box-shadow-table;
}

.patient-care-app .ht_master .wtHolder {
  overflow: initial;
  height: 100% !important;
}

.patient-care-app .handsontable th {
  background-color: $color-grey-lighter;
  border-bottom: 1px solid $color-grey-light !important;
  border-right: 0;
  color: $color-grey-dark;
  font-weight: 700;
  text-align: left;
}

.patient-care-app .handsontable .htDimmed {
  color: $color-primary;
}

.patient-care-app .handsontable .wtBorder {
  display: none !important;
}

.patient-care-app .handsontable thead th:first-child .relative {
  padding-left: $spacing-large;
}

.patient-care-um-table .patient-care-cell.patient-care-last-cell {
  padding-right: $spacing-large;
  text-align: right;
}

.patient-care-app .handsontable thead th:last-child .relative {
  padding-right: $spacing-large;
}

.patient-care-app .handsontable th {
  border-bottom-width: 0;
  border-right-width: 0;
}

.patient-care-app .handsontable th .relative {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-app .handsontable thead th .relative {
  padding: $spacing-smaller $spacing-small;
}

.patient-care-app .handsontable tr:first-child th,
.patient-care-app .handsontable tr:first-child td {
  border-top: 0;
}

.patient-care-app .handsontable th:first-child,
.patient-care-app .handsontable th:nth-child(2),
.patient-care-app .handsontable td:first-of-type,
.patient-care-app .handsontable .htNoFrame + th,
.patient-care-app .handsontable .htNoFrame + td {
  border-left: 0;
}

.patient-care-app .handsontable tbody th.ht__highlight,
.patient-care-app .handsontable thead th.ht__highlight,
.patient-care-app .handsontable tbody th.ht__active_highlight,
.patient-care-app .handsontable thead th.ht__active_highlight {
  background-color: $color-grey-lighter;
  color: $color-grey-dark;
}

.patient-care-app .handsontable span.colHeader.sortAction:hover,
.patient-care-app .handsontable span.colHeader.columnSorting.ascending,
.patient-care-app .handsontable span.colHeader.columnSorting.descending {
  color: $color-accent;
}

.handsontable span.colHeader.columnSorting::before {
  margin-top: -$spacing-smaller + 3px;
  right: -$spacing-small;
}

.patient-care-app .handsontable span.colHeader.columnSorting.ascending::before {
  background-image: url("./../../assets/images/IconSortUp.png");
}

.patient-care-app .handsontable span.colHeader.columnSorting.descending::before {
  background-image:  url("./../../assets/images/IconSortDown.png");
}

.patient-care-app .handsontable .columnSorting.sortAction:hover {
  text-decoration: none;
}

.patient-care-app .handsontable td.area:before {
  display: none;
}

.patient-care-app .handsontable td.area::after {
  opacity: 0.1;
}

// styles for custom selects
.patient-care-select .patient-care__input input {
  height: auto;
}

.patient-care-app .patient-care__control,
.patient-care-app .patient-care__menu,
.patient-care-select .patient-care__control,
.patient-care-select .patient-care__menu {
  font-size: $font-size-medium;
  font-weight: 700;
}

.patient-care-app .patient-care__control:hover,
.patient-care-select .patient-care__control:hover {
  border-color: $color-grey-light;
}

.patient-care-app .patient-care__control.patient-care__control--is-focused,
.patient-care-select .patient-care__control.patient-care__control--is-focused {
  border-color: $color-accent;
}

.patient-care-app .patient-care__single-value,
.patient-care-select .patient-care__single-value {
  color: $color-primary;
  margin: 0;
  max-width: calc(100% - 18px);
}

.patient-care-app .patient-care__single-value + input,
.patient-care-select .patient-care__single-value + input {
  height: $spacing-medium;
}

.patient-care-app .patient-care__control,
.patient-care-select .patient-care__control {
  background-color: $color-white;
  border-color: $color-grey-light;
  box-shadow: none;
  line-height: $ratio-medium;
}

.patient-care-app .patient-care-select-error .patient-care__control,
.patient-care-select.patient-care-select-error .patient-care__control {
  border-color: $color-status-error;
}

.patient-care-app .patient-care__value-container,
.patient-care-select .patient-care__value-container {
  line-height: $ratio-medium;
  padding: ($spacing-smaller + 1px) 0 ($spacing-smaller + 1px) $spacing-small;
}

.patient-care-app .patient-care__value-container input,
.patient-care-select .patient-care__value-container input {
  color: $color-primary !important;
  font-weight: 700;
  line-height: $ratio-small;
}

.patient-care-app .patient-care__value-container .css-1g6gooi,
.patient-care-select .patient-care__value-container .css-1g6gooi {
  margin: 0;
  padding: 0;
}

.patient-care-app .patient-care__indicator-separator,
.patient-care-select .patient-care__indicator-separator {
  background-color: transparent;
}

.patient-care-app .patient-care__indicator.patient-care__dropdown-indicator,
.patient-care-select .patient-care__indicator.patient-care__dropdown-indicator {
  color: $color-grey;
  padding: $spacing-smaller + 2px  $spacing-smaller + 2px $spacing-smaller + 2px 0;
}

.patient-care-app .patient-care__indicator.patient-care__dropdown-indicator svg,
.patient-care-select .patient-care__indicator.patient-care__dropdown-indicator svg {
  height: $spacing-medium - 2px;
  width: $spacing-medium;
}

.patient-care-app .patient-care__placeholder,
.patient-care-select .patient-care__placeholder {
  color: $color-grey;
  max-width: calc(100% - 28px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-app .patient-care--is-disabled .patient-care__placeholder,
.patient-care-select .patient-care--is-disabled .patient-care__placeholder {
  opacity: 0.5;
}

.patient-care-app .patient-care__single-value.patient-care__single-value--is-disabled,
.patient-care-select .patient-care__single-value.patient-care__single-value--is-disabled {
  color: $color-grey;
}

.patient-care-app .patient-care__menu,
.patient-care-select .patient-care__menu {
  z-index: 5004;
}

.patient-care-app .patient-care__menu-list,
.patient-care-select .patient-care__menu-list {
  padding: $spacing-smaller;
}

.patient-care-app .patient-care__option,
.patient-care-select .patient-care__option {
  border-radius: $border-radius-medium;
  color: $color-primary;
  cursor: pointer;
  line-height: $ratio-medium;
  padding-bottom: $spacing-smaller;
  padding-top: $spacing-smaller;
  padding: $spacing-smaller ($spacing-smaller + 4px);
  position: relative;
  transition: color .3s, background-color .3s;
}

.patient-care-app .patient-care__option:after,
.patient-care-select .patient-care__option:after {
  background-color: transparent;
  border-radius: $border-radius-medium;
  bottom: -($spacing-smaller / 4);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -($spacing-smaller / 4);
  z-index: -1;
}

.patient-care-app .patient-care__option.patient-care__option--is-selected,
.patient-care-select .patient-care__option.patient-care__option--is-selected {
  background-color: transparent;
}

.patient-care-app .patient-care__option.patient-care__option--is-focused,
.patient-care-select .patient-care__option.patient-care__option--is-focused {
  background-color: $color-accent;
  color: $color-white;
}

// styles for custom primary select
.patient-care-app .patient-care-select-primary {
  width: 100%;
}

.patient-care-app .patient-care-select-primary .patient-care__control {
  background-color: $color-accent;
  border-radius: $border-radius-large;
  border: 0;
  cursor: pointer;
  transition: background-color .3s;
}

.patient-care-app .patient-care-select-primary .patient-care__control:hover {
  background: rgba($color-accent, .7);
}

.patient-care-app .patient-care-select-primary .patient-care__value-container {
  padding: ($spacing-small - 3px) ($spacing-medium + 4px);
}

.patient-care-app .patient-care-select-primary .patient-care__placeholder {
  color: $color-white;
}

.patient-care-app .patient-care-select-primary .patient-care__indicators {
  left: -($spacing-smaller + 5px);
  position: relative;
}

.patient-care-app .patient-care-select-primary .patient-care__indicator.patient-care__dropdown-indicator {
  color: $color-white;
}

// search select
.patient-care-search-select .patient-care__control {
  border-radius: $border-radius-large;
}

.patient-care-search-select .patient-care__value-container {
  padding-left: $spacing-larger;
}

.patient-care-search-select .patient-care__indicator {
  opacity: 0;
}

// breadcrumbs
.patient-care-app .ui-kit-breadcrumbs-link {
  color: $color-accent;
}

.patient-care-app .ui-kit-breadcrumbs-active,
.patient-care-app .ui-kit-breadcrumbs-active:hover,
.patient-care-app .ui-kit-breadcrumbs-active:focus,
.patient-care-app .ui-kit-breadcrumbs-active:active {
  color: $color-primary;
}

.ui-kit-breadcrumbs li:last-child a {
  color: $color-primary;
  pointer-events: none;
}

// labels
.patient-care-hidden-label span {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
}

// styles for disabled checkbox
.ui-kit-form .input-checkbox label {
  cursor: default;
}

.patient-care-app .ui-kit-form .input-checkbox input + span {
  cursor: pointer;
}

.patient-care-app .ui-kit-form .input-checkbox input:disabled + span {
  cursor: default;
}

.patient-care-app .ui-kit-form .input-checkbox input:checked:disabled + span::before {
  background-color: $color-grey;
  border-color: $color-grey;
}

// calendar styles
.patient-care-calendar {
  position: relative;
}

.patient-care-calendar .rbc-time-view,
.patient-care-calendar .rbc-month-view  {
  background-color: $color-white;
}

.patient-care-calendar .rbc-time-view {
  border: 0;
  border-top: 1px solid $color-grey-light;
}

// calendar header styles
.patient-care-calendar .rbc-time-header-content {
  border-left: 0;
}

.patient-care-calendar .rbc-header {
  border-bottom: 0;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: $ratio-small;
  padding: $spacing-smaller;
  text-align: left;
}

.patient-care-calendar .rbc-time-view .rbc-time-gutter,
.patient-care-calendar .rbc-time-view .rbc-time-header-gutter {
  border-right: 0;
}

.patient-care-calendar .rbc-time-header,
.patient-care-calendar .rbc-month-header {
  box-shadow: $box-shadow-normal;
  position: relative;
  z-index: 5003;
}

.patient-care-calendar .rbc-time-header-cell {
  align-items: flex-end;
  display: flex;
  height: $spacing-larger;
}

.patient-care-calendar .rbc-header + .rbc-header {
  border-left: 0;
}

.patient-care-calendar .rbc-row.rbc-time-header-cell {
  width: 100%;
}

//day view
.patient-care-calendar .rbc-time-header.rbc-overflowing {
  background-color: $color-white;
  border-right: 0;
  margin-right: 0 !important;
  padding-right: $spacing-smaller;
  display: flex;
  align-items: center;
}

.patient-care-calendar .patient-care-day-view .rbc-row-resource {
  display: block;
  align-items: center;
}

.patient-care-calendar .patient-care-day-view .rbc-current-time-indicator {
  z-index: 5001;
}

.patient-care-calendar .patient-care-day-view .rbc-time-view-resources .rbc-row.rbc-time-header-cell-single-day {
  display: none;
}

.patient-care-calendar .rbc-time-view .rbc-allday-cell {
  height: 0;
  display: none;
}

//week view
.patient-care-calendar .rbc-today {
  background-color: transparent;
}

.patient-care-calendar .rbc-header a {
  pointer-events: none;
}

.patient-care-calendar .rbc-today a {
  color: $color-accent;
}

//month view
.patient-care-calendar .rbc-month-view .rbc-date-cell a {
  pointer-events: none;
}

.patient-care-calendar .rbc-now a {
  color: $color-accent;
}

.patient-care-calendar .patient-care-day-view .rbc-header {
  text-align: center;
}

.patient-care-calendar .rbc-month-view .rbc-date-cell {
  cursor: pointer;
}

.patient-care-calendar .rbc-date-cell:hover a {
  color: $color-accent;
}

// calendar time slots styles
.patient-care-calendar .rbc-timeslot-group {
  border: 0;
  line-height: $ratio-small;
  min-height: $spacing-larger * 2 - $spacing-smaller / 2;
}

.patient-care-calendar .rbc-day-slot .rbc-timeslot-group {
  position: relative;
}

.patient-care-calendar .rbc-day-slot .rbc-timeslot-group:after {
  background-color: $color-grey-light;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 999;
}

.patient-care-calendar .rbc-label {
  color: $color-grey-dark;
  font-size: $font-size-small;
  font-weight: 700;
  padding: 0 $spacing-smaller 0 $spacing-small;
}

// calendar content styles
.patient-care-calendar .rbc-time-content {
  background-color: $color-white;
  border-top: 1px solid $color-grey-light;
  flex: auto;
  height: calc(100vh - 281px);
  overflow: auto;

  @media (min-width: $desktop-small) {
    height: calc(100vh - 277px);
  }
}

.patient-care-day-view .rbc-time-view .rbc-time-content {
  height: calc(100vh - 281px);

  @media (min-width: $desktop-small) {
    height: calc(100vh - 277px);
  }
}

.patient-care-day-view .rbc-time-view.rbc-time-view-resources .rbc-time-content {
  height: calc(100vh - 289px);

  @media (min-width: $desktop-small) {
    height: calc(100vh - 285px);
  }
}

.patient-care-calendar .rbc-time-content > * + * > * {
  border-left: 0;
}

// column styles
.patient-care-calendar .rbc-day-slot,
.patient-care-calendar .rbc-time-column {
  background-color: $color-white;
}

.patient-care-calendar .rbc-time-column {
  border-left: 0;
}

.patient-care-calendar .rbc-time-column:before {
  background-color: $color-grey-light;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 999;
}

.patient-care-calendar .rbc-time-column:first-child:before,
.patient-care-calendar .rbc-time-column:nth-child(2):before {
  display: none;
}

.patient-care-calendar .rbc-time-column .rbc-time-slot {
  border-top: 0;
}

.patient-care-calendar .rbc-off-range-bg {
  background: transparent;
}

// calendar indicator styles
.patient-care-calendar .rbc-current-time-indicator {
  background-color: $color-accent;
  height: $spacing-smaller / 4;
}

.patient-care-calendar .rbc-time-content .rbc-day-slot:nth-child(2) .rbc-current-time-indicator:before {
  background-color: $color-accent;
  border-radius: 50%;
  content: "";
  height: $spacing-small;
  left: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $spacing-small;
}

// calendar month view styles
.patient-care-calendar .rbc-month-view {
  border: 0;
  border-bottom: 1px solid $color-grey-light;
  border-top: 1px solid $color-grey-light;
}

.patient-care-calendar .rbc-month-header {
  align-items: flex-end;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  height: $spacing-larger;
}

.patient-care-calendar .rbc-month-header .rbc-header {
  text-align: left;
}

.patient-care-calendar .rbc-month-row {
  min-height: calc((100vh - 282px) / 5);

  @media (min-width: $desktop-small) {
    min-height: calc((100vh - 278px) / 5);
  }
}

.patient-care-calendar .rbc-date-cell {
  font-weight: 700;
  line-height: $ratio-small;
  padding: ($spacing-smaller - 2px)  $spacing-smaller;
  text-align-last: left;
}

.patient-care-calendar .rbc-date-cell.rbc-off-range {
  color: $color-grey;
}

//week view styles
.patient-care-calendar .patient-care-week-view .rbc-time-gutter {
  position: relative;
  z-index: 5002;
}

.patient-care-calendar .rbc-time-gutter .rbc-label {
  position: relative;
  top: -2px;
}

.patient-care-calendar .patient-care-week-view .rbc-time-content {
  overflow-x: hidden;
}

.patient-care-calendar .patient-care-week-view .rbc-current-time-indicator {
  right: -1000%;
  z-index: 5001;
}

.patient-care-calendar .patient-care-week-view .rbc-current-time-indicator:before {
  background-color: $color-accent;
  content: "";
  height: 2px;
  left: -1000%;
  position: absolute;
  top: 0;
  width: 1000%;
}

.patient-care-calendar .patient-care-week-view .rbc-current-time-indicator:after {
  background-color: $color-accent;
  border-radius: 50%;
  content: "";
  height: $spacing-small;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $spacing-small;
}

// calendar events styles
.patient-care-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.patient-care-calendar .rbc-event {
  background-color: $color-booked-appointment-bg;
  border-radius: 0;
  border: 0;
  color: $color-booked-appointment-text;
  display: block;
  padding: $spacing-smaller / 2;
}

.patient-care-calendar .rbc-event.calendar-color-1 {
  background-color: $color-booked-appointment-bg-1;
  color: $color-booked-appointment-text-1;
}

.patient-care-calendar .rbc-event.calendar-color-2 {
  background-color: $color-booked-appointment-bg-2;
  color: $color-booked-appointment-text-2;
}

.patient-care-calendar .rbc-event.calendar-color-3 {
  background-color: $color-booked-appointment-bg-3;
  color: $color-booked-appointment-text-3;
}

.patient-care-calendar .rbc-event.calendar-color-4 {
  background-color: $color-booked-appointment-bg-4;
  color: $color-booked-appointment-text-4;
}

.patient-care-calendar .rbc-event.calendar-color-5 {
  background-color: $color-booked-appointment-bg-5;
  color: $color-booked-appointment-text-5;
}

.patient-care-calendar .rbc-event.calendar-color-6 {
  background-color: $color-booked-appointment-bg-6;
  color: $color-booked-appointment-text-6;
}

.patient-care-calendar .rbc-event.calendar-color-7 {
  background-color: $color-booked-appointment-bg-7;
  color: $color-booked-appointment-text-7;
}

.patient-care-calendar .rbc-event.calendar-color-8 {
  background-color: $color-booked-appointment-bg-8;
  color: $color-booked-appointment-text-8;
}

.patient-care-calendar .rbc-event.calendar-color-9 {
  background-color: $color-booked-appointment-bg-9;
  color: $color-booked-appointment-text-9;
}

.patient-care-calendar .rbc-event.calendar-color-10 {
  background-color: $color-booked-appointment-bg-10;
  color: $color-booked-appointment-text-10;
}

.patient-care-calendar .rbc-event.calendar-color-11 {
  background-color: $color-booked-appointment-bg-11;
  color: $color-booked-appointment-text-11;
}

.patient-care-calendar .rbc-event.calendar-color-12 {
  background-color: $color-booked-appointment-bg-12;
  color: $color-booked-appointment-text-12;
}

.patient-care-calendar .rbc-event.calendar-color-13 {
  background-color: $color-booked-appointment-bg-13;
  color: $color-booked-appointment-text-13;
}

.patient-care-calendar .rbc-event.calendar-color-14 {
  background-color: $color-booked-appointment-bg-14;
  color: $color-booked-appointment-text-14;
}

.patient-care-calendar .rbc-event.calendar-color-15 {
  background-color: $color-booked-appointment-bg-15;
  color: $color-booked-appointment-text-15;
}

.patient-care-calendar .rbc-event.calendar-color-16 {
  background-color: $color-booked-appointment-bg-16;
  color: $color-booked-appointment-text-16;
}

.patient-care-calendar .rbc-row .rbc-event .patient-care-calendar-event-content {
  padding-bottom: 2px;
}

.patient-care-calendar .rbc-month-view .rbc-event {
  padding-bottom: 0;
}

.patient-care-calendar .rbc-event.-available-time-slot {
  padding: $spacing-smaller / 2;
  z-index: 1;
}

.patient-care-calendar .rbc-event .rbc-event-content {
  font-size: $font-size-small;
  line-height: $ratio-small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-calendar .rbc-event .rbc-event-label {
  font-size: $font-size-smaller;
  font-weight: 700;
  line-height: $ratio-small;
  padding-top: $spacing-smaller / 2;
  padding-bottom: $spacing-smaller / 2;
  width: 100%;
}

//show more styles
.patient-care-calendar .rbc-show-more {
  background-color: $color-white;
  color: $color-grey-dark;
  font-weight: 400;
}

.patient-care-calendar .rbc-show-more:hover {
  color: $color-accent;
}

//calendar checkbox styles
.patient-care-app .ui-kit-avatar {
  background: $color-avatar-bg-1;
  border-radius: 50%;
  display: block;
  font-style: normal;
  font-weight: 900;
  position: relative;
}

.patient-care-app .ui-kit-avatar.ui-kit-avatar-small {
  font-size: 14px;
  height: 32px;
  line-height: 14px;
  width: 32px;
}

.patient-care-app .ui-kit-avatar span {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-1 input:checked + span::before {
  background-color: $color-avatar-bg-1;
  border-color: $color-avatar-bg-1;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-1 input:focus + span::before {
  border-color: $color-avatar-bg-1;
}

.patient-care-app .ui-kit-avatar-1 {
  background: $color-avatar-bg-1;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-2 input:checked + span::before {
  background-color: $color-avatar-bg-2;
  border-color: $color-avatar-bg-2;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-2 input:focus + span::before {
  border-color: $color-avatar-bg-2;
}

.patient-care-app .ui-kit-avatar-2 {
  background: $color-avatar-bg-2;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-3 input:checked + span::before {
  background-color: $color-avatar-bg-3;
  border-color: $color-avatar-bg-3;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-3 input:focus + span::before {
  border-color: $color-avatar-bg-3;
}

.patient-care-app .ui-kit-avatar-3 {
  background: $color-avatar-bg-3;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-4 input:checked + span::before {
  background-color: $color-avatar-bg-4;
  border-color: $color-avatar-bg-4;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-4 input:focus + span::before {
  border-color: $color-avatar-bg-4;
}

.patient-care-app .ui-kit-avatar-4 {
  background: $color-avatar-bg-4;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-5 input:checked + span::before {
  background-color: $color-avatar-bg-5;
  border-color: $color-avatar-bg-5;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-5 input:focus + span::before {
  border-color: $color-avatar-bg-5;
}

.patient-care-app .ui-kit-avatar-5 {
  background: $color-avatar-bg-5;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-6 input:checked + span::before {
  background-color: $color-avatar-bg-6;
  border-color: $color-avatar-bg-6;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-6 input:focus + span::before {
  border-color: $color-avatar-bg-6;
}

.patient-care-app .ui-kit-avatar-6 {
  background: $color-avatar-bg-6;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-7 input:checked + span::before {
  background-color: $color-avatar-bg-7;
  border-color: $color-avatar-bg-7;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-7 input:focus + span::before {
  border-color: $color-avatar-bg-7;
}

.patient-care-app .ui-kit-avatar-7 {
  background: $color-avatar-bg-7;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-8 input:checked + span::before {
  background-color: $color-avatar-bg-8;
  border-color: $color-avatar-bg-8;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-8 input:focus + span::before {
  border-color: $color-avatar-bg-8;
}

.patient-care-app .ui-kit-avatar-8 {
  background: $color-avatar-bg-8;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-9 input:checked + span::before {
  background-color: $color-avatar-bg-9;
  border-color: $color-avatar-bg-9;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-9 input:focus + span::before {
  border-color: $color-avatar-bg-9;
}

.patient-care-app .ui-kit-avatar-9 {
  background: $color-avatar-bg-9;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-10 input:checked + span::before {
  background-color: $color-avatar-bg-10;
  border-color: $color-avatar-bg-10;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-10 input:focus + span::before {
  border-color: $color-avatar-bg-10;
}

.patient-care-app .ui-kit-avatar-10 {
  background: $color-avatar-bg-10;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-11 input:checked + span::before {
  background-color: $color-avatar-bg-11;
  border-color: $color-avatar-bg-11;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-11 input:focus + span::before {
  border-color: $color-avatar-bg-11;
}

.patient-care-app .ui-kit-avatar-11 {
  background: $color-avatar-bg-11;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-12 input:checked + span::before {
  background-color: $color-avatar-bg-12;
  border-color: $color-avatar-bg-12;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-12 input:focus + span::before {
  border-color: $color-avatar-bg-12;
}

.patient-care-app .ui-kit-avatar-12 {
  background: $color-avatar-bg-12;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-13 input:checked + span::before {
  background-color: $color-avatar-bg-13;
  border-color: $color-avatar-bg-13;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-13 input:focus + span::before {
  border-color: $color-avatar-bg-13;
}

.patient-care-app .ui-kit-avatar-13 {
  background: $color-avatar-bg-13;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-14 input:checked + span::before {
  background-color: $color-avatar-bg-14;
  border-color: $color-avatar-bg-14;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-14 input:focus + span::before {
  border-color: $color-avatar-bg-14;
}

.patient-care-app .ui-kit-avatar-14 {
  background: $color-avatar-bg-14;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-15 input:checked + span::before {
  background-color: $color-avatar-bg-15;
  border-color: $color-avatar-bg-15;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-15 input:focus + span::before {
  border-color: $color-avatar-bg-15;
}

.patient-care-app .ui-kit-avatar-15 {
  background: $color-avatar-bg-15;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-16 input:checked + span::before {
  background-color: $color-avatar-bg-16;
  border-color: $color-avatar-bg-16;
}

.ui-kit-form .patient-care-calendar-checkbox.input-checkbox.avatar-16 input:focus + span::before {
  border-color: $color-avatar-bg-16;
}

.patient-care-app .ui-kit-avatar-16 {
  background: $color-avatar-bg-16;
}

//search blocks styles
.patient-care-search-block .ui-kit-form .input-search-icon input {
  padding-left: $spacing-larger;
  padding-right: $spacing-small;
}

.patient-care-search-block .ui-kit-form .input-search-icon button {
  cursor: inherit;
  height: $size-icon-medium + 1px;
  left: $spacing-small;
  position: absolute;
  top: 50%;
  width: $size-icon-medium + 1px;
}

.patient-care-search-block .ui-kit-icon-large,
.patient-care-search-block .ui-kit-icon-large svg {
  height: $size-icon-medium;
  width: $size-icon-medium;
}

.patient-care-calendar-info .ui-kit-link-icon i {
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.patient-care-dropdown-opener .ui-kit-link-icon-small {
  height: $spacing-small - 2px;
  width: $spacing-small - 2px;
}

.patient-care-app .ui-kit-pagination {
  background-color: $color-white;
  margin: 0;
  padding: $spacing-large 0;
}

.patient-care-login-page .ui-kit-notification p {
  text-overflow: ellipsis;
  overflow: hidden;
}

// styles for confirmation password input
.patient-care-app .ui-kit-form .input-text.confirm-password input {
  -webkit-text-security: disc;
}

// styles for skeleton
.patient-care-main .patient-care-calendars-sidebar .ui-kit-skeleton {
  padding: 12px 0;
}

.patient-care-time-inputs .form-item + .form-item {
  margin-top: 0;
}

.patient-care-time-inputs .form-item:last-child {
  width: $spacing-larger * 3 - $spacing-smaller;
}

.ui-kit-loader-inline {
  background: none;
}

@keyframes ui-kit-loader {
  0% {
    background: $color-loader-rgba;
  }

  50% {
    background: $color-loader;
  }

  90% {
    background: $color-loader-rgba;
  }
}

.ui-kit-loader > em, .ui-kit-loader > em::before, .ui-kit-loader > em::after {
  background: $color-loader-rgba;
}


// patient-care-select-multi
.patient-care-select-multi .patient-care__menu {
  min-width: 260px; // non-standard
}

.patient-care-select-multi .patient-care__option {
  padding-left: 36px; // non-standard
  position: relative;
  margin-top: ($spacing-smaller / 4);
  margin-bottom: ($spacing-smaller / 4);
}

.patient-care-select-multi .patient-care__option:before,
.patient-care-select-multi .patient-care__option::before {
  content: '';
  width: 16px; // non-standard
  height: 16px; // non-standard
  border: 1px solid $color-grey-light;
  box-sizing: content-box;
  position: absolute;
  top: 11px;
  left: 8px;
  border-radius: $border-radius-small;
  transition: border .3s ease-in-out, background-color .3s ease-in-out;
}

.patient-care-select-multi .patient-care__option:after,
.patient-care-select-multi .patient-care__option::after {
  content: normal;
}

.patient-care-select-multi .patient-care__option--is-disabled {
  display: none;
  opacity: .5;
}

.patient-care-select-multi .patient-care__option--is-focused {
  background: transparent !important;
  color: $color-primary !important;
}

.patient-care-select-multi .patient-care__option--is-selected:before,
.patient-care-select-multi .patient-care__option--is-selected::before {
  background-color: $color-accent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMjMuMzcsNi4yOSwxMS45MywyMWEyLjg3LDIuODcsMCwwLDEtMy44Ni40NGwtMi0xLjYzTDEsMTQuNjVBMi42MiwyLjYyLDAsMCwxLC41NiwxMWEyLjU0LDIuNTQsMCwwLDEsMy41OC0uNDFsNiw1LjE3TDE5LjQ0LDNBMi41NCwyLjU0LDAsMCwxLDIzLDIuNTdhMi42MSwyLjYxLDAsMCwxLC4zMywzLjcyWiIvPjwvc3ZnPgo=');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  border-color: $color-accent;
}

.patient-care-select-multi .patient-care__value-container {
  flex-wrap: nowrap;
  position: relative;
}

.patient-care-select-multi .patient-care__value-container > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.patient-care-select-multi .patient-care__multi-value {
  background: transparent;
  margin: 0;
}

.patient-care-select-multi .patient-care__multi-value + .patient-care__multi-value .patient-care__multi-value__label:before,
.patient-care-select-multi .patient-care__multi-value + .patient-care__multi-value .patient-care__multi-value__label::before {
  content: ', ';
}

.patient-care-select-multi .patient-care__multi-value__label {
  color: $color-primary;
  padding: 0;
}

.patient-care-select-multi .patient-care__multi-value__remove {
  display: none;
}

.patient-care-select-multi .patient-care__clear-indicator {
  display: none;
}


.ui-kit-skeleton.transparent {
  background: transparent;
}

// override default p
.ui-kit-notification > p {
  margin: 0;
}
