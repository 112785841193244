// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-classNameTwo
// react-table:react-table-classNameTwo:input-radio


// react-table:react-table-classNameTwo
.react-table.react-table-classNameTwo th,
.react-table.react-table-classNameTwo td {
  padding-left: 0;
  padding-right: 0;
}

.react-table.react-table-classNameTwo td {
  align-items: center;
  display: flex;
  justify-content: space-between;
}


// react-table:react-table-classNameTwo:input-radio
.react-table.react-table-classNameTwo .input-radio label {
  font-size: $font-size-small;
  line-height: 20px; // non-standard
  padding-left: 28px; // non-standard
}

.react-table.react-table-classNameTwo .input-radio label span::before {
  top: 0;
}
