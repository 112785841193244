// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager-header
// task-manager-header:skeleton-select
// task-manager-header:patient-care-select


// task-manager-header
.task-manager-header {
  box-shadow: $box-shadow-normal;
  padding: $spacing-medium $spacing-small;
}

.task-manager-header h2 {
  font-size: $font-size-larger;
  line-height: round($font-size-larger * $ratio-small);
}


// task-manager-header:skeleton-select
.task-manager-header .skeleton-select {
  margin-top: $spacing-small;
}


// task-manager-header:patient-care-select
.task-manager-header .patient-care-select {
  margin-top: $spacing-small;
}
