// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-custom
// reports-custom-item


// reports-custom
.reports-custom {
  padding: $spacing-medium;
  display: block;
}


// reports-custom-item
.reports-custom-item {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  padding: $spacing-medium;
  width: 50%;
}

.reports-custom-item +
.reports-custom-item {
  margin-top: $spacing-medium;
}

.reports-custom-item p {
  margin-top: $spacing-smaller;
}

.reports-custom-item h4 {
  margin: $spacing-smaller 0;
}

.reports-custom-item button {
  margin-top: $spacing-medium;
}
