// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-classNameFour
// react-table:react-table-classNameFour:columns
// react-table:react-table-classNameFour:input-checkbox


// react-table:react-table-classNameFour
.react-table.react-table-classNameFour tr th.cell-checkbox,
.react-table.react-table-classNameFour tr td.cell-checkbox {
  width: 22px + ($spacing-medium + $spacing-smaller); // non-standard
}

.react-table.react-table-classNameFour tr th.cell-checkbox {
  padding-bottom: ($spacing-smaller / 2);
  padding-top: ($spacing-smaller / 2);
}

.react-table.react-table-classNameFour tr th.cell-checkbox ~ th.cell-main,
.react-table.react-table-classNameFour tr td.cell-checkbox ~ td.cell-main {
  padding-left: 0;
}

.react-table.react-table-classNameFour tr th.cell-main,
.react-table.react-table-classNameFour tr td.cell-main {
  width: 34%;
}

.react-table.react-table-classNameFour tr th.cell-default,
.react-table.react-table-classNameFour tr td.cell-default {
  width: auto;
}


// react-table:react-table-classNameFour:columns
.react-table.react-table-classNameFour[columns='1'] tr th.cell-main,
.react-table.react-table-classNameFour[columns='1'] tr td.cell-main,
.react-table.react-table-classNameFour[columns='2'] tr th.cell-main,
.react-table.react-table-classNameFour[columns='2'] tr td.cell-main,
.react-table.react-table-classNameFour[columns='3'] tr th.cell-main,
.react-table.react-table-classNameFour[columns='3'] tr td.cell-main {
  width: auto;
}


// react-table:react-table-classNameFour:input-checkbox
.react-table.react-table-classNameFour .input-checkbox {
  height: 22px; // non-standard
  width: 22px; // non-standard
  overflow: hidden;
}

.react-table.react-table-classNameFour .input-checkbox label {
  line-height: 22px; // non-standard
}

.react-table.react-table-classNameFour .input-checkbox span:before {
  top: 0;
}
