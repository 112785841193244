@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-roles-heading .patient-care-block-row {
  margin-bottom: -16px;
}

.patient-care-roles-item-heading label {
  color: $color-grey-dark;
}

.patient-care-modal-edit-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.patient-care-modal-edit-row:last-child {
  padding-bottom: 0;
}

.patient-care-roles-item {
  display: block;
  overflow: hidden;
  padding: ($spacing-smaller + 1px) ($spacing-small - 1px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-btn-delete-row {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $color-grey-dark;
  cursor: pointer;
  display: inline-block;
  height: $spacing-medium - 2px;
  padding: ($spacing-smaller - 2px);
  transition: border-color .3s, background-color .3s;
  width: $spacing-medium - 2px;
}

.patient-care-btn-delete-row .ui-kit-link-icon i {
  height: $spacing-smaller;
  width: $spacing-smaller;
}

.patient-care-btn-delete-row svg * {
  fill: $color-grey-dark;
}

.patient-care-btn-delete-row:hover {
  background-color: $color-status-error;
  border-color: $color-status-error;
}

.patient-care-btn-delete-row:hover svg * {
  fill: $color-white;
}
