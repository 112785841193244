// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-select


// skeleton-select
.skeleton-select {
  align-items: center;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  display: flex;
  height: $size-form-medium;
  padding: $spacing-smaller $spacing-small;
  position: relative;
}

.skeleton-select i {
  height: $size-icon-larger;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ($spacing-smaller + 2px);
  width: $size-icon-larger;
}

.skeleton-select svg {
  height: $size-icon-small;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  width: $size-icon-small;
}

.skeleton-select svg * {
  fill: $color-grey;
}
