// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager-content-top
// task-manager-content-scroll


// task-manager-content-top
.task-manager-content-top + .task-manager-footer {
  box-shadow: none;
  margin-bottom: -($spacing-smaller * 2 + $size-form-medium);
  transition: margin .3s ease-in-out, box-shadow .3s ease-in-out;
}


// task-manager-content-scroll
.task-manager-content-scroll + .task-manager-footer {
  box-shadow: $box-shadow-top;
  margin-bottom: 0;
  transition: margin .3s ease-in-out, box-shadow .3s ease-in-out;
}
