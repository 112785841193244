@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';
@import 'patient-care-section-block.scss';

// patient-care-sortable
@import 'patient-care-sortable-wrapper.scss';
@import 'patient-care-sortable-item.scss';

// react-table
@import './styles/react-table.scss';

// input-checkbox
@import './styles/input-checkbox.scss';

// helpers
.patient-care-unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.patient-care-align-right {
  text-align: right;
}

.patient-care-align-center {
  text-align: center;
}

// list styles
.patient-care-two-column-list {
  column-count: 2;
  column-gap: $spacing-small;
  margin-bottom: $spacing-small;
}

.patient-care-two-column-list li {
  padding-right: $spacing-smaller;
}

// typography styles
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $color-primary;
  font-weight: 700;
  line-height: $ratio-small;
  margin: 0;
}

h1, .h1 {
  font-size: $font-size-larger;
  font-weight: 900;
}

h3, .h3 {
  font-size: $font-size-large;
  font-weight: 900;
}

h2, .h2 {
  font-weight: 900;
}

h4, .h4 {
  line-height: $ratio-medium;
}

p {
  margin: 0 0 $spacing-smaller;
}

.patient-care-warning {
  color: $color-status-error;
}

a,
.patient-care-link {
  color: $color-accent;
  cursor: pointer;
  text-decoration: none;
}

a {
  transition: color .3s;
}

a:hover {
  color: rgba($color-accent, .8);
}

a:focus,
button:focus {
  outline: none;
}

.patient-care-link {
  font-weight: 700;
}

.patient-care-link svg * {
  fill: $color-accent;
}

.patient-care-link-has-icon {
  font-weight: 700;
}

.patient-care-link-has-icon i {
  margin-left: $spacing-smaller;
}

.patient-care-link-has-icon svg * {
  fill: $color-accent;
}

.patient-care-support-link {
  color: $color-primary;
  font-weight: 700;
}

.patient-care-support-link:hover svg * {
  fill: rgba($color-accent, .8);
}

.patient-care-support-link i {

  @media (min-width: $desktop-small) {
    margin-right: $spacing-smaller;
  }
}

.patient-care-support-link svg * {
  fill: $color-primary;
  transition: fill .3s;
}

.patient-care-separator {
  border-color: transparent transparent $color-grey-light transparent;
  margin: $spacing-medium 0;
  padding: 0;
}

.patient-care-separator.first {
  margin-top: 0;
}

.patient-care-separator-small {
  border-color: transparent transparent $color-grey-light transparent;
  margin: $spacing-small 0;
  padding: 0;
}

.patient-care-roles-separator {
  border-color: transparent transparent $color-grey-light transparent;
  margin: $spacing-small 0;
  padding: 0;
}

.patient-care-links-spacing {
  margin: 0 $spacing-smaller;
}

// container styles
.patient-care-container {
  padding: 0 $spacing-large;
}

.patient-care-container-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 $spacing-large;
}

.patient-care-container-flex > .ui-kit-form {
  padding-right: $spacing-small;
  width: 100%;
}

.patient-care-container-flex > .patient-care-select,
.patient-care-container-flex > .ui-kit-tabs {
  margin-right: $spacing-small;
}

.patient-care-container-flex > .ui-kit-button {
  display: flex;
  flex-shrink: 0;
}

.patient-care-container-sm {
  margin: 0 auto;
  padding: 0 $spacing-large;

  @media #{$screen-desktop-small} {
    width: $size-large + $spacing-large * 2;
  }
}

// flex row styles
.patient-care-row {
  display: flex;
}

.patient-care-row-align-content-center {
  align-items: center;
  display: flex;
}

.patient-care-row-align-space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.patient-care-row-align-center {
  display: flex;
  justify-content: center;
}

.patient-care-row-align-right {
  display: flex;
  justify-content: flex-end;
}

// column styles
.patient-care-col-12 {
  padding: 0 $spacing-smaller;
  width: 100%;
}

.patient-care-col-11 {
  padding: 0 $spacing-smaller;
  width: 90%;
}

.patient-care-col-10 {
  padding: 0 $spacing-smaller;
  width: 80%;
}

.patient-care-col-9 {
  padding: 0 $spacing-smaller;
  width: 75%;
}

.patient-care-col-8 {
  padding: 0 $spacing-smaller;
  width: 66.66%;
}

.patient-care-col-7 {
  padding: 0 $spacing-smaller;
  width: 58.33%;
}

.patient-care-col-6 {
  padding: 0 $spacing-smaller;
  width: 50%;
}

.patient-care-col-5 {
  padding: 0 $spacing-smaller;
  width: 41.66%;
}

.patient-care-col-4 {
  padding: 0 $spacing-smaller;
  width: 33.33%;
}

.patient-care-col-3 {
  padding: 0 $spacing-smaller;
  width: 25%;
}

.patient-care-col-2 {
  padding: 0 $spacing-smaller;
  width: 20%;
}

.patient-care-col-1 {
  padding: 0 $spacing-smaller;
  width: 10%;
}

.patient-care-col-12:first-child,
.patient-care-col-11:first-child,
.patient-care-col-10:first-child,
.patient-care-col-9:first-child,
.patient-care-col-8:first-child,
.patient-care-col-7:first-child,
.patient-care-col-6:first-child,
.patient-care-col-5:first-child,
.patient-care-col-4:first-child,
.patient-care-col-3:first-child,
.patient-care-col-2:first-child,
.patient-care-col-1:first-child {
  padding-left: 0;
}

.patient-care-col-12:last-child,
.patient-care-col-11:last-child,
.patient-care-col-10:last-child,
.patient-care-col-9:last-child,
.patient-care-col-8:last-child,
.patient-care-col-7:last-child,
.patient-care-col-6:last-child,
.patient-care-col-5:last-child,
.patient-care-col-4:last-child,
.patient-care-col-3:last-child,
.patient-care-col-2:last-child,
.patient-care-col-1:last-child {
  padding-right: 0;
}

// scrollbar styles on touch screens
// ::-webkit-scrollbar {
//   height: $spacing-smaller;
//   width: $spacing-smaller;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: $color-grey-light;
//   border-radius: $border-radius-small;
// }

// styles for forms
.ui-kit-form .input-checkbox.input-checkbox-large span::before {
  height: $spacing-medium;
  width: $spacing-medium;
}

.ui-kit-form .input-checkbox-large.horisontal-centered span::before {
  top: 50%;
  transform: translateY(-50%);
}

.ui-kit-form .input-checkbox.input-checkbox-large label {
  padding-left: $spacing-larger;
}

.patient-care-time-col {
  width: $spacing-larger * 5 - $spacing-small;
}

.patient-care-time-col.edited {
  width: $spacing-larger * 8;
}

.patient-care-time-col .input-error {
  padding-left: 0;
}

.patient-care-time-inputs {
  //width: $spacing-larger * 5 - $spacing-small;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: $spacing-small;
}

//.patient-care-time-inputs.edited {
//  width: $spacing-larger * 5 - $spacing-small;
//}
//
//.patient-care-time-inputs.edited.first-line {
//  width: $spacing-larger * 8;
//}

.patient-care-time-inputs:last-child {
  margin-bottom: 0;
}

.patient-care-time-inputs:after {
  content: "-";
  font-weight: 700;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.patient-care-time-inputs.edited:after {
  left: 28.8%;
}

.patient-care-time-inputs .form-item,
.patient-care-time-inputs .patient-care-filled-input {
  margin-right: $spacing-medium;
  width: $spacing-larger * 2;
  min-width: $spacing-larger * 2;
}

.patient-care-time-inputs .form-item:last-child,
.patient-care-time-inputs .patient-care-filled-input:last-child {
  margin: 0;
}

.patient-care-time-input-error {
  display: none;
}

.patient-care-time-input-error:last-child {
  display: block;
}

.patient-care-availability-day {
  width: 21%;
}

.patient-care-availability {
  width: 31%;
}

.patient-care-availability-time {
  width: 42%;
}

.patient-care-input-error {
  color: $color-status-error;
}

.patient-care-input-error p {
  margin-bottom: 0;
}

// styles for prefilled inputs
.patient-care-filled-input {
  border: 1px solid transparent;
  color: $color-primary;
  display: block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: $ratio-medium;
  min-height: $size-form-medium;
  overflow: hidden;
  padding: ($spacing-smaller + 1px) ($spacing-small - 1px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-filled-input-full {
  text-overflow: clip;
  white-space: normal;
}

.patient-care-filled-select {
  border: 1px solid transparent;
  color: $color-primary;
  display: block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: $ratio-medium;
  height: $size-form-medium;
  overflow: hidden;
  padding: $spacing-smaller $spacing-small;
  position: relative;
  text-overflow: ellipsis;
  top: 1px;
  white-space: nowrap;
}

.patient-care-select {
  width: 100%;
  height: fit-content;
}

.patient-care-sm-select {
  width: ($size-small - 150px);
}

.patient-care-md-select {
  width: ($size-small - 100px);
}

.patient-care-lg-select {
  width: ($size-small - 20px);
}

// buttons styles
.ui-kit-button-blueline.patient-care-btn-greyline {
  background-color: $color-white;
  border: 1px solid $color-grey-light;
  color: $color-grey-dark;
}

.ui-kit-button-blueline.patient-care-btn-greyline.ui-kit-button-small {
  line-height: $ratio-small;
}

.ui-kit-button-blueline.patient-care-btn-greyline:hover,
.ui-kit-button-blueline.patient-care-btn-greyline:focus {
  background-color: $color-grey-light;
  color: $color-grey-dark;
}

.ui-kit-button-blueline.patient-care-btn-greyline:after {
  border-color: transparent;
}

.patient-care-btn-link {
  background-color: transparent;
  border: 0;
  color: $color-accent;
  cursor: pointer;
  font-size: $font-size-medium;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: $ratio-small;
  padding: 0;
}

.patient-care-btn-link:focus {
  outline: none;
}

.patient-care-back-link {
  color: $color-primary;
  cursor: pointer;
  font-size: $spacing-medium;
  font-weight: 700;
}

.patient-care-btn-close {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: $spacing-medium;
  top: $spacing-medium;
}

.patient-care-btn-delete .ui-kit-link-icon {
  border-radius: 50%;
  border: 1px solid $color-grey-light;
  transition: border-color .3s, background-color .3s;

  @media #{$screen-large} {
    padding: $spacing-smaller + 1px;
  }
}

.patient-care-btn-delete .ui-kit-link-icon:hover {
  background-color: $color-status-error;
  border-color: $color-status-error;
}

.patient-care-btn-delete .ui-kit-link-icon svg * {
  fill: $color-grey-dark;
}

.patient-care-btn-delete .ui-kit-link-icon:hover svg * {
  fill: $color-white;
}

.patient-care-btn-in-group + .patient-care-btn-in-group {
  margin-left: $spacing-small;
}

.patient-care-btn-remove.ui-kit-button-outline {
  color: $color-status-error;
}

.patient-care-btn-remove.ui-kit-button-outline::after
{
  border: 1px solid $color-status-error;
}

.patient-care-btn-remove.ui-kit-button-outline:hover,
.patient-care-btn-remove.ui-kit-button-outline:focus {
  background: $color-status-error;
  color: $color-white;
}

.patient-care-btn-remove.ui-kit-button-outline:hover::after {
  border-color: $color-status-error;
}

// titles styles
.patient-care-info-search span {
  display: block;
  overflow: hidden;
  padding-bottom: $spacing-smaller;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-info-search span:last-child {
  padding-bottom: 0;
}

.patient-care-time {
  color: $color-primary;
  font-weight: 700;
}

.patient-care-service {
  color: $color-primary;
  font-weight: 900;
}

.patient-care-person {
  color: $color-primary;
}

.patient-care-room {
  color: $color-grey-dark;
  display: block;
}

// background overlay styles
.patient-care-active-overlay {
  position: relative;
}

.patient-care-active-overlay:after {
  background-color: rgba($color-primary, .95);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.ui-kit-overlay {
  z-index: 10000;
}

// sidebar styles
.patient-care-sidebar-left,
.patient-care-calendars-sidebar {
  border-right: 1px solid $color-grey-light;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: $spacing-medium $spacing-small;
  position: absolute;
  top: 0;
  transition: left .3s;
  width: ($size-small - 80px);
}

// .patient-care-sidebar-left {
//   padding: $spacing-medium $spacing-small;
// }

// .patient-care-calendars-sidebar {
//   padding: $spacing-medium $spacing-smaller $spacing-medium $spacing-small;
// }

.patient-care-sidebar-left.hidden,
.patient-care-calendars-sidebar.hidden {
  left: (-($size-small - 80px));
}

.patient-care-sidebar-left .patient-care-breadcrumbs {
  font-weight: 900;
}

.patient-care-sidebar-left .patient-care-sidebar-block,
.patient-care-calendars-sidebar .patient-care-sidebar-block {
  margin-bottom: $spacing-medium;
}

.patient-care-sidebar-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-small;
}

.patient-care-sidebar-header {
  box-shadow: $box-shadow-bottom;
  padding: $spacing-medium $spacing-small;
  position: relative;
  z-index: 1;
}

.patient-care-sidebar-header > .ui-kit-link-icon {
  position: absolute;
  top: $spacing-medium;
  right: $spacing-small;
}

.patient-care-sidebar-header h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-sidebar-heading {
  margin-bottom: $spacing-small;
}

.patient-care-sidebar-title {
  margin-bottom: $spacing-small;
}

.patient-care-sidebar-date {
  background-color: $color-grey-lighter;
  border-bottom: 1px solid $color-grey-light;
  border-top: 1px solid $color-grey-light;
  color: $color-primary;
  font-weight: 700;
  padding: ($spacing-small - 1px) $spacing-small;
}

.patient-care-sidebar-date.active {
  color: $color-accent;
}

.patient-care-sidebar-date.sticky-top {
  box-shadow: $box-shadow-normal;
  left: 0;
  position: absolute;
  top: $spacing-medium * 4 + $spacing-smaller;
  right: $scroll-width;
  z-index: 0;
  width: 100%;
}

.patient-care-results-holder .patient-care-sidebar-date.sticky-top {
  top: $spacing-medium * 3;
}

.patient-care-custom-appointment .patient-care-sidebar-date.sticky-top {
  top: ($spacing-medium * 4 + $spacing-smaller)*2;
}

.patient-care-sidebar-footer {
  background-color: $color-white;
  bottom: 0;
  box-shadow: $box-shadow-top;
  left: 1px;
  padding: $spacing-smaller $spacing-small;
  position: absolute;
  right: 1px;
}

// overlay modals styles
.patient-care-overlay-header {
  padding-bottom: $spacing-small;
}

.patient-care-overlay-content {
  padding: $spacing-small 0;
}

.patient-care-overlay-footer {
  padding-top: $spacing-small;
}

.patient-care-overlay-form {
  margin-top: $spacing-medium;
}

// modals styles
.patient-care-modal-overlay {
  background-color: rgba($color-primary, .95);
  bottom: 0;
  left: -9999px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.patient-care-modal-holder {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}

.patient-care-modal-holder.agenda-active {
  box-shadow: $box-shadow-normal;
  right: $size-small;
}

.patient-care-modal {
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  height: 100%;
  width: ($size-large + 100px);
}

.patient-care-modal-full-height-holder {
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.patient-care-modal-full-height-with-agenda-holder {
  bottom: 0;
  position: fixed;
  right: $size-small;
  top: 0;
  z-index: 9999;
}

.patient-care-modal-full-height {
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  height: 100%;
  width: ($size-large + 100px);
}

.patient-care-modal-header {
  padding: $spacing-large $spacing-medium $spacing-medium;
  position: relative;
  transition: box-shadow .3s;
  z-index: 1;
}

.patient-care-modal-header.has-shadow {
  box-shadow: $box-shadow-normal;
}

.patient-care-modal-content {
  height: calc(100% - 172px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 $spacing-medium;
}

.overlay-confirmation-content h4 {
  margin-bottom: $spacing-medium;
}

.overlay-confirmation-content h4:last-child {
  margin-bottom: 0;
}

.patient-care-heading {
  margin-bottom: $spacing-small;
}

.patient-care-heading-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-small;
}

.patient-care-title-in-row {
  margin-right: $spacing-smaller;
  white-space: nowrap;
}

.patient-care-block-row {
  display: flex;
  margin-bottom: $spacing-small;
}

.patient-care-block-row:last-child {
  margin-bottom: 0;
}

.patient-care-block {
  margin: $spacing-small 0;
  padding-top: 2px;
  padding-left: 8px;
}

.patient-care-block-overlay{
    margin: 20px auto;
    max-width: 100%;
    background-color: $color-appointment-overlay;
    height: 190px;
    border-radius: 10px;
    padding-top: 12px;
    padding-left: 10px;
}

.patient-care-row-status-overlay{
  padding-left: 10px;
  width: 97%;
}

.patient-care-block.hidden {
  visibility: hidden;
}

.patient-care-form-block {
  margin: $spacing-medium 0;
}

.patient-care-block:first-child {
  margin-top: 0;
}

.patient-care-block:last-child {
  margin-bottom: 0;
}

.patient-care-block-margin-medium {
  margin-bottom: $spacing-medium;
}

.patient-care-search-block {
  margin: $spacing-medium 0;
  position: relative;
}

.patient-care-search-block > .ui-kit-icon {
  cursor: initial;
  left: $spacing-small;
  pointer-events: none;
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
}

.patient-care-search-block svg * {
  fill: $color-accent;
}

.patient-care-text-block {
  margin: $spacing-medium 0;
}

.patient-care-description-block {
  color: $color-grey-dark;
  margin-bottom: $spacing-medium;
}

.patient-care-description-block > h2 {
  margin-bottom: $spacing-medium;
}

.patient-care-description-block > h3,
.patient-care-description-block .patient-care-heading-row {
  margin-bottom: $spacing-smaller;
}

.patient-care-appointment-details-text {
  margin-left: $spacing-smaller;
}

.patient-care-modal-footer {
  bottom: 0;
  left: 0;
  height: $spacing-larger * 2 + $spacing-small - 4px;
  padding: $spacing-medium;
  position: absolute;
  right: 0;
  transition: box-shadow .3s;
  z-index: 103;
}

.patient-care-modal-footer.has-shadow {
  box-shadow: $box-shadow-top;
}

.patient-care-modal-buttons-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.patient-care-modal-button-wrap {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.patient-care-modal-buttons-flex {
  display: flex;
}

.patient-care-modal-buttons-flex > * + * {
  margin-left: $spacing-small;
}

.patient-care-video-join-msg {
  margin-right: $spacing-small;
}


// animation keyframes
@keyframes move-right {
  0% {
    right: (-$size-small);
  }
  100% {
    right: 0;
  }
}

// main layout styles
.patient-care-app {
  overflow-x: auto;
  position: relative;
}

.patient-care-wrapper {
  transition: margin-right .3s;
}

.patient-care-wrapper.active {

  @media #{$screen-desktop-small} {
    margin-right: $size-small;
  }
}

.patient-care-main {
  border-top: 1px solid $color-grey-light;
  min-height: calc(100vh - 79px);
  overflow: hidden;
  position: relative;
}

.patient-care-main-holder {
  bottom: 0;
  left: ($size-small - 80px);
  overflow: auto;
  position: absolute;
  top: 0;
  transition: width .3s, left .3s;
  width: calc(100vw - 220px);
}

.patient-care-main-holder.full-width {
  left: 0;
  width: calc(100% - 300px);
}

.patient-care-main-holder.active {

  @media #{$screen-desktop-medium} {
    width: calc(100% - 520px);
  }
}

.patient-care-main-holder.active.full-width {

  @media #{$screen-desktop-medium} {
    width: calc(100% - 600px);
  }
}

.patient-care-table-container {
  height: 100%;
}

// for responsive layout
.patient-care-table-container,
.patient-care-custom-table {
  @media (max-width: $desktop-small) {
    height: 100%;
    min-width: $size-large + 280px;
  }
}

// filters styles
.patient-care-filters-holder {
  padding: ($spacing-small - 4px) 0;
}

.patient-care-filters-holder:first-child {
  padding-top: $spacing-medium;
}

.patient-care-user-filters-holder .patient-care-col-9:only-child {
  width: $size-large + 50px;

  @media #{$screen-desktop-medium} {
    width:  $size-large + 68px;
  }
}

.patient-care-user-filters-holder .patient-care-col-9.small:only-child {
  width: $size-large - 70px;

  @media #{$screen-desktop-medium} {
    width:  $size-large - 60px;
  }
}

.patient-care-user-filters-holder {
  background-color: $color-grey-lighter;
  padding-bottom: $spacing-medium;
}

.patient-care-user-filters-holder:first-child {
  padding-top: $spacing-large;
}

.patient-care-user-breadcrumbs-holder .patient-care-user-filters-holder {
  padding-top: 0;
}

.patient-care-user-filters-holder + .patient-care-branches-empty-block {
  height: calc(100vh - 177px);

  @media (min-width: $desktop-small) {
    height: calc(100vh - 163px);
  }
}

.patient-care-user-filters-holder + .patient-care-user-filters-holder,
.patient-care-user-breadcrumbs-holder + .patient-care-details-holder {
  padding-top: 0;
}

.patient-care-user-filters-holder a:hover svg * {
  fill: $color-accent;
}

.patient-care-user-filters-holder.with-button {
  margin-top: -(58px);
}

.patient-care-user-filters-holder .ui-kit-tabs-item a {
  color: $color-grey-dark;
}

.patient-care-user-filters-holder .ui-kit-tabs-item a:hover {
  color: $color-accent;
}

.patient-care-user-filters-holder .ui-kit-tabs-item.ui-kit-tabs-item-selected a {
  color: $color-accent;
}

.patient-care-user-filters-holder.no-padding {
  padding-top: 0;
}

.patient-care-сalendar-availability .patient-care-block-row {
  margin-bottom: $spacing-medium;
}

.patient-care-day-col {
  display: flex;
  justify-content: space-between;
  padding-right: $spacing-small;
  width: $spacing-larger * 6 + 10px;
}

.patient-care-day-col .patient-care-select,
.patient-care-day-col .patient-care-filled-status,
.patient-care-day-col .patient-care-filled-select {
  width: $spacing-larger * 4 - 20px;
}

.patient-care-open-hours .patient-care-day-col {
  width: $spacing-larger * 5 + $spacing-small;
}

.patient-care-open-hours .patient-care-day-col .patient-care-select,
.patient-care-open-hours .patient-care-day-col .patient-care-filled-status,
.patient-care-open-hours .patient-care-day-col .patient-care-filled-select {
  width: $spacing-large * 3 + $spacing-smaller;
}

.patient-care-day {
  display: block;
  font-weight: 900;
  padding: ($spacing-smaller + 3px) $spacing-smaller $spacing-smaller 0;
  width: $spacing-large * 3 - 2px;
}

.patient-care-no-results {
  margin: $spacing-small 0 $spacing-medium;
}

.patient-care-no-results p {
  margin-bottom: $spacing-small;
}

.patient-care-note {
  display: block;
  font-size: $font-size-smaller;
  margin-bottom: $spacing-smaller;
}

// numbered list styles
.patient-care-numbered-list {
  counter-reset: listItem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.patient-care-numbered-list li {
  border-bottom: 1px solid $color-grey-light;
  color: $color-grey-dark;
  counter-increment: listItem;
  margin-bottom: $spacing-medium;
  padding-bottom: $spacing-medium;
}

.patient-care-numbered-list .patient-care-heading {
  padding-left: $spacing-medium - 4px;
  position: relative;
}

.patient-care-numbered-list .patient-care-heading:before {
  color: $color-accent;
  content: counters(listItem, ".");
  font-weight: 900;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: $spacing-medium - 4px;
}

.patient-care-numbered-list li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.patient-care-numbered-list li p {
  margin-bottom: $spacing-medium;
}

.patient-care-numbered-list li p:last-child {
  margin-bottom: 0;
}

// status styles
.patient-care-error-status {
  color: $color-status-error;
  display: block;
  margin-bottom: $spacing-small;
}

.patient-care-status {
  display: block;
  margin-bottom: $spacing-small;
}

.patient-care-status:last-child {
  margin-bottom: 0;
}

.patient-care-status.error {
  color: $color-status-error;
}

.patient-care-status.success {
  color: $color-status-success;
}

.patient-care-status.pending {
  color: $color-support-two;
}

.patient-care-skeleton-wrap {
  padding: $spacing-large $spacing-larger;
}

.patient-care-skeleton-filters {
  background-color: $color-grey-lighter;
  height: $spacing-larger * 2 + 2px;
}

.patient-care-none-cell {
  color: $color-grey-dark;
  font-style: italic;
}

.patient-care-external-link {
  display: inline-block;
  vertical-align: top;
  margin-top: $spacing-medium;
}

.patient-care-external-link .ui-kit-link-external {
  color: $color-accent;
  display: block;
}

.patient-care-external-link .ui-kit-link-external svg * {
  fill: $color-accent;
}


// patient-care-btn-join
.patient-care-btn-join .ui-kit-tooltip p {
  margin: 0;
}

.patient-care-btn-join .ui-kit-tooltip .ui-kit-tooltip-dropdown {
  width: 200px;
}

.grecaptcha-badge { visibility: hidden; }


// patient-care-sidebar-register
.patient-care-sidebar-register,
.patient-care-sidebar-register li {
  margin: 0;
  padding: 0;
}

.patient-care-sidebar-register {
  border-top: 1px solid $color-grey-light;
  padding: $spacing-medium 0;
}

.patient-care-sidebar-register li {
  list-style-type: none;
}

.patient-care-sidebar-register a {
  display: block;
  color: $color-grey-dark;
  font-weight: 700;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  display: flex;
  align-items: center;
}

.patient-care-sidebar-register a:hover,
.patient-care-sidebar-register a.active {
  color: $color-accent;
}

.patient-care-sidebar-register em {
  font-style: normal;
  color: $color-white;
  background: $color-accent;
  font-size: 12px; // non-standard
  font-weight: 900;
  line-height: 12px; // non-standard
  padding: $spacing-smaller $spacing-small;
  border-radius: $border-radius-large;
  margin-right: 6px; // non-standard
}
