// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-description-row


// skeleton-description-row
.skeleton-description-row + .skeleton-description-row {
  margin-top: $spacing-large;
}
