// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:branch-calendars:cell-main
// react-table:branch-calendars:cell-small
// react-table:branch-calendars:cell-medium
// react-table:branch-calendars:cell-alert


// react-table:branch-calendars:cell-main
.react-table.branch-calendars tr th.cell-main,
.react-table.branch-calendars tr td.cell-main {
  // width: 40%;
}


// react-table:branch-calendars:cell-small
.react-table.branch-calendars tr th.cell-small,
.react-table.branch-calendars tr td.cell-small {
  width: 140px; // non-standard
}


// react-table:branch-calendars:cell-medium
.react-table.branch-calendars tr th.cell-medium,
.react-table.branch-calendars tr td.cell-medium {
  width: 160px; // non-standard
}


// react-table:branch-calendars:cell-alert
.react-table.branch-calendars tr th.cell-alert,
.react-table.branch-calendars tr td.cell-alert {
  width: 90px; // non-standard
}

.react-table.branch-calendars tr td.cell-alert .group svg {
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.react-table.branch-calendars tr td.cell-alert .group.warning svg * {
  fill: $color-status-error;
}

.react-table.branch-calendars tr td.cell-alert .group.caution svg * {
  fill: #f29c26;
}
