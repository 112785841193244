@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.header-span-wrap {
    display: inline-block;
}

.select-all-checkbox span {
    color: $color-grey-dark;
    font-size: $font-size-small;
    font-weight: 700;
}

.patient-care-service-table.column {
    padding-left: $spacing-medium - $spacing-smaller;
    font-size: $font-size-small;
}

.patient-care-feature-content-table tbody td .service-check-col {
    padding: ($spacing-small/2) ($spacing-medium + 2);
}

td.patient-care-services-custom-table-left .patient-care-services-modes {
    padding: 0 ($spacing-smaller + 2);
}