@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-sidebar-agenda {
  animation: move-right 0.3s ease;
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  bottom: 0;
  box-shadow: $box-shadow-normal;
  line-height: $ratio-small;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform .3s;
  width: $size-small;
  z-index: 9998;

  @media #{$screen-desktop-medium} {
    box-shadow: none;
  }
}

.agenda-sidebar {
  background: $color-white;
  bottom: 0;
  border-left: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .3s;
  width: $size-small;
  z-index: 5;

  @media #{$screen-desktop-medium} {
    box-shadow: none;
  }
}

.agenda-sidebar-inactive {
  z-index: 0;

}

.patient-care-sidebar-agenda-enter {
  transform: translateX(100%);
}

.patient-care-sidebar-agenda-enter-active {
  transform: translateX(0);
}

.patient-care-sidebar-agenda-exit {
  transform: translateX(0);
}

.patient-care-sidebar-agenda-exit-active {
  transform: translateX(100%);
}