// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// overlay-content-task-manager


// overlay-content-task-manager
.overlay-content-task-manager h4 .ui-kit-link-external {
  color: $color-accent;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-smedium);
}

.overlay-content-task-manager h4 .ui-kit-link-external svg * {
  fill: $color-accent;
}
