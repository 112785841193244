// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-heading-one


// skeleton-heading-one
.skeleton-heading-one {
  align-items: center;
  display: flex;
  height: 24px; // non-standard round($font-size-larger * $ratio-small)
}
