// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-block
// patient-care-feature-content-block-header
// patient-care-feature-content-block-edit
// patient-care-feature-content-block-card
// patient-care-feature-content-block-card:label
// patient-care-feature-content-block-card:option


// patient-care-feature-content-block
.patient-care-feature-content-block h3 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-small);
  margin-bottom: $spacing-smaller;
}

.patient-care-feature-content-block p {
  margin: 0;
  color: $color-grey-dark;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.patient-care-feature-content-block p + p {
  margin-top: $spacing-small;
}

.patient-care-feature-content-block p.small {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-medium);
}

.patient-care-feature-content-block +
.patient-care-feature-content-block {
  padding-top: $spacing-medium;
  margin-top: $spacing-medium;
  border-top: 1px solid $color-grey-light;
}


// patient-care-feature-content-block-header
.patient-care-feature-content-block-header {
  display: flex;
  justify-content: space-between;
}


// patient-care-feature-content-block-edit
.patient-care-feature-content-block-edit {
  color: $color-accent;
  cursor: pointer;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: round($font-size-small * $ratio-medium);
  margin-left: $spacing-smaller;
}

.patient-care-feature-content-block-edit.disabled {
  pointer-events: none;
}


// patient-care-feature-content-block-card
.patient-care-feature-content-block-card {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  padding: $spacing-large;
  margin-top: $spacing-medium;
  display: flex;
  justify-content: space-between;
}


// patient-care-feature-content-block-card:label
.patient-care-feature-content-block-card .label {
  flex: 1;
}


// patient-care-feature-content-block-card:option
.patient-care-feature-content-block-card .option {
  padding-top: 100px;
}

.patient-care-feature-content-block-billing-radio {
  padding-top: 20px;
}

.patient-care-feature-content-block-form {
  padding-top: 12px;
}

.patient-care-feature-content-block-form .small {
  padding-left: 35px;
}

.patient-care-feature-content-block-form .small-bottom-padding {
  padding-left: 35px;
  padding-bottom: 20px;
}

.patient-care-feature-content-block-form span {
  font-weight: bold;
}