// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-content


// reports-content
.reports-content {
  display: flex;
  flex-direction: column;
  transition: width .3s ease-in-out;
}
