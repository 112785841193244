// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-fees
// card-reports-fees-info
// card-reports-fees-total
// card-reports-fees-item


// card-reports-fees
.card-reports-fees {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  margin-top: $spacing-medium;
  padding: $spacing-medium;
  position: relative;
}

.card-reports-fees h3 {
  font-size: $font-size-medium;
  font-weight: 400;
  line-height: round($font-size-medium * $ratio-smedium);
}

.card-reports-fees p {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: round($font-size-large * $ratio-small);
  margin-top: $spacing-smaller;
}


// card-reports-fees-info
.card-reports-fees-info {
  position: absolute;
  top: $spacing-medium;
  right: $spacing-medium;
}

.card-reports-fees-info a {
  display: block;
  color: $color-accent;
  font-size: $font-size-small;
  font-weight: 400;
  line-height: round($font-size-small * $ratio-smedium);
}

.card-reports-fees-info a svg * {
  fill: $color-accent;
}

.card-reports-fees-info a:hover {
  color: $color-accent;
}

.card-reports-fees-info a:hover svg * {
  fill: $color-accent;
}


// card-reports-fees-total
.card-reports-fees-total h3,
.card-reports-fees-total p {
  color: $color-primary;
}


// card-reports-fees-item
.card-reports-fees-item {
  margin-top: $spacing-small;
}

.card-reports-fees-item h3,
.card-reports-fees-item p {
  color: $color-grey-dark;
}
