@import '@patient-access/ui-kit/styles/utils/_variables.scss';


.time-off-branch-switch {
    margin-left: $spacing-smaller;
}


.patient-care-timeoff-branch {
    display: flex;
    margin-top: $spacing-medium;
}

.patient-care-select.timeoff-branch-select, .patient-care-branch-time-out .branch-timeout-info {
    margin-top: $spacing-medium;
}

.patient-care-availability-value {
    margin-top: $spacing-large + 2;
    margin-left: $spacing-small + 1;
    margin-bottom: $spacing-large + 2;
}

.patient-care-branch-time-out .branch-timeout-description {
    margin: 0;
}

.patient-care-branch-availability-select {
    width: ($size-medium / 2);

}

.patient-care-btn-link.disabled {
    pointer-events: none;
    cursor: pointer;
  }
  