// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-date-picker


// skeleton-date-picker
.skeleton-date-picker {
  align-items: center;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  display: flex;
  height: $size-form-medium;
  padding: $spacing-smaller $spacing-small;
  position: relative;
}

.skeleton-date-picker i {
  height: $size-icon-larger;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 12px; // non-standard
  width: $size-icon-larger;
}

.skeleton-date-picker svg {
  height: $size-icon-large;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  width: $size-icon-large;
}

.skeleton-date-picker svg * {
  fill: $color-grey;
}
