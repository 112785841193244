// patient-care-register-more
// patient-care-register-more-wrapper
// patient-care-register-more-content
// patient-care-register-more-image
// screen-custom
// screen-custom:patient-care-register-more-wrapper
// screen-custom:patient-care-register-more-content
// screen-custom:patient-care-register-more-image


// patient-care-register-more
.patient-care-register-more {}


// patient-care-register-more-wrapper
.patient-care-register-more-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 932px; // non-standard
  background: $color-grey-lighter;
  height: 520px; // non-standard
  display: flex;
  align-items: center;
}


// patient-care-register-more-content
.patient-care-register-more-content {
  padding: ($spacing-large * 2);
  width: 466px;
}

.patient-care-register-more-content h2 {
  font-family: $font-family-serif;
  font-weight: 900;
  font-size: $font-size-larger;
  line-height: round($font-size-larger * $ratio-medium);
  margin-bottom: $spacing-small;
}

.patient-care-register-more-content h4,
.patient-care-register-more-content p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
}

.patient-care-register-more-content h4 {
  text-align: center;
}

.patient-care-register-more-content h4 svg {
  width: ($size-icon-larger * 2);
  height: ($size-icon-larger * 2);
  margin: 0 auto $spacing-medium;
}

.patient-care-register-more-content h4 svg * {
  fill: $color-status-success;
}

.patient-care-register-more-content button {
  width: auto;
  margin-top: $spacing-large;
}


// patient-care-register-more-image
.patient-care-register-more-image {
  width: 466px;
  position: absolute;
  top: 0;
  left: 466px;
}

.patient-care-register-more-image img {
  width: 100%;
  height: auto;
}


// screen-custom
@media (max-width: $desktop-xsmall) {

  // screen-custom:patient-care-register-more-wrapper
  .patient-care-register-more-wrapper {
    height: auto;
  }


  // screen-custom:patient-care-register-more-content
  .patient-care-register-more-content {
    width: auto;
  }

  // screen-custom:patient-care-register-more-image
  .patient-care-register-more-image {
    display: none;
  }

}
