// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-legend


// reports-legend
.reports-legend {
  display: flex;
  flex-wrap: wrap;
  margin: -($spacing-smaller / 2);
}
