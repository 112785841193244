@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// calendar styles
.patient-care-calendar-skeleton-wrap {
  position: absolute;
  width: calc(100% - 70px);
  z-index: 9999;
  top: 50%;
  left: 70px;
}

.patient-care-calendar-wrap {
  overflow-x: auto;
  transition: margin-right .3s;
}

.patient-care-calendar-wrap.active {
  margin-right: ($size-small + 15px);
}

.patient-care-date-row {
  align-items: center;
  display: flex;
  padding-right: $spacing-smaller;
}

.active-results .patient-care-date-row {
  @media (max-width: $desktop-small) {
    width: calc(100% - 135px);
  }
}

//filter styles
.patient-care-calendar-filters-holder {
  background-color: $color-grey-lighter;
  padding: ($spacing-small - 4px) 0;
}

.patient-care-calendar-filters-holder:first-child {
  padding-top: $spacing-medium;
}

.patient-care-calendar-info {
  background-color: $color-grey-lighter;
  padding: ($spacing-small - 4px) 0 $spacing-medium;
}

.patient-care-calendar-filters-holder .patient-care-container-flex,
.patient-care-calendar-info .patient-care-container-flex,
.patient-care-calendar-filters-holder .patient-care-container,
.patient-care-calendar-info .patient-care-container {
  padding: 0 $spacing-medium;
}

.patient-care-date-row .patient-care-btn-greyline {
  margin-left: $spacing-smaller;

  @media (min-width: $desktop-small) {
    margin-left: $spacing-small;
  }
}

.patient-care-date-row .patient-care-datepicker {
  margin: 0 $spacing-small;
  width: 150px;
}

.patient-care-date-row .react-datepicker-popper {
  z-index: 5004;
}

.patient-care-calendar-filters-holder .ui-kit-link-icon {
  margin-right: $spacing-smaller;
}

.patient-care-calendar-filters-holder .ui-kit-link-icon i,
.patient-care-date-row .ui-kit-link-icon i,
.patient-care-calendar-filters-holder .ui-kit-link-icon svg,
.patient-care-date-row i svg {
  height: $spacing-small;
  width: $spacing-small;
}

.patient-care-calendar-filters-holder .ui-kit-link-icon svg *,
.patient-care-date-row .ui-kit-link-icon-large svg * {
  fill: $color-accent;
}

.patient-care-date-wrap {
  margin: 0 $spacing-small;
  max-width: calc(100% - 149px);
  padding-right: $spacing-smaller;

  @media (min-width: $desktop-small) {
    max-width: 61%;
  }
}

.patient-care-date-wrap h2 {
  font-weight: 700;
  line-height: $ratio-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.patient-care-switcher-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.patient-care-switcher-wrapper > button {
  margin-right: $spacing-small;
}

.patient-care-calendar-switcher {

  @media (max-width: $desktop-small) {
    margin-left: $spacing-smaller;
    width: $spacing-larger * 5 + 5px;
  }

  @media (min-width: $desktop-small) {
    background-color: $color-white;
    border-radius: $border-radius-large;
    border: 1px solid $color-grey-light;
  }
}

.active-results .patient-care-calendar-switcher {
  @media (max-width: $desktop-small) {
    width: $spacing-larger * 3 + 7px;
  }
}

.patient-care-calendar-button {
  background-color: transparent;
  border-radius: $border-radius-large;
  border: 0;
  color: $color-grey-dark;
  cursor: pointer;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: $ratio-small + .3;
  margin: 0;
  padding: ($spacing-smaller + 1px) $spacing-small;
  text-transform: capitalize;
  transition: color .3s, background-color .3s;
  width: $spacing-larger * 2;
}

.patient-care-calendar-button.active,
.patient-care-calendar-button.active:hover {
  background-color: $color-accent;
  color: $color-white;
}

.patient-care-calendar-button:hover {
  background-color: $color-grey-light;
  color: $color-grey-dark;
}

.patient-care-calendar-button:focus {
  outline: none;
}

.patient-care-calendar-event-content {
  align-items: center;
  display: flex;
  font-size: $font-size-smaller;
  justify-content: space-between;
  padding-bottom: $spacing-smaller / 2;
}

.patient-care-calendar-event-content > span > i {
  margin-right: ($spacing-smaller / 2);
}

.patient-care-calendar-event-title {
  display: block;
  font-size: $font-size-small;
  font-weight: 900;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
}

.patient-care-calendar-event-fullname {
  display: block;
  font-size: $font-size-small;
  font-weight: 400;
  line-height: $ratio-small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// calendar unavailable availability view styles
.patient-care-calendar .rbc-time-column.unavailable,
.patient-care-calendar .rbc-day-bg.unavailable,
.patient-care-calendar .rbc-day-slot.unavailable,
.patient-care-calendar .rbc-time-slot.unavailable{
  background-color: $color-grey-lighter;
}

.patient-care-calendar .rbc-day-slot.unavailable .patient-care-calendar-event {
  background-color: $color-white;
  color: $color-white;
  transition: background-color .3s;
}

.patient-care-calendar .rbc-day-slot.unavailable .patient-care-calendar-event:hover {
  background-color: $color-accent;
  z-index: 2;
}

// calendar blocked availability view styles
.patient-care-calendar .rbc-time-column.blocked,
.patient-care-calendar .rbc-day-bg.blocked,
.patient-care-calendar .rbc-day-slot.blocked,
.patient-care-calendar .rbc-time-slot.blocked{
  background-color: $color-grey;
}

.patient-care-calendar .rbc-day-slot.blocked .patient-care-calendar-event {
  background-color: $color-white;
  color: $color-white;
  transition: background-color .3s;
}

.patient-care-calendar .rbc-day-slot.blocked .patient-care-calendar-event:hover {
  background-color: $color-accent;
  z-index: 2;
}

// calendar booking cutoff view styles
.patient-care-calendar .rbc-time-column.cutoff,
.patient-care-calendar .rbc-day-bg.cutoff,
.patient-care-calendar .rbc-today.cutoff {
  background-image: (linear-gradient(45deg,
    transparent 48%, $color-grey 49%, $color-grey 51%, transparent 52%));
  background-size: 10px 10px;
}

// calendar cancelled appointments styles
.patient-care-calendar-event-title.-cancelled {
  text-decoration: line-through;
}

.patient-care-calendar .patient-care-calendar-event.cancelled {
  background-color: $color-white;
}

.patient-care-calendar .rbc-event.cancelled {
  color: $color-grey;
}

// calendar faded appointments styles
.patient-care-calendar .patient-care-calendar-event.faded {
  background-color: $color-booked-faded-appointment-bg;
}

.patient-care-calendar .patient-care-calendar-event.faded.cancelled {
  background-color: $color-white;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-1 {
  background-color: $color-booked-faded-appointment-bg-1;
  color: $color-booked-faded-appointment-text-1;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-2 {
  background-color: $color-booked-faded-appointment-bg-2;
  color: $color-booked-faded-appointment-text-2;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-3 {
  background-color: $color-booked-faded-appointment-bg-3;
  color: $color-booked-faded-appointment-text-3;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-4 {
  background-color: $color-booked-faded-appointment-bg-4;
  color: $color-booked-faded-appointment-text-4;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-5 {
  background-color: $color-booked-faded-appointment-bg-5;
  color: $color-booked-faded-appointment-text-5;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-6 {
  background-color: $color-booked-faded-appointment-bg-6;
  color: $color-booked-faded-appointment-text-6;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-7 {
  background-color: $color-booked-faded-appointment-bg-7;
  color: $color-booked-faded-appointment-text-7;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-8 {
  background-color: $color-booked-faded-appointment-bg-8;
  color: $color-booked-faded-appointment-text-8;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-9 {
  background-color: $color-booked-faded-appointment-bg-9;
  color: $color-booked-faded-appointment-text-9;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-10 {
  background-color: $color-booked-faded-appointment-bg-10;
  color: $color-booked-faded-appointment-text-10;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-11 {
  background-color: $color-booked-faded-appointment-bg-11;
  color: $color-booked-faded-appointment-text-11;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-12 {
  background-color: $color-booked-faded-appointment-bg-12;
  color: $color-booked-faded-appointment-text-12;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-13 {
  background-color: $color-booked-faded-appointment-bg-13;
  color: $color-booked-faded-appointment-text-13;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-14 {
  background-color: $color-booked-faded-appointment-bg-14;
  color: $color-booked-faded-appointment-text-14;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-15 {
  background-color: $color-booked-faded-appointment-bg-15;
  color: $color-booked-faded-appointment-text-15;
}

.patient-care-calendar .patient-care-calendar-event.faded.calendar-color-16 {
  background-color: $color-booked-faded-appointment-bg-16;
  color: $color-booked-faded-appointment-text-16;
}

.patient-care-calendar .patient-care-calendar-event.faded .rbc-event-label,
.patient-care-calendar .patient-care-calendar-event.faded .rbc-event-content {
  opacity: 0.5;
}

.rbc-event.patient-care-calendar-event.-available-time-slot {
  background-color: $color-white;
}

.rbc-event.patient-care-calendar-event.selected,
.rbc-event.patient-care-calendar-event.-available-time-slot:hover {
  background-color: $color-accent !important;
}

.rbc-event.patient-care-calendar-event .rbc-event-label,
.rbc-event.patient-care-calendar-event.-available-time-slot .rbc-event-label {
  visibility: hidden;
}

.rbc-event.patient-care-calendar-event.selected .rbc-event-label,
.rbc-event.patient-care-calendar-event.-available-time-slot:hover .rbc-event-label {
  visibility: visible;
  color: $color-white;
}

.patient-care-calendar-event.overridden {
  left: 0 !important;
  width: 100% !important;
}

// calendar avatars styles
.patient-care-calendar .patient-care-day-view .rbc-time-header-content {
  text-align: center;
}

.patient-care-calendar .patient-care-day-view .rbc-time-header-content,
.patient-care-calendar .rbc-time-view-resources .rbc-day-slot {
  min-width: $size-small / 3;
}

.patient-care-calendar .rbc-row-resource .rbc-header {
  background-color: $color-avatar-bg;
  border-radius: 50%;
  color: $color-white;
  display: inline-block;
  font-size: $font-size-small;
  font-weight: 900;
  height: $size-avatar-small;
  line-height: $size-avatar-small;
  margin: $spacing-smaller;
  padding: 0;
  width: $size-avatar-small;
}

.patient-care-calendar :not(.-available-time-slot).summary-short .rbc-event-label,
.patient-care-calendar :not(.-available-time-slot).summary-extra-short .rbc-event-label,
.patient-care-calendar :not(.-available-time-slot).summary-extra-short .patient-care-calendar-event-fullname {
  display: none;
}

.patient-care-calendar {

  :not(.-available-time-slot) {
    &.summary-short,
    &.summary-extra-short {
      .rbc-event-label {
        display: none;
      }
    }
    &.summary-extra-short .patient-care-calendar-event-fullname {
      display: none;
    }
  }
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-1 {
  background-color: $color-avatar-bg-1;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-2 {
  background-color: $color-avatar-bg-2;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-3 {
  background-color: $color-avatar-bg-3;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-4 {
  background-color: $color-avatar-bg-4;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-5 {
  background-color: $color-avatar-bg-5;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-6 {
  background-color: $color-avatar-bg-6;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-7 {
  background-color: $color-avatar-bg-7;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-8 {
  background-color: $color-avatar-bg-8;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-9 {
  background-color: $color-avatar-bg-9;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-10 {
  background-color: $color-avatar-bg-10;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-11 {
  background-color: $color-avatar-bg-11;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-12 {
  background-color: $color-avatar-bg-12;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-13 {
  background-color: $color-avatar-bg-13;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-14 {
  background-color: $color-avatar-bg-14;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-15 {
  background-color: $color-avatar-bg-15;
}

.patient-care-calendar .rbc-row-resource .rbc-header.avatar-16 {
  background-color: $color-avatar-bg-16;
}

.main-content-with-list-view {
  bottom: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;
}

.no-appointments-close{
  float: right;
}

.empty-data-info{
  text-align: center;
  font-weight: bold;
}