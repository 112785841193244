// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager-content
// task-manager-content:ui-kit-no-results


// task-manager-content
.task-manager-content {
  flex: 1;
  overflow-y: auto;
}


// task-manager-content:ui-kit-no-results
.task-manager-content > .ui-kit-no-results {
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}
