// styles.scss

// dependencies
// wrapper


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// wrapper
.wrapper {
  margin: 0 auto;
  max-width: $size-maxwidth;
}
