// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-body
// calendar-filters-body-expanded
// calendar-filters-body-collapsed


// calendar-filters-body
.calendar-filters-body {
  border-top: 1px solid $color-grey-light;
  margin-top: $spacing-small;
  padding-top: $spacing-small;
}


// calendar-filters-body-expanded
.calendar-filters-body-expanded {
  display: block;
}


// calendar-filters-body-collapsed
.calendar-filters-body-collapsed {
  display: none;
}
