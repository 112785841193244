// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task


// task
.task {
  border-bottom: 1px solid $color-grey-light;
  padding: $spacing-small;
  position: relative;
}
