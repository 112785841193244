// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-content
// calendar-filters-content-top
// calendar-filters-content-scroll


// calendar-filters-content
.calendar-filters-content {
  flex: 1;
  overflow-y: auto;
}


// calendar-filters-content-top
.calendar-filters-content-top +
.calendar-filters-footer {
  box-shadow: none;
  margin-bottom: -($spacing-small * 2 + $size-form-medium);
  transition: margin .3s ease-in-out, box-shadow .3s ease-in-out;
}


// calendar-filters-content-scroll
.calendar-filters-content-scroll +
.calendar-filters-footer {
  box-shadow: $box-shadow-top;
  margin-bottom: 0;
  transition: margin .3s ease-in-out, box-shadow .3s ease-in-out;
}
