// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-nominations
// card-reports-nominations-active


// card-reports-nominations
.card-reports-nominations {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  padding: $spacing-medium;
}

.card-reports-nominations h3,
.card-reports-nominations p {
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}

.card-reports-nominations h4 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-small);
  margin-top: $spacing-smaller;
}

.card-reports-nominations p {
  align-items: center;
  display: flex;
  margin-top: $spacing-smaller;
}

.card-reports-nominations p i,
.card-reports-nominations p svg {
  display: block;
  height: $size-icon-medium;
  width: $size-icon-medium;
}

.card-reports-nominations p i + span {
  margin-left: ($spacing-smaller / 2);
}

.card-reports-nominations p svg * {
  fill: $color-white;
}


// card-reports-nominations-active
.card-reports-nominations-active {
  background: $color-accent;
}

.card-reports-nominations-active h3,
.card-reports-nominations-active h4,
.card-reports-nominations-active p {
  color: $color-white;
}
