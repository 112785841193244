// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-sidebar-menu


// reports-sidebar-menu
.reports-sidebar-menu {
  background: $color-white;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-medium;
}

.reports-sidebar-menu ul,
.reports-sidebar-menu li {
  margin: 0;
  padding: 0;
}

.reports-sidebar-menu ul {
  flex: 1;
}

.reports-sidebar-menu li {
  list-style-type: none;

  overflow: hidden;
  white-space: nowrap;
}

.reports-sidebar-menu li + li {
  margin-top: $spacing-medium;
}

.reports-sidebar-menu p {
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-smedium);
  margin-top: $spacing-small;
}

.reports-sidebar-menu p span {
  display: block;

  overflow: hidden;
  white-space: nowrap;
}

.reports-sidebar-menu a {
  align-items: center;
  color: $color-grey-dark;
  display: flex;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-medium);
}

.reports-sidebar-menu a i,
.reports-sidebar-menu a svg {
  display: block;
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.reports-sidebar-menu a svg * {
  fill: $color-grey-dark;
  transition: fill .3s ease-in-out;
}

.reports-sidebar-menu a.active,
.reports-sidebar-menu a:hover {
  color: $color-accent;
}

.reports-sidebar-menu a.active svg *,
.reports-sidebar-menu a:hover svg * {
  fill: $color-accent;
}
