$screen-desktop-xsmall: (min-width: 800px);
$desktop-xsmall: 800px;

$screen-desktop-small: (min-width: 1100px);
$desktop-small: 1100px;

$screen-desktop-medium: (min-width: 1300px);
$desktop-medium: 1300px;

$color-avatar-bg: #6ABE8F;
$color-booked-appointment-text: #2A4C39;
$color-booked-appointment-bg: #D2EBDD;
$color-booked-faded-appointment-bg: #e8f5ee;

$scroll-width: 17px;
$box-shadow-bottom: 2px 2px 5px 0 rgba($color-primary, .15);
$box-shadow-table: 0 0 5px 0 rgba($color-primary, .15);

$color-avatar-bg-1: #6ABE8F;
$color-booked-appointment-bg-1: #D2EBDD;
$color-booked-appointment-text-1: #2A4C39;
$color-booked-faded-appointment-bg-1: #f1f9f4;
$color-booked-faded-appointment-text-1: #D2EBDD;

$color-avatar-bg-2: #ECAD52;
$color-booked-appointment-bg-2: #F9E9CB;
$color-booked-appointment-text-2: #5e2520;
$color-booked-faded-appointment-bg-2: #fdf7ef;
$color-booked-faded-appointment-text-2: #cfc7bc;

$color-avatar-bg-3: #e5a29c;
$color-booked-appointment-bg-3: #f7e3e1;
$color-booked-appointment-text-3: #5b403e;
$color-booked-faded-appointment-bg-3: #f7e3e1;
$color-booked-faded-appointment-text-3: #cec6c5;

$color-avatar-bg-4: #8b80ba;
$color-booked-appointment-bg-4: #dcd9ea;
$color-booked-appointment-text-4: #37334a;
$color-booked-faded-appointment-bg-4: #f4f3f8;
$color-booked-faded-appointment-text-4: #c3c2c9;

$color-avatar-bg-5: #ca4e90;
$color-booked-appointment-bg-5: #efcadd;
$color-booked-appointment-text-5: #501f39;
$color-booked-faded-appointment-bg-5: #faeff4;
$color-booked-faded-appointment-text-5: #cabcc3;

$color-avatar-bg-6: #76ced3;
$color-booked-appointment-bg-6: #d6f0f1;
$color-booked-appointment-text-6: #2f5254;
$color-booked-faded-appointment-bg-6: #f2fafa;
$color-booked-faded-appointment-text-6: #c1cbcc;

$color-avatar-bg-7: #c44a1e;
$color-booked-appointment-bg-7: #edc9bb;
$color-booked-appointment-text-7: #4e1d0c;
$color-booked-faded-appointment-bg-7: #f9eeea;
$color-booked-faded-appointment-text-7: #cabbb6;

$color-avatar-bg-8: #58a854;
$color-booked-appointment-bg-8: #cde5cc;
$color-booked-appointment-text-8: #234321;
$color-booked-faded-appointment-bg-8: #f0f7ef;
$color-booked-faded-appointment-text-8: #bdc6bc;

$color-avatar-bg-9: #1999c9;
$color-booked-appointment-bg-9: #bae0ee;
$color-booked-appointment-text-9: #0a3d50;
$color-booked-faded-appointment-bg-9: #eaf5f9;
$color-booked-faded-appointment-text-9: #b5c5ca;

$color-avatar-bg-10: #eb9414;
$color-booked-appointment-bg-10: #f9dfb8;
$color-booked-appointment-text-10: #5e3b08;
$color-booked-faded-appointment-bg-10: #fdf5e9;
$color-booked-faded-appointment-text-10: #cfc4b5;

$color-avatar-bg-11: #ea3c8e;
$color-booked-appointment-bg-11: #f8c4dd;
$color-booked-appointment-text-11: #5d1838;
$color-booked-faded-appointment-bg-11: #fcedf4;
$color-booked-faded-appointment-text-11: #cebac3;

$color-avatar-bg-12: #8946d7;
$color-booked-appointment-bg-12: #dbc7f3;
$color-booked-appointment-text-12: #361c56;
$color-booked-faded-appointment-bg-12: #f4eefb;
$color-booked-faded-appointment-text-12: #c3bbcc;

$color-avatar-bg-13: #2428ec;
$color-booked-appointment-bg-13: #bdbef9;
$color-booked-appointment-text-13: #0e105e;
$color-booked-faded-appointment-bg-13: #ebebfd;
$color-booked-faded-appointment-text-13: #b7b7cf;

$color-avatar-bg-14: #0ed359;
$color-booked-appointment-bg-14: #b7f1cd;
$color-booked-appointment-text-14: #2f5254;
$color-booked-faded-appointment-bg-14: #e9faf0;
$color-booked-faded-appointment-text-14: #b4ccbd;

$color-avatar-bg-15: #24d9d6;
$color-booked-appointment-bg-15: #bdf3f2;
$color-booked-appointment-text-15: #0e5655;
$color-booked-faded-appointment-bg-15: #ebfbfb;
$color-booked-faded-appointment-text-15: #b7cccc;

$color-avatar-bg-16: #ff5a2a;
$color-booked-appointment-bg-16: #ffcdbf;
$color-booked-appointment-text-16: #662410;
$color-booked-faded-appointment-bg-16: #fff0eb;
$color-booked-faded-appointment-text-16: #d1bdb7;

$color-loader: #4768fd;
$color-loader-rgba: rgba($color-loader, .25);

// NEW
$size-icon-custom: 20px;

$color-pending-appointment-alert-bg:#C9E4FD;
$color-update-status-link:#4768FC;
$size-pend-app-padding: 10px;
$size-pend-app-width: 100px;

$color-bulkappointment-background: #FDECEF;
$color-bulkappointment-header: #32325d;
$color-calendar-list-border: #DEDEE3;
$color-calendar-edit: #4768FC;
$color-calendar-border: #ccc;
$color-table-calendar: #f0f0f0;
$color-appointment-pending: #E01A38;

$color-upcoming: #01882A;
$color-service-provided: #4768FC;
$color-appointment-status: #D9D9D9;
$color-customer-not-attended: #7361C3;
$color-appointment-overlay: #FDECEF;
$color-calendar-row-border: #dedee3;
$color-calendar-date: #32335A;
$color-calendar-title: #6f6f8c;
$color-error: #e01937