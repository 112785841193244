// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// recharts-nominations


// recharts-nominations
.recharts-nominations .recharts-xAxis,
.recharts-nominations .recharts-yAxis {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
}

.recharts-nominations .recharts-xAxis text,
.recharts-nominations .recharts-yAxis text {
  fill: $color-grey-dark !important;
}
