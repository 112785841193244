@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-appointments-holder {
  animation: move-right 0.3s ease;
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  bottom: 0;
  line-height: $ratio-small;
  position: absolute;
  right: 0;
  top: 0;
  transition: right .3s;
  width: $size-small;
  z-index: 1;
}

.patient-care-sidebar-appointments-body {
  height: calc(100% - 96px);
  overflow-y: auto;
}

.patient-care-sidebar-appointments-body.has-btn {
  height: calc(100% - 156px);
}

.patient-care-time-slots-block {
  padding: 0 $spacing-small;
}

.patient-care-time-slots-mask {
  height: 60px; // #58681 BUG
}

.patient-care-slots-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 (-$spacing-small) 0 0;
  padding: 0;
}

.patient-care-subtitle {
  color: $color-grey-dark;
  display: block;
  font-weight: 700;
  margin-top: $spacing-small;
}

.patient-care-slot-title {
  color: $color-grey-dark;
  margin-top: -$spacing-smaller;
  padding: $spacing-small 0;
  width: 100%;
}

.patient-care-slot-title:first-child {
  margin-top: 0;
}

.patient-care-slot-title + ul {
  padding-bottom: $spacing-small;
}

.patient-care-slot-title + ul:last-child {
  padding-bottom: 0;
}

.patient-care-time-slots-item {
  margin: 0 $spacing-smaller $spacing-small 0;
  transition: background-color .3s, color .3s, border-color .3s;
}

.patient-care-slots-list .ui-kit-form .input-radio-time {
  margin: 0;
  width: $size-small - 220px;
}

.patient-care-time-slots-item.hovered {
  pointer-events: none; // prevent double-click #41033
}

.patient-care-time-slots-item.hovered .ui-kit-form .input-radio-time span {
  background-color: $color-accent;
  border-color: transparent;
  color: $color-white;
}
