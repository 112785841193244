// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation


// header-navigation
.header-navigation {
  display: flex;
}
