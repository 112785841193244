@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-sidebar-agenda-body {
  height: calc(100% - 134px);
  overflow-y: auto;
}

.patient-care-sidebar-agenda-body-with-btn {
  height: calc(100% - 194px);
  overflow-y: auto;
}

.patient-care-appointments-holder .patient-care-sidebar-agenda-body {
  height: calc(100% - 96px);
  margin-top: $spacing-larger + $spacing-smaller;
}

.patient-care-appointments-holder .patient-care-sidebar-agenda-body-with-btn {
  // height: calc(100% - 157px);
  height: calc(100% - 256px); // fix bug #78638
  margin-top: $spacing-larger + $spacing-smaller;
}

.patient-care-results-holder .patient-care-sidebar-agenda-body {
  height: calc(100% - 64px);
  margin-top: $spacing-larger + $spacing-small;
}

.patient-care-results-holder .patient-care-sidebar-agenda-body-with-btn {
  height: calc(100% - 180px);
  margin-top: $spacing-larger + $spacing-small;
}
