// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-pharmacy-skeleton
// card-reports-pharmacy-skeleton-row


// card-reports-pharmacy-skeleton
.card-reports-pharmacy-skeleton {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  margin-top: $spacing-medium;
}


// card-reports-pharmacy-skeleton-row
.card-reports-pharmacy-skeleton-row {
  display: flex;
  flex-direction: column;
  padding: $spacing-medium;
}

.card-reports-pharmacy-skeleton-row > div:nth-of-type(1) {
  margin-top: ($spacing-smaller / 2) + 1px;
}

.card-reports-pharmacy-skeleton-row > div:nth-of-type(2) {
  margin-top: ($spacing-smaller / 2) + 2px + $spacing-smaller + 6px;
  margin-bottom: ($spacing-smaller / 2);
}

.card-reports-pharmacy-skeleton-row +
.card-reports-pharmacy-skeleton-row {
  border-top: 1px solid $color-grey-light;
}
