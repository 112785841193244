// styles.scss

// dependencies
// section-feature
// section-feature-wrapper
// section-feature-blob
// section-feature-header
// section-feature-body
// section-feature-content
// section-feature-image
// screen-medium
// screen-medium:section-feature-body
// screen-medium:section-feature-content
// screen-medium:section-feature-image


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-feature
.section-feature {
  padding-top: $spacing-larger;
  padding-bottom: $spacing-larger;
}

// section-feature-wrapper
.section-feature-wrapper {
  position: relative;
}


// section-feature-blob
.section-feature-blob {
  position: absolute;
  z-index: -1;
}

.section-feature-blob img {
  display: block;
  height: auto;
}


// section-feature-header
.section-feature-header {
  text-align: center;
  margin-bottom: $spacing-larger;
}

.section-feature-header h2 {
  font-family: $font-family-serif;
  font-size: ($font-size-medium * 2);
  font-weight: 900;
  line-height: round($font-size-medium * 2 * $ratio-smedium);
}


// section-feature-body
.section-feature-body {
  position: relative;
}

// section-feature-image
.section-feature-image {
  margin-bottom: $spacing-larger;
}

.section-feature-image img {
  width: 100%;
  height: auto;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:section-feature-body
  .section-feature-body {
    height: 596px; // non-standard
    display: flex;
    align-items: center;
  }

  .section-feature-body.lside .section-feature-content {
    margin-left: auto;
  }

  .section-feature-body.lside .section-feature-image {
    right: 428px;
  }

  .section-feature-body.rside .section-feature-content {
    margin-right: auto;
  }

  .section-feature-body.rside .section-feature-image {
    left: 428px;
  }


  // screen-medium:section-feature-content
  .section-feature-content {
    width: 380px; // non-standard
  }


  // screen-medium:section-feature-image
  .section-feature-image {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: 812px; // non-standard
  }

}
