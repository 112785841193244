// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-task
// skeleton-task-content
// skeleton-task-footer


// skeleton-task
.skeleton-task {
  border-bottom: 1px solid $color-grey-light;
  padding: 22px $spacing-small; // non-standard
}

.skeleton-task .ui-kit-skeleton-line + .ui-kit-skeleton-line {
  margin-top: 12px; // non-standard
}


// skeleton-task-content
.skeleton-task-content {
  margin-top: 22px; // non-standard
}


// skeleton-task-footer
.skeleton-task-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 22px; // non-standard
}

.skeleton-task-footer .ui-kit-skeleton-line + .ui-kit-skeleton-line {
  margin-top: 0;
}
