// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-section


// calendar-filters-section
.calendar-filters-section {
  border-bottom: 1px solid $color-grey-light;
  padding: $spacing-small;
}
