@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-results-holder {
  animation: move-right 0.3s ease;
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: right .3s;
  width: $size-small;
  z-index: 1;
}

.patient-care-main-holder.active.full-width + .patient-care-results-holder {

  @media #{$screen-desktop-medium} {
    right: $size-small;
  }
}
.patient-care-main-holder.active.full-width + .patient-care-appointments-holder {

  @media #{$screen-desktop-medium} {
    right: $size-small;
  }
}

.patient-care-sidebar-results-body {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.patient-care-sidebar-results-body.has-btn {
  height: calc(100% - 124px);
}

.patient-care-results-item {
  border: 1px solid $color-grey-light;
  cursor: pointer;
  margin-bottom: $spacing-small;
  padding: $spacing-smaller $spacing-smaller;
  transition: background-color .3s;
}

.patient-care-results-item:hover {
  background-color: $color-grey-lighter;
}

.patient-care-results-time-holder {
  border-bottom: 1px solid $color-grey-light;
  padding: $spacing-smaller 0;
}

.patient-care-results-date {
  display: block;
  font-weight: 700;
}
