// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// reports-basic-chart:status
// screen-desktop-small
// screen-desktop-small:reports-basic-chart:status
// screen-desktop-medium
// screen-desktop-medium:reports-basic-chart:status


// reports-basic-chart:status
.reports-basic-chart.status {
  margin-top: 0;
  margin-left: -($spacing-small);
  margin-right: -($spacing-small);
}

.reports-basic-chart.status,
.reports-basic-chart.status .reports-basic-loader {
  height: 350px; // non-standard
}

.reports-basic-chart.status > div {
  margin: 0 auto;
}

.reports-basic-chart.status .recharts-layer text {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
}


// screen-desktop-small
@media #{$screen-desktop-small} {

  // screen-desktop-small:reports-basic-chart:status
  .reports-basic-chart.status {
    margin-bottom: -($spacing-small);
  }

  .reports-basic-chart.status,
  .reports-basic-chart.status .reports-basic-loader {
    height: 250px; // non-standard
  }

}


// screen-desktop-medium
@media #{$screen-desktop-medium} {

  // screen-desktop-medium:reports-basic-chart:status
  .reports-basic-chart.status,
  .reports-basic-chart.status .reports-basic-loader {
    height: 350px; // non-standard
  }

}
