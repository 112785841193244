// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-content


// task-content
.task-content {
  margin-top: $spacing-small;
}
