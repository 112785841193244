// styles.scss

// dependencies
// header
// header-scroll
// header-wrapper
// header-logo
// header-links
// screen-medium
// screen-medium:header-logo


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// header
.header {
  background: $color-white;
  box-sizing: border-box;
  left: 0;
  min-width: $size-minwidth;
  padding-left: $spacing-small;
  padding-right: $spacing-small;
  padding-bottom: 18px; // non-standard
  padding-top: 18px; // non-standard
  position: fixed;
  top: 0;
  transition: background .3s ease-in-out, box-shadow .3s ease-in-out;
  width: 100%;
  z-index: 2;
}


// header-scroll
.header-scroll {
  box-shadow: $box-shadow-normal;
}


// header-wrapper
.header-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}


// header-logo
.header-logo {
  flex-shrink: 0;
}

.header-logo a,
.header-logo svg {
  display: block;
}

.header-logo a {
  overflow: hidden;
  width: 40px; // non-standard
  height: 36px; // non-standard
}

.header-logo svg {
  height: 54px; // non-standard
  width: auto;
}

.header-logo span {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}


// header-links
.header-links {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: $spacing-small;
}

.header-links a {
  width: auto;
}

.header-links a.ui-kit-button-secondary:hover {
  color: $color-white;
}

.header-links a:nth-of-type(2) {
  margin-left: $spacing-small;
  margin-top: 0;
}

.header-links a::after {
  box-sizing: content-box;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:header-logo
  .header-logo a,
  .header-logo svg {
    height: 40px; // non-standard
  }

  .header-logo a {
    width: auto;
  }

}
