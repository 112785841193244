@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-modal-table-block {
  margin: 0 (-$spacing-medium);
}

.patient-care-modal-table-block .patient-care-custom-table-header {
  padding: 0 ($spacing-small + $spacing-larger);
}

.patient-care-table-header-with-checkbox {
  align-items: center;
  background-color: $color-grey-lighter;
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  padding: $spacing-smaller $spacing-medium;
}

.patient-care-table-item {
  border-bottom: 1px solid $color-grey-light;
  padding: ($spacing-small - 4px) $spacing-medium;
}

.patient-care-table-item:last-child {
  border-bottom: 0;
}

.patient-care-services-table .ui-kit-form .input-checkbox-medium span::before {
  height: $spacing-medium;
  width: $spacing-medium;
}

.patient-care-services-table .ui-kit-form label {
  font-size: $font-size-small;
}