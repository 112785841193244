@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// breadcrumbs styles
.patient-care-user-breadcrumbs-holder {
  background-color: $color-grey-lighter;
  padding: $spacing-large 0 $spacing-medium;
}

.patient-care-user-breadcrumbs-holder.has-button .patient-care-container {
  max-width: calc(100% - 225px);
}

.patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs ul {
  flex-wrap: nowrap;
  width: 100%;
}

.patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs li:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs-label a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs-label:nth-child(3) {
  max-width: 60%;

  @media (min-width: $desktop-small) {
    max-width: 80%;
  }
}

.patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs-label:nth-child(3):last-child {
  max-width: 76%;

  @media (min-width: $desktop-small) {
    max-width: 100%;
  }
}

.patient-care-user-breadcrumbs-holder.has-button .ui-kit-breadcrumbs-label:nth-child(3):last-child {
  max-width: 44%;

  @media (min-width: $desktop-small) {
    max-width: 100%;
  }
}

.patient-care-user-breadcrumbs-holder.has-button .ui-kit-breadcrumbs-label:nth-child(3) {
  max-width: 40%;

  @media (min-width: $desktop-small) {
    max-width: 60%;
  }
}

.patient-care-user-breadcrumbs-holder.has-button .ui-kit-breadcrumbs-label:nth-child(5) {
  max-width: 30%;

  @media (min-width: $desktop-small) {
    max-width: 60%;
  }
}

// sidebar breadcrumbs styles
.patient-care-breadcrumbs {
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  font-weight: 700;
  margin: 0 (-$spacing-small);
  padding: 0 $spacing-small $spacing-small;
}

.patient-care-breadcrumbs-icon {
  margin-right: $spacing-smaller;
}

.patient-care-breadcrumbs-icon svg * {
  fill: $color-accent;
}

.patient-care-breadcrumbs-item {
  color: $color-primary;

  .patient-care-user-breadcrumbs-holder .ui-kit-breadcrumbs-label a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
