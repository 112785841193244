// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-body-item
// calendar-filters-body-item:button-settings


// calendar-filters-body-item
.calendar-filters-body-item {
  position: relative;
}

.calendar-filters-body-item +
.calendar-filters-body-item {
  margin-top: $spacing-small;
}

.calendar-filters-body-item label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-filters-body-item:hover > a + div {
  padding-right: $size-icon-larger + ($spacing-smaller / 2);
}


// calendar-filters-body-item:button-settings
.calendar-filters-body-item .button-settings {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}

.calendar-filters-body-item:hover .button-settings {
  opacity: 1;
}
