@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// header-navigation
@import './styles/header-navigation.scss';
@import './styles/header-navigation-item.scss';
@import './styles/header-navigation-button.scss';
@import './styles/header-navigation-tooltip.scss';
@import './styles/header-navigation-support.scss';
@import './styles/header-navigation-account.scss';

.patient-care-header {
  height: $size-header-desktop;
  padding: $spacing-smaller 0;
  transition: margin-right .3s;
}

.patient-care-header.active {

  @media #{$screen-desktop-medium} {
    margin-right: $size-small;
  }
}

.patient-care-header-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 $spacing-medium;
}

.patient-care-nav-col {
  padding-right: $spacing-medium / 2;
  width: 70%;

  @media (min-width: $desktop-small) {
    width: 58.33%;
  }
}

.patient-care-user-col {
  padding-left: $spacing-medium / 2;
  margin: ($spacing-medium / 2) 0;
  // width: 30%;

  @media (min-width: $desktop-small) {
    // width: 41.66%;
  }
}

.patient-care-logo-wrap {
  width: ($size-small - 105px);
}

.patient-care-logo {
  margin-right: $spacing-larger;
  overflow: hidden;
  width: 40px;
}

.patient-care-logo svg {
  width: ($size-small - 30px);
  height: auto;
}

.patient-care-header-list {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0 ($spacing-medium * 2) 0 0;
  padding: 0;

  @media (max-width: $desktop-small) {
    width: 100%;
  }
}

.patient-care-header-list.active {

  @media (min-width: $desktop-medium) {
    margin-right: 0;
  }
}

.patient-care-header-list.no-agenda {
  margin-right: 0;
}

.patient-care-header-item {
  padding: 0 ($spacing-medium / 2);

  @media #{$screen-desktop-small} {
    padding: 0 ($spacing-small - 4px);
  }
}

.patient-care-header-item:first-child {
  padding-left: 0;
  @media (max-width: $desktop-small) {
    // width: 28px;
  }
}

.patient-care-header-item:last-child {
  padding-right: 0;
  @media (max-width: $desktop-small) {
    max-width: calc(100% - 80px);
  }
}

// patient-care-header-support
.patient-care-header-support {
  position: relative;
  white-space: nowrap;
}

.patient-care-header-support-link,
.patient-care-header-support-link:hover {
  color: $color-primary;
}

.patient-care-header-support-link {
  font-weight: 700;
  display: block;
}

.patient-care-header-support-link span {
  margin: 0 $spacing-smaller;
}

.patient-care-header-support-link svg * {
  fill: $color-primary;
}

.patient-care-users-holder {
  position: relative;
  transition: margin-right .3s;
}

.patient-care-dropdown-opener {
  cursor: pointer;
}

.patient-care-dropdown-opener i {
  margin: 0 0 0 $spacing-smaller;
}

.patient-care-dropdown-opener svg * {
  fill: $color-primary;
}

.patient-care-user-name {
  display: inline-block;
  max-width: calc(100% - 22px);
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.patient-care-dropdown-wrap {
  background-color: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  list-style: none;
  position: absolute;
  top: 100%;
  z-index: 5004;
}

.patient-care-nav-list .patient-care-dropdown-wrap {
  margin-top: -$spacing-smaller;
}

.patient-care-users-holder .patient-care-dropdown-wrap {
  margin-top: $spacing-smaller;
  right: 0;
  width: ($size-small + 100px);
}

.patient-care-nav {
  @media (max-width: $desktop-small) {
    max-width: calc(100% - 195px);
  }
}

.patient-care-nav .patient-care-dropdown-wrap {
  left: - ($size-small - 112px);
  width: ($size-large + 110px);
}

.patient-care-nav .patient-care-dropdown-wrap.small {
  left: 50%;
  transform: translateX(-50%);
  width: ($size-small + $spacing-small);
}

.patient-care-dropdown-content-bordered {
  border-bottom: 1px solid $color-grey-light;
  padding: $spacing-small;
}

.patient-care-profile-name {
  display: block;
  font-weight: 900;
  overflow: hidden;
  padding-bottom: $spacing-smaller / 2;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-profile-role {
  display: block;
  color: $color-grey-dark;
  line-height: $ratio-small;
  padding-bottom: $spacing-smaller;
}

.patient-care-dropdown-content {
  padding: $spacing-medium;
}

.patient-care-dropdown-content .patient-care-col-6 {
  padding: 0 $spacing-medium / 2;
}

.patient-care-profile-list {
  background-color: $color-grey-lighter;
  border-bottom: 1px solid $color-grey-light;
  list-style: none;
  margin: 0;
  max-height: ($size-small - 64px);
  overflow-y: auto;
  padding: 0 $spacing-small;
}

.patient-care-profile-list span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-profile-list li {
  border-bottom: 1px solid $color-grey-light;
  padding: ($spacing-smaller + 3px) 0;
}

.patient-care-profile-list li:last-child {
  border-bottom: 0;
}

.patient-care-profile-list li span {
  cursor: pointer;
  transition: color .3s;
}

.patient-care-profile-list li:hover span {
  color: $color-accent;
}

.logout {
  background-color: transparent;
  border: 0;
  color: $color-grey;
  cursor: pointer;
  font-weight: 700;
  padding: 0;
}

.patient-care-hidden-xs {

  @media (max-width: $desktop-small) {
    display: none;
  }
}

.patient-care-xs {

  @media (max-width: $desktop-small) {
    margin-left: $spacing-smaller;
  }
}

.patient-care-support-link--support {
  display: inline-block;
  width: 135px;
}
