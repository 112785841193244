// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-footer
// sidebar-footer:row
// sidebar-footer:column
// sidebar-footer:ui-kit-button


// sidebar-footer
.sidebar-footer {
  padding: $spacing-medium;
  transition: box-shadow .3s ease-in-out;
  z-index: 3;
}

.sidebar-footer p.error {
  color: $color-status-error;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
}


// sidebar-footer:row
.sidebar-footer .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


// sidebar-footer:column
.sidebar-footer .column + .column {
  margin-left: $spacing-smaller;
}


// sidebar-footer:ui-kit-button
.sidebar-footer .ui-kit-button + .ui-kit-button {
  margin-left: $spacing-small;
}
