// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-header
// patient-care-feature-content-header:row
// patient-care-feature-content-header:column
// patient-care-feature-content-header:column-full
// patient-care-feature-content-header:column:negative
// patient-care-feature-content-header:spacing-medium


// patient-care-feature-content-header
.patient-care-feature-content-header {
  padding: $spacing-large $spacing-large $spacing-medium;
}

.patient-care-feature-content-header .ui-kit-button + .ui-kit-button {
  margin-left: $spacing-small;
}


// patient-care-feature-content-header:row
.patient-care-feature-content-header .row {
  display: flex;
  justify-content: space-between;
}

.patient-care-feature-content-header .row + .row {
  margin-top: $spacing-medium;
}


// patient-care-feature-content-header:column
.patient-care-feature-content-header .column + .column {
  margin-left: $spacing-small;
}

// patient-care-feature-content-header:column-full
.patient-care-feature-content-header .column-full {
  flex: 1;
}


// patient-care-feature-content-header:column:negative
.patient-care-feature-content-header .column .negative {
  margin-bottom: -10px; // non-standard
  margin-top: -10px; // non-standard
}


// patient-care-feature-content-header:spacing-medium
.patient-care-feature-content-header.spacing-medium {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

// patient-care-feature-content-header:no-spacing
.patient-care-feature-content-header.no-spacing {
  padding: 0;
}
