// styles.scss

// dependencies
// section-tabs
// section-tabs-header
// section-tabs-content
// screen-medium
// screen-medium:section-tabs-content


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-tabs
.section-tabs {
  padding-top: 0;
}


// section-tabs-header
.section-tabs-header {
  text-align: center;
  margin-bottom: $spacing-medium;
}

.section-tabs-header p {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: round($font-size-large * $ratio-small);
}


// section-tabs-content
.section-tabs-content a {
  color: $color-primary;
  font-weight: 700;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
  padding: 27px $spacing-medium 27px 96px; // non-standard
  box-shadow: $box-shadow-normal;
  border-radius: $border-radius-medium;
  background: $color-white;
  position: relative;
  display: block;
  width: auto;
  transition: box-shadow .3s ease-in-out;
}

.section-tabs-content a::before {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: 50%;
  box-shadow: 0 0 0 4px $color-white inset;
  box-sizing: content-box;
  content: '';
  height: 22px; // non-standard
  left: 24px; // non-standard
  top: 24px; // non-standard
  transition: border .3s ease-in-out, background .3s ease-in-out;
  width: 22px; // non-standard
  position: absolute;
}

.section-tabs-content a.active::before {
  background: $color-accent;
  border-color: $color-accent;
}

.section-tabs-content a.active svg * {
  fill: $color-accent;
}

.section-tabs-content a:hover {
  color: $color-primary;
}

.section-tabs-content a + a {
  margin-top: $spacing-medium;
}

.section-tabs-content svg {
  width: $size-icon-larger;
  height: $size-icon-larger;
  position: absolute;
  top: 24px; // non-standard
  left: 64px; // non-standard
}

.section-tabs-content svg * {
  fill: $color-grey-dark;
  transition: fill .3s ease-in-out;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:section-tabs-content
  .section-tabs-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-tabs-content a {
    width: 350px; // non-standard
  }

  .section-tabs-content a:hover {
    box-shadow: $box-shadow-hover;
  }

  .section-tabs-content a:hover svg * {
    fill: $color-accent;
  }

  .section-tabs-content a + a {
    margin-left: $spacing-large;
    margin-top: 0;
  }

}
