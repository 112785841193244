// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// reports-basic-filters
// reports-basic-filters-wrapper
// reports-basic-filters-date
// reports-basic-filters:role-super-admin
// reports-basic-filters:role-organization-admin
// reports-basic-filters:role-branch-admin
// reports-basic-filters:role-branch-member
// reports-basic-filters:overrides


// reports-basic-filters
.reports-basic-filters {
  background: $color-white;
  padding: $spacing-medium;
  border-bottom: 1px solid $color-grey-light;
  flex-shrink: 1;
}


// reports-basic-filters-wrapper
.reports-basic-filters-wrapper {
  display: flex;
}

.reports-basic-filters-wrapper > div + div {
  margin-left: $spacing-small;
}

.reports-basic-filters-wrapper:nth-of-type(1) > div {
  flex: 1;
}

.reports-basic-filters-wrapper:nth-of-type(2) {
  justify-content: space-between;
  margin-top: $spacing-medium;
}


// reports-basic-filters-date
.reports-basic-filters-date {
  display: flex;
  align-items: center;
}

.reports-basic-filters-date > div {
  width: ($size-small / 2);
}

.reports-basic-filters-date > p {
  margin: 0 $spacing-small;
}


// reports-basic-filters:overrides
.reports-basic-filter-button > .ui-kit-button {
  width: auto;
}

.reports-basic-filter-button > .ui-kit-button + .ui-kit-button {
  margin-top: 0;
  margin-left: $spacing-small;
}


// screen-desktop-medium
@media #{$screen-desktop-medium} {

  // screen-desktop-medium:reports-basic-filters
  .reports-basic-filters {
    display: flex;
  }

  .reports-basic-filters > div + div {
    margin-left: $spacing-small;
  }


  // screen-desktop-medium:reports-basic-filters-wrapper
  .reports-basic-filters-wrapper:nth-of-type(1) {
    flex: 1;
  }

  .reports-basic-filters-wrapper:nth-of-type(2) {
    margin-top: 0;
  }

}
