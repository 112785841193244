@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-section-block
// patient-care-section-block-edit
// patient-care-section-block-heading
// patient-care-section-block-content


// patient-care-section-block
.patient-care-section-block +
.patient-care-section-block {
  padding-top: $spacing-medium;
  margin-top: $spacing-medium;
  border-top: 1px solid $color-grey-light;
}

.patient-care-section-block h3 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-small);
}

.patient-care-section-block h4 {
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}

.patient-care-section-block p {
  color: $color-grey-dark;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  margin: 0;
}


// patient-care-section-block-edit
.patient-care-section-block-edit {
  color: $color-accent;
  cursor: pointer;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: round($font-size-small * $ratio-medium);
  margin-left: $spacing-smaller;
}

.patient-care-section-block-edit.disabled {
  pointer-events: none;
}


// patient-care-section-block-heading
.patient-care-section-block-heading {
  display: flex;
  justify-content: space-between;
}


// patient-care-section-block-content
.patient-care-section-block-content {
  margin-top: $spacing-smaller;
}

.patient-care-section-block-content +
.patient-care-section-block-content {
  margin-top: $spacing-medium;
}
