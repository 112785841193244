// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// ui-kit-overlay-description
// ui-kit-overlay-description:ui-kit-overlay-close
// ui-kit-overlay-description:ui-kit-overlay-content
// ui-kit-overlay-description:overlay-description-header
// ui-kit-overlay-description:overlay-description-content
// ui-kit-overlay-description:overlay-description-content-heading
// ui-kit-overlay-description:overlay-description-content-link
// ui-kit-overlay-description:overlay-description-footer


// ui-kit-overlay-description
.ui-kit-overlay-description {
  height: 80vh;
  padding: 0;
}


// ui-kit-overlay-description:ui-kit-overlay-close
.ui-kit-overlay-description .ui-kit-overlay-close {
  z-index: 5;
}

// ui-kit-overlay-description:ui-kit-overlay-content
.ui-kit-overlay-description .ui-kit-overlay-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}


// ui-kit-overlay-description:overlay-description-header
.ui-kit-overlay-description .overlay-description-header {
  padding: 0 ($spacing-medium * 2);
  height: ($spacing-large * 2);
  transition: box-shadow .3s ease-in-out;
  z-index: 1;
}


// ui-kit-overlay-description:overlay-description-content
.ui-kit-overlay-description .overlay-description-content {
  flex: 1;
  padding: 0 ($spacing-medium * 2);
  overflow-y: auto;
}

.ui-kit-overlay-description .ui-kit-overlay-content.header-shadow .overlay-description-header {
  box-shadow: $box-shadow-normal;
}

.ui-kit-overlay-description .ui-kit-overlay-content.footer-shadow .overlay-description-footer {
  box-shadow: $box-shadow-top;
}


// ui-kit-overlay-description:overlay-description-content-heading
.ui-kit-overlay-description .overlay-description-content-heading {
  margin-bottom: $spacing-medium;
}

.ui-kit-overlay-description .overlay-description-content-heading h1 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-smedium);
}


// ui-kit-overlay-description:overlay-description-content-link
.ui-kit-overlay-description .overlay-description-content-link {
  margin-top: $spacing-medium;
}

.ui-kit-overlay-description .overlay-description-content-link > a {
  color: $color-accent;
}

.ui-kit-overlay-description .overlay-description-content-link > a svg * {
  fill: $color-accent;
}

.ui-kit-overlay-description .overlay-description-content-link > a:hover {
  color: lighten($color-accent, $percentage-hover);
}

.ui-kit-overlay-description .overlay-description-content-link > a:hover svg * {
  fill: lighten($color-accent, $percentage-hover);
}


// ui-kit-overlay-description:overlay-description-footer
.ui-kit-overlay-description .overlay-description-footer {
  padding: $spacing-small ($spacing-medium * 2) ($spacing-medium * 2);
  transition: box-shadow .3s ease-in-out;
  z-index: 1;
}
