@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-blocked-times-wrap .patient-care-col-5:last-child {
  padding-right: $spacing-smaller;
}

.patient-care-сalendar-dates {
  margin-bottom: $spacing-small;
}

.patient-care-сalendar-dates.with-duration-range {
  width: 100%;
}