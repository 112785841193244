// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager-footer


// task-manager-footer
.task-manager-footer {
  box-shadow: $box-shadow-top;
  padding: $spacing-smaller $spacing-small;
}

.task-manager-footer a {
  display: block;
}

.task-manager-footer>div {
  padding-bottom: $spacing-small;
}
