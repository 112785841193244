// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager


// task-manager
.task-manager {
  background: $color-white;
  bottom: 0;
  border-left: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .3s;
  width: $size-small;
  z-index: 4;
}

// remove margin-bottom from global <p> tag
.task-manager p {
  margin: 0;
}
