// styles.scss

// dependencies
// section-bookings
// section-bookings-wrapper
// section-bookings-blob
// section-bookings-header
// section-bookings-content
// section-bookings-image
// section-bookings-testimonials
// screen-medium
// screen-medium:section-bookings-header
// screen-medium:section-bookings-content
// screen-medium:section-bookings-lside
// screen-medium:section-bookings-rside
// screen-large
// screen-large:section-bookings-rside
// screen-large:section-bookings-image
// screen-large:section-bookings-testimonials


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-bookings
.section-bookings {
  padding-top: $spacing-larger;
  padding-bottom: $spacing-larger;
}


// section-bookings-wrapper
.section-bookings-wrapper {
  position: relative;
}


// section-bookings-blob
.section-bookings-blob {
  position: absolute;
  z-index: -1;
}


// section-bookings-header
.section-bookings-header {
  text-align: center;
  margin-bottom: $spacing-larger;
}

.section-bookings-header h2 {
  font-family: $font-family-serif;
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-medium);
}


// section-bookings-content
.section-bookings-content > div + div {
  margin-top: $spacing-larger;
}


// section-bookings-image
.section-bookings-image img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 250px;
}


// section-bookings-testimonials
.section-bookings-testimonials {
  margin-top: $spacing-large;
}

.section-bookings-testimonials h3 {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: round($font-size-large * $ratio-medium);
}

.section-bookings-testimonials p {
  color: $color-grey-dark;
  text-align: center;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
  margin: $spacing-medium auto 0;
  display: none;
  max-width: 250px; // non-standard
}

.section-bookings-testimonials i {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-small;
}

.section-bookings-testimonials i svg {
  display: block;
}

.section-bookings-testimonials i svg + svg {
  margin-left: $spacing-smaller;
}

.section-bookings-testimonials i svg * {
  fill: #f4ac4e;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:section-bookings-header
  .section-bookings-header h2 {
    font-size: ($font-size-medium * 2);
    line-height: round($font-size-medium * 2 * $ratio-smedium);
  }


  // screen-medium:section-bookings-content
  .section-bookings-content {
    display: flex;
  }

  .section-bookings-content > div + div {
    margin-top: 0;
  }


  // screen-medium:section-bookings-lside
  .section-bookings-lside {
    width: 380px; // non-standard
    flex-shrink: 0;
  }


  // screen-medium:section-bookings-rside
  .section-bookings-rside {
    width: 350px; // non-standard
    margin-left: auto;
    margin-right: auto;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:section-bookings-rside
  .section-bookings-rside {
    width: auto;
    margin-left: 85px; // non-standard
    margin-right: 0;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }


  // screen-large:section-bookings-image
  .section-bookings-image img {
    max-width: 310px; // non-standard
  }


  // screen-large:section-bookings-testimonials
  .section-bookings-testimonials {
    margin-top: 0;
    width: 350px;
  }

  .section-bookings-testimonials p {
    display: block;
  }

}
