// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-pagination


// patient-care-feature-pagination
.patient-care-feature-pagination.hidden {
  display: none;
}
