// styles.scss

// dependencies
// section
// section-block
// section-block-list


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section
.section {
  overflow: hidden;
  padding-top: (($spacing-small * 2) + $size-form-medium);
}

.section p {
  margin: 0; // reset main
}

.section img {
  display: block;
}


// section-block
.section-block {
  padding: $spacing-large $spacing-small;
}


// section-block-list
.section-block-list {
  margin: 0;
  padding: 0;
}

.section-block-list li {
  list-style-type: none;
}

.section-block-list li +  li {
  margin-top: $spacing-medium;
}

.section-block-list h3 {
  font-size: $font-size-larger;
  line-height: round($font-size-larger * $ratio-medium);
  margin-bottom: $spacing-small;
}

.section-block-list p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
}

.section-block-list span.line {
  display: block;
  width: 80%;
  height: 1px;
  background: $color-grey-light;
  margin-top: $spacing-medium;
}
