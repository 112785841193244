// styles.scss

// dependencies
// section-package
// section-package-wrapper
// section-package-header
// section-package-body
// section-package-tbody
// screen-large
// screen-large:section-package


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-package
.section-package {
  display: none;
  padding-top: $spacing-larger;
}


// section-package-wrapper
.section-package-wrapper {
  width: 850px;
}


// section-package-header
.section-package-header {
  text-align: center;
  margin-bottom: $spacing-larger;
}

.section-package-header h2 {
  font-family: $font-family-serif;
  font-size: ($font-size-medium * 2);
  font-weight: 900;
  line-height: round($font-size-medium * 2 * $ratio-smedium);
}


// section-package-body
.section-package-body > div + div {
  margin-top: $spacing-small;
}

.section-package-body ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.section-package-body li {
  list-style-type: none;
}

.section-package-body li:nth-of-type(1) {
  flex: 1;
}

.section-package-body li:nth-of-type(2),
.section-package-body li:nth-of-type(3) {
  width: 150px;
}

.section-package-body li:nth-of-type(2),
.section-package-body li:nth-of-type(3) {
  margin-left: $spacing-large;
}

.section-package-body li:nth-of-type(3) {
  margin-right: 20px; // non-standard
}


// section-package-thead
.section-package-thead li:nth-of-type(2),
.section-package-thead li:nth-of-type(3) {
  text-align: center;
  font-weight: 900;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
}


// section-package-tbody
.section-package-tbody ul {
  border-radius: $border-radius-medium;
  transition: background .3s ease-in-out;
}

.section-package-tbody ul:hover {
  background: rgba($color-grey, .2);
}

.section-package-tbody ul:hover li:nth-of-type(2) {
  background: #ede6e4; // non-standard
}

.section-package-tbody ul:hover li:nth-of-type(3) {
  background: #dfe2f2; // non-standard
}

.section-package-tbody li {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
  font-weight: 700;
  transition: background .3s ease-in-out;
}

.section-package-tbody li svg {
  display: block;
  height: $size-icon-medium;
  width: $size-icon-medium;
}

.section-package-tbody li svg * {
  fill: $color-primary;
}

.section-package-tbody li:nth-of-type(1) {
  padding: 20px 0 20px 20px; // non-standard
}

.section-package-tbody li:nth-of-type(2),
.section-package-tbody li:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-package-tbody li:nth-of-type(2) {
  background: #fbf2ee;
}

.section-package-tbody li:nth-of-type(3) {
  background: #e9edff;
}

.section-package-tbody ul:first-of-type li:nth-of-type(2),
.section-package-tbody ul:first-of-type li:nth-of-type(3) {
  border-radius: $border-radius-medium $border-radius-medium 0 0;
}

.section-package-tbody ul:last-of-type li:nth-of-type(2),
.section-package-tbody ul:last-of-type li:nth-of-type(3) {
  border-radius: 0 0 $border-radius-medium $border-radius-medium;
}


// screen-large
@media #{$screen-large} {

  // screen-large:section-package
  .section-package {
    display: block;
  }

}
