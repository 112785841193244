// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-footer
// task-footer:link-dismiss
// task-footer:link-details
// task-footer:link-cancel


// task-footer
.task-footer {
  color: $color-primary;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-smedium);
  margin-top: $spacing-small;
}

.task-footer * + * {
  margin-left: $spacing-smaller;
}


// task-footer:link-dismiss
.task-footer a.link-dismiss {
  color: $color-primary;
}

.task-footer a.link-dismiss:hover {
  color: lighten($color-accent, $percentage-hover);
}


// task-footer:link-details
.task-footer a.link-details {
  color: $color-accent;
  font-weight: 700;
}

.task-footer a.link-details:hover {
  color: lighten($color-accent, $percentage-hover);
}


// task-footer:link-cancel
.task-footer a.link-cancel {
  color: $color-primary;
}

.task-footer a.link-cancel:hover {
  color: $color-status-error;
}
