@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-branches-empty-block {
  height: calc(100% - 80px);
}

.patient-care-custom-table-container {
  background-color: $color-white;
  font-size: $font-size-small;
  overflow: auto hidden;
}

.patient-care-custom-table-holder {
  @media (max-width: $desktop-small) {
    min-width: $size-large + 280px;
  }
}

.patient-care-custom-table-holder .patient-care-custom-table-data {
  height: calc(100vh - 224px);
  overflow: auto;
  
  @media (min-width: $desktop-small) {
    height: calc(100vh - 207px);
  }
}

.is-data-changed
{
  height: calc(100vh - 301px) !important;
  overflow: auto !important;
}

.patient-care-custom-table-holder.is-data-changed .patient-care-custom-table-data {
  height: calc(100vh - 301px);
}

.patient-care-custom-table-cell  .patient-care-editable-input {
  border: 1px solid transparent;
  display: block;
  font-size: $font-size-medium;
  padding: ($spacing-smaller + 1px) ($spacing-small - 1px);
}

.patient-care-custom-table-cell  .patient-care-editable-select {
  border: 1px solid transparent;
  display: block;
  font-size: $font-size-medium;
  padding: ($spacing-smaller + 1px) $spacing-small;
}

.patient-care-custom-table-header,
.patient-care-custom-table-data-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 $spacing-medium;
  position: relative;
}

.patient-care-custom-table-header:after,
.patient-care-custom-table-data-row:after {
  background-color: $color-grey-light;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.patient-care-custom-table-header {
  background-color: $color-grey-lighter;
  font-weight: 700;
}

.patient-care-custom-table-row {
  align-items: center;
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  padding: 0 $spacing-medium;
}

.patient-care-custom-table-data .patient-care-custom-table-row {
  min-height: ($size-header-mobile + 1px);
}

.patient-care-custom-table-cell {
  padding: ($spacing-small - 2px) $spacing-smaller;
}

.patient-care-custom-table-cell b {
  display: block;
  overflow: hidden;
  padding: ($spacing-smaller + 1px) 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-custom-table-heading-col,
.patient-care-custom-table-data-col {
  color: $color-grey-dark;
  overflow: hidden;
  padding: $spacing-smaller;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-checkbox-col-header label {
  display: block;
  height: $spacing-medium;
}