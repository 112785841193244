// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation-item


// header-navigation-item
.header-navigation-item {
  position: relative;
}

.header-navigation-item +
.header-navigation-item {
  margin-left: $spacing-medium;
}
