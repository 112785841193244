// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation-tooltip


// header-navigation-tooltip
.header-navigation-tooltip {
  background: $color-primary;
  border-radius: $border-radius-small;
  padding: $spacing-smaller $spacing-small;
  position: absolute;
  top: 52px; // non-standard
  transition: opacity .3s ease-in-out;
  opacity: 0;
  right: 0;
  visibility: hidden;
  width: 130px; // non-standard
  z-index: -1;
}

.header-navigation-tooltip p {
  color: $color-white;
  font-size: $font-size-smaller;
  font-weight: 700;
  line-height: round($font-size-smaller * $ratio-medium);
  margin: 0;
}

.header-navigation-button:hover ~ .header-navigation-tooltip {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}
