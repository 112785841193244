// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-header


// task-header
.task-header h2,
.task-header h3 {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-smedium);
}

.task-header h3 {
  font-weight: 700;
  margin-top: $spacing-smaller;
}
