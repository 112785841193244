// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// filter-organisation-calendars
// filter-organisation-calendars:input-search-filter
// filter-organisation-calendars:patient-care-select
// filter-organisation-calendars:patient-care-select:ui-kit-skeleton


// filter-organisation-calendars
.filter-organisation-calendars {
  display: flex;
}


// filter-organisation-calendars:input-search-filter
.filter-organisation-calendars .input-search-filter {
  flex: 1
}


// filter-organisation-calendars:patient-care-select
.filter-organisation-calendars .patient-care-select {
  margin-left: $spacing-small;
  width: 25%;
}


// filter-organisation-calendars:patient-care-select:ui-kit-skeleton
.filter-organisation-calendars .patient-care-select.ui-kit-skeleton {
  background-color: transparent;
  padding: 0
}
