// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:organisations-list:cell-main
// react-table:organisations-list:cell-small
// react-table:organisations-list:cell-medium


// react-table:organisations-list:cell-main
.react-table.organisations-list tr th.cell-main,
.react-table.organisations-list tr td.cell-main {
  width: 34%;
}


// react-table:organisations-list:cell-small
.react-table.organisations-list tr th.cell-small,
.react-table.organisations-list tr td.cell-small {
  width: 100px; // non-standard
}


// react-table:organisations-list:cell-medium
.react-table.organisations-list tr th.cell-medium,
.react-table.organisations-list tr td.cell-medium {
  width: 150px; // non-standard
}
