// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-body
// sidebar-body:ui-kit-tooltip
// sidebar-body:form-item-error
// sidebar-body:input-number
// sidebar-body:select-number
// sidebar-body:input-radio
// sidebar-body:input-checkbox
// sidebar-body:input-error


// sidebar-body
.sidebar-body {
  flex: 1;
  overflow-x: none;
  overflow-y: auto;
  padding: 0 $spacing-medium;
}

.sidebar-body input::-webkit-outer-spin-button,
.sidebar-body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidebar-body input[type=number] {
  -moz-appearance: textfield;
}


// sidebar-body:ui-kit-tooltip
.sidebar-body .ui-kit-tooltip {
  display: inline-block;
}

.sidebar-body .ui-kit-tooltip-dropdown {
  width: 210px; // non-standard
}


// sidebar-body:form-item-error
.sidebar-body .form-item-error .input-radio:not(.no-validate) input:not(:disabled):not(:checked) + span::before {
  border-color: $color-status-error;
}

.sidebar-body .form-item-error .input-checkbox:not(.no-validate) input:not(:disabled):not(:checked) + span::before {
  border-color: $color-status-error;
}


// sidebar-body:input-number
.sidebar-body .input-number {
  width: 100px; // non-standard
}


// sidebar-body:select-number
.sidebar-body .select-number {
  max-width: 250px; // non-standard
}


// sidebar-body:input-radio
.sidebar-body .input-radio input:disabled ~ span {
  opacity: .5;
  cursor: default;
}


// sidebar-body:input-checkbox
.sidebar-body .input-checkbox input:disabled ~ span {
  opacity: .5;
  cursor: default;
}


// sidebar-body:input-error
.sidebar-body .input-error {
  padding-left: 0;
  padding-right: 0;
}
