// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-chart
// skeleton-chart-grid
// skeleton-chart-grid-wrapper
// skeleton-chart-grid-wrapper:vertical
// skeleton-chart-grid-wrapper:horizontal


// skeleton-chart
.skeleton-chart {
  position: relative;
}

.skeleton-chart .reports-smartpharmacy-chart-loader {
  display: contents;
}


// skeleton-chart-grid
.skeleton-chart-grid {
  bottom: 32px; // non-standard
  border: 1px solid $color-grey-light;
  left: 40px;  // non-standard
  position: absolute;
  top: 8px; // non-standard
  right: 0;
}


// skeleton-chart-grid-wrapper
.skeleton-chart-grid-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
}


// skeleton-chart-grid-wrapper:vertical
.skeleton-chart-grid-wrapper.vertical {
  flex-direction: row;
}

.skeleton-chart-grid-wrapper.vertical > div {
  width: calc(100% / 3);
  position: relative;
}

.skeleton-chart-grid-wrapper.vertical > div + div {
  border-left: 1px solid $color-grey-light;
}

.skeleton-chart-grid-wrapper.vertical > div > div {
  position: absolute;
  bottom: -20px; // non-standard
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px; // non-standard
  max-width: 70%;
}


// skeleton-chart-grid-wrapper:horizontal
.skeleton-chart-grid-wrapper.horizontal {
  flex-direction: column;
}

.skeleton-chart-grid-wrapper.horizontal > div {
  height: 25%;
  position: relative;
}

.skeleton-chart-grid-wrapper.horizontal > div + div {
  border-top: 1px solid $color-grey-light;
}

.skeleton-chart-grid-wrapper.horizontal > div > div {
  width: 32px;
  position: absolute;
  top: -4px;
  left: -40px;
}

.skeleton-chart-grid-wrapper.horizontal > div:last-of-type > div:last-of-type {
  top: auto;
  bottom: -4px;
}
