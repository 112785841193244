// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-body-empty


// calendar-filters-body-empty
.calendar-filters-body-empty p + button {
  margin-top: $spacing-small;
}
