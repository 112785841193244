// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-sidebar-close


// reports-sidebar-close
.reports-sidebar-close {
  padding: $spacing-small $spacing-medium;
}
