// patient-care-sortable-item
// patient-care-sortable-item:move
// patient-care-sortable-item:index
// patient-care-sortable-item:value


// patient-care-sortable-item
.patient-care-sortable-item {
  padding: $spacing-smaller 0;
  display: flex;
  align-items: center;
  height: $size-form-medium + ($spacing-smaller * 2);
}


// patient-care-sortable-item:move
.patient-care-sortable-item .move {
  width: $size-icon-larger;
  height: $size-form-medium;
  position: relative;
  cursor: move;
  margin-right: $spacing-smaller;
}

.patient-care-sortable-item .move svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size-icon-medium;
  height: $size-icon-medium;
}

.patient-care-sortable-item .move svg * {
  fill: $color-grey-dark;
}


// patient-care-sortable-item:index
.patient-care-sortable-item .index {
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-smedium);
  margin-right: $spacing-smaller;
  min-width: 26px;
}


// patient-care-sortable-item:value
.patient-care-sortable-item .value {
  width: 390px;
  margin-right: $spacing-small;
}
