// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-sticky
// patient-care-feature-content-sticky:smallest


// patient-care-feature-content-sticky
.patient-care-feature-content-sticky {
  background: $color-grey-lighter;
  min-width: 1000px; // non-standard
}


// patient-care-feature-content-sticky:smallest
.patient-care-feature-content-sticky.smallest {
  min-width: 580px; // non-standard (800 - 220)
}
