// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-deliveryTrend-chart
// card-reports-deliveryTrend-chart-header
// card-reports-deliveryTrend-chart-content
// card-reports-deliveryTrend-chart-footer


// card-reports-deliveryTrend-chart
.card-reports-deliveryTrend-chart {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-medium;
}


// card-reports-deliveryTrend-chart-header
.card-reports-deliveryTrend-chart-header h3 {
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}


// card-reports-deliveryTrend-chart-content
.card-reports-deliveryTrend-chart-content {
  flex: 1;
  margin-top: $spacing-medium;
}


// card-reports-deliveryTrend-chart-footer
.card-reports-deliveryTrend-chart-footer {
  margin-top: $spacing-medium;
}

.card-reports-deliveryTrend-chart-footer span {
  white-space: nowrap; // fix for very long labels in Legend
}
