// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.react-table-calendar{
  border-collapse: collapse;
  width: 100%;
}

.react-table-calendar tbody tr td{
  text-align: center;
  padding: 15px;
}

.react-table-calendar-td{
  padding: 5px;
  background-color: $color-table-calendar;
  text-align: left !important;
  color: $color-calendar-date;
}

table th {
  border-bottom: 1px solid $color-calendar-list-border;
  padding: 8px;
}

.react-table-calendar-th-tr{
  font-size: 14px;
  color: $color-calendar-title;
}

.react-table-calendar-th {
  border-bottom: 1px solid $color-calendar-row-border;
  padding: 8px;
}

.table-calendar-div-h3{
  background-color: $color-bulkappointment-header;
  max-height: 200px;
  border: 1px solid $color-calendar-border;
  height: 40px;
  padding-left: 150px;
}

.table-calendar-div-style-h3{
  color: $color-calendar-list-border;
  margin-right: 10px;
  padding-top: 13px;
  font-size: 14px;
}

.table-calendar-div-p{
  margin-right: 10px;
  padding-left: 141px;
}

.table-calendar-td{
  padding-left: 10px;
  padding-bottom: 23px;
}

.table-calendar-list{
  width: 100%;
  padding-left: 5px;
}

.table-calendar-inner-div{
  margin-bottom: 20px;
  width: 800px;
}

.table-calendar-inner-div-td{
  height: 10px;
  width: 600px;
}

.edit-row{
  color: $color-calendar-edit;
}

.table-container{
  position: relative;
  height: 100vh;
  padding-bottom: 50px;
}

.table-container-popup{
  @extend .table-container;
  height: 51vh;
  overflow-y: scroll;
}

.sticky-bulk-appointment-div{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-appointment-overlay;
  height: 37vh;
}

.checkbox-calendar{
  top: -16px;
  position: relative;
}

.patient-care-block-p{
  margin: 10px 0 8px;
}

