// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-content-sticky


// reports-content-sticky
.reports-content-sticky {
  background: $color-white;
  border-bottom: 1px solid $color-grey-light;
  padding: $spacing-medium;
}
