

@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-appointment-reason {
  margin: $spacing-small 0;
}

.patient-care-block.appointment-reason-wrap {
  margin: $spacing-medium 0;
}
