// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters-footer


// calendar-filters-footer
.calendar-filters-footer {
  background: $color-white;
  display: flex;
  padding: $spacing-small;
}
