@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-logo-full svg {
  width: $size-small - 90px;
}

.patient-care-login-page {
  align-items: center;
  background-color: $color-grey-lighter;
  border-top: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  font-size: $font-size-small;
  justify-content: center;
  min-height: calc(100vh - 80px);
  overflow: auto;
  padding: $spacing-large 0;
}

.patient-care-login-holder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.patient-care-login-wrap {
  background-color: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  font-size: $font-size-medium;
  margin: $spacing-large 0;
  padding: $spacing-larger ($spacing-larger + $spacing-small);
  width: ($size-medium + $spacing-large * 2 + $spacing-smaller / 2);
}

.patient-care-login-wrap .ui-kit-form {
  margin-top: $spacing-medium;
}

.patient-care-login-heading {
  margin-bottom: $spacing-large;
  text-align: center;
}

.patient-care-login-content .ui-kit-form {
  margin-top: $spacing-large;
}

.patient-care-login-content p {
  margin-bottom: $spacing-medium;
}

.patient-care-login-content p:last-child {
  margin-bottom: 0;
}

.patient-care-login-description {
  margin: $spacing-large 0;
}

.patient-care-form-group {
  margin-bottom: $spacing-medium;
}

.patient-care-btn-group {
  margin-top: $spacing-large;
}

.patient-care-login-footer {
  color: $color-grey-dark;
  font-size: $font-size-small;
  margin-top: $spacing-medium;
}

.patient-care-login-footer p {
  margin-bottom: $spacing-medium;
}

.patient-care-login-footer p:last-child {
  margin-bottom: 0;
}

.patient-care-footer-list-item {
  color: $color-primary;
  display: inline-block;
  font-weight: 700;
  padding-right: $spacing-medium;
  position: relative;
  vertical-align: middle;
}

.patient-care-footer-list-item:last-child {
  padding-right: 0;
}

.patient-care-footer-list-item:first-child:before {
  display: none;
}

.patient-care-footer-list-item:before {
  background-color: $color-grey-dark;
  border-radius: 50%;
  content: "";
  height: $spacing-smaller / 4;
  left: -($spacing-smaller + $spacing-smaller / 2);
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  width: $spacing-smaller / 4;
}

.hidden-iframe {
  display: none;
}

.patient-care-aadlogin-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
