// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// reports-basic-content
// reports-basic-card
// reports-basic-headline
// reports-basic-title
// reports-basic-row
// reports-basic-column
// reports-basic-box
// reports-basic-box-graph
// reports-basic-positive
// reports-basic-negative
// reports-basic-item
// reports-basic-item-secondary
// screen-desktop-small
// screen-desktop-small:reports-basic-row
// screen-desktop-small:reports-basic-column
// screen-desktop-medium
// screen-desktop-medium:reports-basic-column


// reports-basic-content
.reports-basic-content {
  padding: $spacing-medium;
  color: $color-primary;
  flex: 1;
  overflow-y: auto;
}


// reports-basic-card
.reports-basic-card {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  padding: $spacing-medium;
}

.reports-basic-card +
.reports-basic-card {
  margin-top: $spacing-large;
}


// reports-basic-headline
.reports-basic-headline h2 {
  font-weight: 700;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
}


// reports-basic-title
.reports-basic-title {
  margin-bottom: $spacing-small;
}

.reports-basic-title h3 {
  font-weight: 700;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
}


// reports-basic-row
.reports-basic-row {
  margin-top: $spacing-medium;
}


// reports-basic-column
.reports-basic-column > div + div {
  margin-top: $spacing-medium;
}

.reports-basic-column:nth-of-type(2) {
  margin-top: $spacing-medium;
}


// reports-basic-box
.reports-basic-box {
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  // padding: $spacing-medium;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


// reports-basic-box-graph
.reports-basic-box-graph {
  padding: $spacing-medium;
}


// reports-basic-positive
.reports-basic-positive {
  background: $color-accent;
  border-color: $color-accent;
}

.reports-basic-positive h3,
.reports-basic-positive p {
  color: $color-white;
}


// reports-basic-negative
.reports-basic-negative {
  background: $color-status-error;
  border-color: $color-status-error;
}

.reports-basic-negative h3,
.reports-basic-negative p {
  color: $color-white;
}


// reports-basic-item
.reports-basic-item {
  padding: $spacing-medium;
}

.reports-basic-item h3 {
  font-size: $font-size-medium;
  font-weight: 400;
  line-height: round($font-size-medium * $ratio-small);
}

.reports-basic-item p {
  font-weight: 700;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
  margin-top: $spacing-smaller;
}

.reports-basic-item p strong {
  display: block;
  font-size: $font-size-larger;
  line-height: round($font-size-larger * $ratio-small);
  font-weight: 900;
}

.reports-basic-item ul {
  display: flex;
  margin-top: $spacing-small;
}

.reports-basic-item li {
  width: calc(50% - (#{$spacing-medium} / 2));
}

.reports-basic-item li + li {
  margin-left: $spacing-medium;
}

.reports-basic-item li h3,
.reports-basic-item li p {
  color: $color-grey-dark;
}

.reports-basic-item li h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reports-basic-item li p {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
}

.reports-basic-item +
.reports-basic-item {
  border-top: 1px solid $color-grey-light;
}


// reports-basic-item-secondary
.reports-basic-item-secondary {
  background: $color-grey-lighter;
}


// screen-desktop-small
@media #{$screen-desktop-small} {

  // screen-desktop-small:reports-basic-row
  .reports-basic-row {
    display: flex;
  }


  // screen-desktop-small:reports-basic-column
  .reports-basic-column:nth-of-type(1) {
    flex-shrink: 0;
    width: 290px; // no-standard
  }

  .reports-basic-column:nth-of-type(2) {
    flex: 1;
    margin-left: $spacing-medium;
    margin-top: 0;
  }

  .reports-basic-column:nth-of-type(2) > div {
    height: 100%;
  }

}


// screen-desktop-medium
@media #{$screen-desktop-medium} {

  // screen-desktop-medium:reports-basic-column
  .reports-basic-column:nth-of-type(1) {
    width: 386px; // no-standard
  }

}

