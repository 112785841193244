// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-wrapper:medium


// patient-care-feature-content-wrapper:medium
.patient-care-feature-content-wrapper.medium {
  margin: 0 auto;
  width: $size-large;
}
