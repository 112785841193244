// patient-care-register-hero
// patient-care-register-hero-wrapper
// patient-care-register-hero-content
// patient-care-register-hero-image
// screen-custom
// screen-custom:patient-care-register-hero-wrapper
// screen-custom:patient-care-register-hero-content
// screen-custom:patient-care-register-hero-image


// patient-care-register-hero
.patient-care-register-hero {
  padding-top: ($spacing-medium * 2);
}


// patient-care-register-hero-wrapper
.patient-care-register-hero-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 932px; // non-standard
  height: 380px;
  display: flex;
  align-items: center;
}


// patient-care-register-hero-content
.patient-care-register-hero-content {
  width: 480px; // non-standard
}

.patient-care-register-hero-content h1 {
  font-family: $font-family-serif;
  font-weight: 900;
  font-size: ($font-size-medium * 2);
  line-height: 40px; // non-standard
}

.patient-care-register-hero-content p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
  margin-top: $spacing-medium;
}

.patient-care-register-hero-content a {
  width: auto;
  margin-top: $spacing-larger;
}

.patient-care-register-hero-content a:hover {
  color: $color-white;
}


// patient-care-register-hero-image
.patient-care-register-hero-image {
  width: 400px; // non-standard
  position: absolute;
  top: 0;
  left: 532px;
}

.patient-care-register-hero-image img {
  width: 100%;
  height: auto;
}


// screen-custom
@media (max-width: $desktop-xsmall) {

  // screen-custom:patient-care-register-hero-wrapper
  .patient-care-register-hero-wrapper {
    height: auto;
    display: block;
  }


  // screen-custom:patient-care-register-hero-content
  .patient-care-register-hero-content {
    width: auto;
  }


  // screen-custom:patient-care-register-hero-image
  .patient-care-register-hero-image {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto $spacing-large;
    width: 276px;
  }

}
