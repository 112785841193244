// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-branches-summary
// react-table:react-table-branches-summary:columns
// react-table:react-table-branches-summary:input-checkbox


// react-table:react-table-branches-summary
.react-table.react-table-branches-summary tr th.cell-checkbox,
.react-table.react-table-branches-summary tr td.cell-checkbox {
  width: 22px + ($spacing-medium + $spacing-smaller); // non-standard
}

.react-table.react-table-branches-summary tr th.cell-checkbox {
  padding-bottom: ($spacing-smaller / 2);
  padding-top: ($spacing-smaller / 2);
}

.react-table.react-table-branches-summary tr th.cell-alert,
.react-table.react-table-branches-summary tr td.cell-alert {
  width: 90px;
}

.react-table.react-table-branches-summary tr td.cell-alert .group svg {
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.react-table.react-table-branches-summary tr td.cell-alert .group.warning svg * {
  fill: $color-status-error;
}

.react-table.react-table-branches-summary tr td.cell-alert .group.caution svg * {
  fill: #f29c26;
}

.react-table.react-table-branches-summary tr th.cell-main,
.react-table.react-table-branches-summary tr td.cell-main {
  width: 34%;
}

.react-table.react-table-branches-summary tr th.cell-default,
.react-table.react-table-branches-summary tr td.cell-default {
  width: auto;
}


// react-table:react-table-branches-summary:columns
.react-table.react-table-branches-summary[columns='1'] tr th.cell-main,
.react-table.react-table-branches-summary[columns='1'] tr td.cell-main,
.react-table.react-table-branches-summary[columns='2'] tr th.cell-main,
.react-table.react-table-branches-summary[columns='2'] tr td.cell-main,
.react-table.react-table-branches-summary[columns='3'] tr th.cell-main,
.react-table.react-table-branches-summary[columns='3'] tr td.cell-main {
  width: auto;
}


// react-table:react-table-branches-summary:input-checkbox
.react-table.react-table-branches-summary .input-checkbox {
  height: 22px; // non-standard
  width: 22px; // non-standard
  overflow: hidden;
}

.react-table.react-table-branches-summary .input-checkbox label {
  line-height: 22px; // non-standard
}

.react-table.react-table-branches-summary .input-checkbox span:before {
  top: 0;
}
