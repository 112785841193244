@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.logo-wrap {
    border: 1px solid $color-grey-light;
    width: 145px;
    height: 128px;
    background: white;
    border-radius: $spacing-medium/2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
}

.no-image-message {
    width: 100%;
    text-align: center;
    font-size: $font-size-small;
    padding: 0 $spacing-medium;
}

.logo-wrap.no-image:hover {
    border: 1px solid $color-accent;
}

.logo-wrap .company-logo {
    width: 100%;
    border-radius: $spacing-smaller + 2;
    height: 100px;
}

.company-logo-upload[type="file"] {
    display: none;
}

.label-image-upload {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: $spacing-medium/2;
}

img.image-upload {
    width: 30px;
    height: 30px;
    display: block;
    flex: 1;
    top: 0;
    
}

.logo-wrap .company-logo-delete {
    margin-left: $spacing-medium + 2;
    display: flex;
    align-items: center;
}

.logo-wrap .company-logo-delete .ui-kit-icon > svg {
    flex: 1;
    cursor: pointer;
    fill: $color-grey-dark;

}

.logo-wrap .company-logo-delete .ui-kit-icon > svg:hover {
    fill: $color-status-error;
}

.logo-click-span {
    padding-left: 12px;
    margin-top: -57px;
}

.logo-upload-error {
    color: $color-error;
    font-size: 14px;
    line-height: 21px;
}

.company-logo-p-style{
    font-weight: 700;
    color: $color-bulkappointment-header;
}