// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// calendar-filters
// calendar-filters-hidden


// calendar-filters
.calendar-filters {
  background: $color-white;
  border-right: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$size-header-desktop});
  transition: margin-left .3s;
  width: 220px; // non-standard
}

.calendar-filters p {
  margin: 0; // reset global p
}


// calendar-filters-hidden
.calendar-filters-hidden {
  margin-left: -220px; // non-standard
}
