@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// search calendar styles
.patient-care-calendar-search {
  align-items: center;
  display: flex;
  margin-right: $spacing-smaller;
  width: 60%;

  @media (min-width: $desktop-small) {
    margin-right: $spacing-medium / 2;
  }
}

.patient-care-calendar-search .ui-kit-form {
  max-width: $size-large - 84px;
  width: 100%;
}

// new appointment styles
.patient-care-add-appointment {
  display: flex;
  justify-content: flex-end;
  margin-left: $spacing-smaller;
  width: 40%;

  @media (min-width: $desktop-small) {
    margin-left: $spacing-medium / 2;
  }
}

// search filters styles
.patient-care-search-filter {
  margin-left: $spacing-smaller;
  width: 40%;

  @media (min-width: $desktop-small) {
    margin-left: $spacing-medium / 2;
  }
}

.patient-care-search-filter.active-results {
  @media (max-width: $desktop-small) {
    width: 45%;
  }
}

.patient-care-search-filter .patient-care-select {
  width: 100%;

  @media (min-width: $desktop-small) {
    width: $spacing-large * 6;
  }
}

.patient-care-add-appointment .patient-care-select-primary {
  max-width: $spacing-larger * 5 + 5px;
}

//styles for available slots
.patient-care-filter-slots-title {
  align-items: center;
  display: flex;
  padding-right: $spacing-smaller;
  white-space: nowrap;

  @media (min-width: $desktop-small) {
    padding-right: $spacing-small;
  }
}

.patient-care-filter-slots-title h2 {

  @media (max-width: $desktop-small) {
    font-size: $font-size-medium;
  }
}

.patient-care-slots-filters {
  display: flex;
  width: 100%;
}

.patient-care-slots-filters .patient-care-select {
  max-width: $spacing-large * 6 + 15px;
  margin-right: $spacing-smaller;
  width: 100%;

  @media (min-width: $desktop-small) {
    margin-right: $spacing-small;
  }
}

.patient-care-slots-filters .patient-care-select:last-child {
  margin-right: 0;
}

// styles with active results sidebar
.patient-care-calendar-search.active-results {
  @media (max-width: $desktop-small) {
    width: 55%;
  }
  
  @media (min-width: $desktop-small) {
    width: $size-large - 60px;
  }
}
.patient-care-custom-appointment .ui-kit-form .input-label-small,
.patient-care-custom-appointment .ui-kit-form .input-label-small label {
  line-height: $spacing-small;
}

.pending-appointments-alert {
  background-color: $color-pending-appointment-alert-bg;
  padding: $size-pend-app-padding;
  border-radius: $size-pend-app-padding;
  margin: $size-icon-custom;
}

.alert-close {
  float: right;
}

.alert-content {
  display: flex;
  align-items: center;
  padding: $size-pend-app-padding;
}

.alert-icon {
  width: $size-pend-app-width;
  text-align: center;
  padding-right: $size-icon-custom;
}

.update-status-label {
  color: $color-update-status-link;
  cursor: pointer;
}