// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation-account


// header-navigation-account
.header-navigation-account {
  background: $color-white;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  border-radius: $border-radius-small;
  position: absolute;
  top: 52px; // non-standard
  right: 0;
  width: 400px; // non-standard
  z-index: 3;
}
