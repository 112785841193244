// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// recharts-medication
// recharts-medication:ui-kit-loader


// recharts-medication
.recharts-medication .recharts-xAxis,
.recharts-medication .recharts-yAxis,
.recharts-medication .recharts-label-list {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
}

.recharts-medication .recharts-xAxis text,
.recharts-medication .recharts-yAxis text,
.recharts-medication .recharts-label-list text {
  fill: $color-grey-dark !important;
}


// recharts-medication:ui-kit-loader
.recharts-medication .ui-kit-loader {
  height: 100%;
}
