// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// button-add


// button-add
.button-add {
  cursor: pointer;
  display: block;
  height: $size-icon-larger;
  position: relative;
  width: $size-icon-larger;
}

.button-add svg {
  height: $size-icon-custom;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size-icon-custom;
}

.button-add svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}

.button-add:hover svg * {
  fill: $color-accent;
}
