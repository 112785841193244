// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-featured-services
.patient-care-featured-services {
  margin-top: -($spacing-smaller);
  padding: $spacing-medium 0;
}

.patient-care-featured-services a,
.patient-care-featured-services p {
  font-weight: 700;
}

.patient-care-featured-services p {
  margin: 0;
  color: $color-primary;
  font-weight: 700;
}

.patient-care-featured-services p.info {
  color: $color-grey-dark;
}

.patient-care-featured-services p + p {
  margin-top: $spacing-medium;
}
