@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-full-height-page {
  height: calc(100vh - 80px);
  overflow: auto;
  padding: $spacing-large 0;
  text-align: center;
}

.patient-care-full-height-body {
  display: table;
  font-size: $font-size-large;
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.patient-care-full-height-container {
  display: table-cell;
  vertical-align: middle;
}

.patient-care-full-height-text {
  padding-bottom: $spacing-large;
}

.patient-care-full-height-page h1 {
  line-height: $ratio-medium;
  margin-bottom: $spacing-medium;
}

.patient-care-option-item {
  display: inline-block;
  padding: 0 $spacing-medium;
  vertical-align: top;
}

.patient-care-option-image {
  background-size: contain;
  display: inline-block;
  height: $spacing-larger + $spacing-smaller;
  margin-bottom: $spacing-small;
  width: $spacing-larger + $spacing-smaller;
}

.patient-care-option-image.chrome {
  background-image: url("./../../../assets/images/Chrome.png");

  @media only screen and (-moz-min-device-pixel-ratio: 1.5), 
  only screen and (-o-min-device-pixel-ratio: 3/2), 
  only screen and (-webkit-min-device-pixel-ratio: 1.5), 
  only screen and (min-devicepixel-ratio: 1.5), 
  only screen and (min-resolution: 1.5dppx) {
    background-image: url("./../../../assets/images/Chrome@2x.png");
  }
}

.patient-care-option-image.firefox {
  background-image: url("./../../../assets/images/Firefox.png");

  @media only screen and (-moz-min-device-pixel-ratio: 1.5), 
  only screen and (-o-min-device-pixel-ratio: 3/2), 
  only screen and (-webkit-min-device-pixel-ratio: 1.5), 
  only screen and (min-devicepixel-ratio: 1.5), 
  only screen and (min-resolution: 1.5dppx) {
    background-image: url("./../../../assets/images/Firefox@2x.png");
  }
}

.patient-care-option-item .ui-kit-link-external {
  color: $color-accent;
  display: block;
}

.patient-care-option-item .ui-kit-link-external svg * {
  fill: $color-accent;
}