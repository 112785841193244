@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

//reset CSS
html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-text-rendering: optimizelegibility;
  -webkit-text-rendering: optimizelegibility;
  text-rendering: optimizelegibility;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
  color: $color-primary;
  font-family: $font-family-sans;
  font-size: $font-size-medium;
  line-height: $ratio-medium;
  margin: 0;
  min-width: $desktop-xsmall;
  padding: 0;

  date-input-polyfill {
    z-index: 9999;
  }

}

button {
  font-family: $font-family-sans;
}

// reset styles for form inputs
input {
  vertical-align: middle;
}

form ::-webkit-inner-spin-button,
form ::-webkit-outer-spin-button {
  display: none;
}
