// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-button-remove


// patient-care-button-remove
.patient-care-button-remove {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.patient-care-button-remove i,
.patient-care-button-remove svg {
  display: block;
  width: 22px; // non-standard
  height: 22px; // non-standard
}

.patient-care-button-remove i {
  position: relative;
}

.patient-care-button-remove svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity .3s ease-in-out;
}

.patient-care-button-remove svg:nth-of-type(1) * {
  fill: $color-grey-dark;
}

.patient-care-button-remove svg:nth-of-type(2) * {
  fill: $color-status-error;
}

.patient-care-button-remove svg:nth-of-type(1) {
  opacity: 1;
}

.patient-care-button-remove svg:nth-of-type(2) {
  opacity: 0;
}

.patient-care-button-remove:hover svg:nth-of-type(1) {
  opacity: 0;
}

.patient-care-button-remove:hover svg:nth-of-type(2) {
  opacity: 1;
}
