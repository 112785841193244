// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-body-content:row-list
// sidebar-body-content:row-inline


// sidebar-body-content:row-list
.sidebar-body-content .row-list > div + div {
  margin-top: $spacing-small;
}


// sidebar-body-content:row-inline
.sidebar-body-content .row-inline {
  align-items: center;
  display: flex;
}

.sidebar-body-content .row-inline > .input-search {
  flex: 1;
}

.sidebar-body-content .row-inline > .input-before + div,
.sidebar-body-content .row-inline > div + .input-after {
  margin-left: $spacing-smaller;
}

.sidebar-body-content .row-inline > .input-search + .input-checkbox,
.sidebar-body-content .row-inline > .input-number + .input-checkbox,
.sidebar-body-content .row-inline > .input-text + .input-checkbox {
  margin-left: $spacing-small;
}

