// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// calendar-details-loading
.calendar-details-loading {
  background: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; // z-index is messed up
  display: flex;
  align-items: center;
}
