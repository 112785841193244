@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-radio-group
// patient-care-radio-icon

// patient-care-radio-group
.patient-care-radio-group form .form-item {
  display: flex;
}

.patient-care-radio-group .ui-kit-tooltip {
  flex: 1;
}

.patient-care-radio-group .ui-kit-tooltip + .ui-kit-tooltip {
  margin-left: $spacing-medium;
}

.patient-care-radio-group .ui-kit-tooltip-dropdown {
  width: 80%;
  padding: 8px;
}

.patient-care-radio-group .ui-kit-tooltip-dropdown p {
  margin-bottom: 0;
}

// patient-care-radio-icon
.patient-care-radio-icon label {
  padding-left: (($spacing-small + 22px) + $spacing-medium + $spacing-small) !important;
}

.patient-care-radio-icon .radio-disabled {
  color: $color-grey;
}

.patient-care-radio-icon {
  margin-top: 0 !important;
}

.patient-care-radio-icon i {
  position: absolute;
  top: 50%;
  left: $spacing-medium + $spacing-smaller + 20px;
  transform: translate(0, -50%);
  pointer-events: none;
  margin-top: -2px;
}

.patient-care-radio-icon i svg * {
  fill: $color-grey-dark;
}

.patient-care-radio-icon .radio-disabled i svg * {
  fill: $color-grey;
}
