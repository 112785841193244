// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-pharmacy
// card-reports-pharmacy-item


// card-reports-pharmacy
.card-reports-pharmacy {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  margin-top: $spacing-medium;
}

.card-reports-pharmacy h3 {
  font-size: $font-size-medium;
  font-weight: 400;
  line-height: round($font-size-medium * $ratio-smedium);
}

.card-reports-pharmacy p {
  font-size: $font-size-large;
  font-weight: 700;
  line-height: round($font-size-large * $ratio-small);
  margin-top: $spacing-smaller;
}


// card-reports-pharmacy-item
.card-reports-pharmacy-item {
  padding: $spacing-medium;
}

.card-reports-pharmacy-item +
.card-reports-pharmacy-item {
  border-top: 1px solid $color-grey-light;
}
