// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-main


// reports-main
.reports-main {
  background: $color-grey-lighter;
  display: flex;
  height: calc(100vh - #{$size-header-desktop});
}

.reports-pending-list-main {
  background: $color-grey-lighter;
  height: calc(100vh - #{$size-header-desktop});
  background: $color-white;
  overflow: scroll;
}

.reports-main p {
  margin: 0;
}
