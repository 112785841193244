// styles.scss

// dependencies
// footer
// footer-info
// footer-info-links
// footer-info-logos
// footer-copyright
// footer-sticky
// footer-sticky-scroll
// screen-medium
// screen-medium:footer
// screen-medium:footer-info
// screen-medium:footer-info-links
// screen-medium:footer-info-logos
// screen-medium:footer-sticky


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// footer
.footer {
  background: $color-primary;
  color: $color-white;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
  padding: $spacing-larger $spacing-small;
  text-align: center;
}

.footer a,
.footer a:hover {
  color: $color-white;
  font-weight: 700;
  text-decoration: underline;
}


// footer-info
// footer-info-links
.footer-info-links {
  flex: 1;
  width: auto;
}

.footer-info-links a {
  display: block;
}

.footer-info-links a + a {
  margin-top: $spacing-small;
}


// footer-info-logos
.footer-info-logos {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: $spacing-large;
}

.footer-info-logos svg {
  height: 36px;
  width: auto;
}

.footer-info-logos svg .logo-nhs-background {
  fill: $color-white;
}

.footer-info-logos svg .logo-nhs-logo {
  fill: $color-primary;
}


// footer-copyright
.footer-copyright {
  margin-top: $spacing-large;
}


// footer-sticky
.footer-sticky {
  box-shadow: $box-shadow-top;
  padding: 18px $spacing-small;
  position: fixed;
  bottom: -85px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: $color-white;
  transition: bottom .3s ease-in-out;
}

.footer-sticky p {
  margin: 0;
  font-weight: 900;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-small);
  display: none;
}

.footer-sticky a.ui-kit-button-secondary:hover {
  color: $color-white;
}

.footer-sticky .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


// footer-sticky-scroll
.footer-sticky-scroll {
  bottom: 0;
}

.footer-sticky-scroll + .footer {
  margin-bottom: $size-header-desktop;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:footer
  .footer {
    text-align: left;
  }


  // screen-medium:footer-info
  .footer-info {
    display: flex;
    flex-wrap: wrap;
  }


  // screen-medium:footer-info-links
  .footer-info-links a {
    display: inline-block;
  }

  .footer-info-links a + a {
    margin-left: $spacing-medium;
    margin-top: 0;
  }


  // screen-medium:footer-info-logos
  .footer-info-logos {
    margin-top: 0;
  }


  // screen-medium:footer-sticky
  .footer-sticky p {
    display: block;
  }

  .footer-sticky p + a {
    margin-left: $spacing-medium;
  }

}
