// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-table
// skeleton-table-row
// skeleton-table-row-head
// skeleton-table-row-head-sticky
// skeleton-table-row-head-grey
// skeleton-table-row-body
// skeleton-table:spacing-medium


// skeleton-table
// skeleton-table-row
.skeleton-table-row {
  align-items: center;
  display: flex;
}

.skeleton-table-row > div {
  flex: 1;
}

.skeleton-table-row > div:first-of-type {
  min-width: 40%;
}


// skeleton-table-row-head
.skeleton-table-row-head {
  background: $color-white;
  border-bottom: 1px solid $color-grey-light;
  height: round($font-size-medium * $ratio-small) + ($spacing-smaller * 2) + 1px; // LINE_HEIGHT + PADDINGS + BORDERS
}


// skeleton-table-row-head-sticky
.skeleton-table-row-head-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}


// skeleton-table-row-head-grey
.skeleton-table-row-head-grey {
  background: $color-grey-lighter;
}


// skeleton-table-row-body
.skeleton-table-row-body {
  border-bottom: 1px solid $color-grey-light;
  height: 63px; // non-standard
}


// skeleton-table:spacing-medium
.skeleton-table.spacing-medium > div {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}
