// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation-support


// header-navigation-support
.header-navigation-support {
  background: $color-white;
  box-shadow: $box-shadow-normal;
  box-sizing: border-box;
  border-radius: $border-radius-small;
  padding: $spacing-medium;
  position: absolute;
  top: 52px; // non-standard
  left: 50%;
  transform: translate(-50%, 0);
  width: 220px; // non-standard
  z-index: 3;
}

.header-navigation-support ul,
.header-navigation-support li {
  margin: 0;
  padding: 0;
}

.header-navigation-support li {
  list-style-type: none;
}

.header-navigation-support li + li {
  margin-top: $spacing-medium;
}

.header-navigation-support a {
  display: block;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: round($font-size-medium * $ratio-small);
}

.header-navigation-support a svg * {
  fill: $color-grey-dark;
  transition: fill .3s ease-in-out;
}

.header-navigation-support a span {
  color: $color-primary;
  margin-left: $spacing-smaller;
  margin-right: ($spacing-smaller / 2);
}

.header-navigation-support a:hover span {
  color: $color-accent;
  transition: color .3s ease-in-out;
}

.header-navigation-support a:hover svg * {
  fill: $color-accent;
}
