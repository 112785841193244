// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-scroll
// patient-care-feature-content-scroll:grey
// patient-care-feature-content-scroll:changed
// patient-care-feature-content-scroll:smallest


// patient-care-feature-content-scroll
.patient-care-feature-content-scroll {
  flex: 1;
  overflow-y: auto;
  min-width: 1000px; // non-standard
}


// patient-care-feature-content-scroll:grey
.patient-care-feature-content-scroll.grey {
  background: $color-grey-lighter;
}


// patient-care-feature-content-scroll:changed
.patient-care-feature-content-scroll.changed {
  margin-bottom: (($spacing-medium * 2) + $size-form-medium);
}


// patient-care-feature-content-scroll:smallest
.patient-care-feature-content-scroll.smallest {
  min-width: 580px; // non-standard (800 - 220)
}
