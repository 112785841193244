// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-classNameOne
// react-table:react-table-classNameOne:columns


// react-table:react-table-classNameOne
.react-table.react-table-classNameOne tr th.cell-main,
.react-table.react-table-classNameOne tr td.cell-main {
  width: 34%;
}

.react-table.react-table-classNameOne tr td.cell-main .tooltip {
  display: inline-block;
  margin-right: $spacing-smaller;
  height: $size-icon-small;
  width: $size-icon-large;
}

.react-table.react-table-classNameOne tr td.cell-main .tooltip .ui-kit-tooltip-dropdown {
  width: 230px; // non-standard
  white-space: normal;
}

.react-table.react-table-classNameOne tr td.cell-main .tooltip-error svg * {
  fill: $color-status-error;
}

.react-table.react-table-classNameOne tr th.cell-default,
.react-table.react-table-classNameOne tr td.cell-default {
  width: auto;
}


// react-table:react-table-classNameOne:columns
.react-table.react-table-classNameOne[columns='2'] tr th.cell-main,
.react-table.react-table-classNameOne[columns='2'] tr td.cell-main {
  width: auto;
}
