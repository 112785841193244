// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// header-navigation-button
// header-navigation-button-support
// header-navigation-button-account
// header-navigation-button-active
// header-navigation-button-warning


// header-navigation-button
.header-navigation-button,
.header-navigation-button:hover {
  color: $color-primary;
}

.header-navigation-button {
  color: $color-primary;
  cursor: pointer;
  display: block;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-smedium);
}

.header-navigation-button p {
  align-items: center;
  display: flex;
  margin: ($spacing-smaller / 2) 0 0; // non-standard
}

.header-navigation-button p > svg {
  height: $size-icon-smaller;
  margin-left: ($spacing-smaller / 2);
  margin-top: ($spacing-smaller / 2); // fix visual aliment
  width: $size-icon-smaller;
}

.header-navigation-button svg * {
  fill: $color-primary;
}

.header-navigation-button > i {
  margin: 0 auto;
}


// header-navigation-button-support
.header-navigation-button-support {}


// header-navigation-button-account
.header-navigation-button-account {}


// header-navigation-button-active
.header-navigation-button-active > i svg * {
  fill: $color-accent;
}


// header-navigation-button-warning
.header-navigation-button-warning > i svg * {
  fill: #f29c26;
}
