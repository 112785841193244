// patient-care-register-feature
// patient-care-register-feature-wrapper
// patient-care-register-feature-header
// patient-care-register-feature-body
// patient-care-register-feature-content
// patient-care-register-feature-image
// patient-care-register-feature-blob


// patient-care-register-feature
// patient-care-register-feature-wrapper
.patient-care-register-feature-wrapper {
  margin: 0 auto;
  max-width: 736px; // non-standard
}


// patient-care-register-feature-header
.patient-care-register-feature-header {
  margin-bottom: $spacing-large;
  text-align: center;
}

.patient-care-register-feature-header h2 {
  font-family: $font-family-serif;
  font-weight: 900;
  font-size: ($font-size-small * 2);
  line-height: round($font-size-small * 2 * $ratio-small);
}


// patient-care-register-feature-body
.patient-care-register-feature-body {
  position: relative;
  height: 596px; // non-standard
  display: flex;
  align-items: center;
}

.patient-care-register-feature-body.lside .patient-care-register-feature-content {
  margin-left: auto;
}

.patient-care-register-feature-body.lside .patient-care-register-feature-image {
  right: 430px;
}

.patient-care-register-feature-body.rside .patient-care-register-feature-content {
  margin-right: auto;
}

.patient-care-register-feature-body.rside .patient-care-register-feature-image {
  left: 430px;
}


// patient-care-register-feature-content
.patient-care-register-feature-content {
  width: 380px; // non-standard
}

.patient-care-register-feature-content ul,
.patient-care-register-feature-content li {
  margin: 0;
  padding: 0;
}

.patient-care-register-feature-content li {
  list-style-type: none;
}

.patient-care-register-feature-content li + li {
  margin-top: $spacing-medium;
}

.patient-care-register-feature-content h3 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-medium);
  margin-bottom: $spacing-small;
}

.patient-care-register-feature-content p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
}

.patient-care-register-feature-content span.line {
  display: block;
  width: 70%;
  height: 1px;
  background: $color-grey-light;
  margin-top: $spacing-medium;
}


// patient-care-register-feature-image
.patient-care-register-feature-image {
  width: 306px; // non-standard
  position: absolute;
  top: 0;
}

.patient-care-register-feature-image img {
  width: 100%;
  height: auto;
}


// patient-care-register-feature-blob
.patient-care-register-feature-blob {
  position: absolute;
  z-index: -1;
}
