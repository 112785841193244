// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-services-summary
// react-table:react-table-services-summary:columns


// react-table:react-table-services-summary
.react-table.react-table-services-summary tr th.cell-alert,
.react-table.react-table-services-summary tr td.cell-alert {
  width: 90px;
}

.react-table.react-table-services-summary tr td.cell-alert .group svg {
  height: $size-icon-larger;
  width: $size-icon-larger;
}

.react-table.react-table-services-summary tr td.cell-alert .group.warning svg * {
  fill: $color-status-error;
}

.react-table.react-table-services-summary tr td.cell-alert .group.caution svg * {
  fill: #f29c26;
}

.react-table.react-table-services-summary tr th.cell-main,
.react-table.react-table-services-summary tr td.cell-main {
  width: 25%;
}

.react-table.react-table-services-summary tr td.cell-main .tooltip {
  display: inline-block;
  margin-right: $spacing-smaller;
  height: $size-icon-small;
  width: $size-icon-large;
}

.react-table.react-table-services-summary tr td.cell-main .tooltip .ui-kit-tooltip-dropdown {
  width: 230px; // non-standard
  white-space: normal;
}

.react-table.react-table-services-summary tr td.cell-main .tooltip-error svg * {
  fill: $color-status-error;
}

.react-table.react-table-services-summary tr th.cell-default,
.react-table.react-table-services-summary tr td.cell-default {
  width: auto;
}


// react-table:react-table-services-summary:columns
.react-table.react-table-services-summary[columns='2'] tr th.cell-main,
.react-table.react-table-services-summary[columns='2'] tr td.cell-main {
  width: auto;
}
