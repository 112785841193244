// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// date-picker-wrapper


// date-picker-wrapper
.date-picker-wrapper {
  position: relative;
}

.date-picker-wrapper > i {
  position: absolute;
  top: 50%;
  right: $spacing-small;
  transform: translate(0, -50%);
  z-index: 1;
  pointer-events: none;
}

.date-picker-wrapper > i svg * {
  fill: $color-grey;
}

.date-picker-wrapper > div,
.date-picker-wrapper > div > div {
  display: block;
}

.date-picker-wrapper input {
  border: 0;
  color: $color-primary;
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 700;
  appearance: none;
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-small;
  box-sizing: border-box;
  color: $color-primary;
  font-family: inherit;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
  padding: 9px ($spacing-small - 1px);
  padding-right: $spacing-larger;
  transition: border .3s ease-in-out;
  width: 100%;
}
.date-picker-wrapper input::placeholder {
  color: $color-grey !important;
}

.date-picker-wrapper input:focus {
  border-color: $color-accent;
  outline: 0;
}
