// styles.scss

// dependencies
// section-more
// section-more-wrapper
// section-more-content
// section-more-image
// screen-medium
// screen-medium:section-more-wrapper
// screen-medium:section-more-content
// screen-medium:section-more-body
// screen-medium:section-more-image
// screen-large
// screen-large:section-more-content
// screen-large:section-more-image


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-more
// section-more-wrapper
.section-more-wrapper {
  background: $color-grey-lighter;
  position: relative;
}


// section-more-content
.section-more-content {
  box-sizing: border-box;
  padding: $spacing-medium;
}


// screen-medium:section-more-body
.section-more-body h2 {
  font-family: $font-family-serif;
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-medium);
}

.section-more-body p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
}

.section-more-body h2 + p {
  margin-top: $spacing-small;
}


// screen-medium:section-more-contact
.section-more-contact {
  margin-top: $spacing-medium;
}

.section-more-contact .form-row {
  display: flex;
}

.section-more-contact .form-row > div:nth-of-type(1) {
  width: calc(60% - (#{$spacing-smaller} / 2));
}

.section-more-contact .form-row > div:nth-of-type(2) {
  margin-left: $spacing-small;
  margin-top: 0;
  width: calc(40% - (#{$spacing-smaller} / 2));
}

.section-more-contact .form-contact {
  max-width: $size-medium;
}


// section-more-image
.section-more-image img {
  height: auto;
  width: 100%;
  display: block;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:section-more-wrapper
  .section-more-wrapper {
    align-items: center;
    display: flex;
    height: 691px;
  }


  // screen-medium:section-more-content
  .section-more-content {
    padding: $spacing-larger;
    width: 60%;
  }


  // screen-medium:section-more-body
  .section-more-body h2 {
    font-size: ($font-size-medium * 2);
    line-height: round($font-size-medium * 2 * $ratio-smedium);
  }


  // screen-medium:section-more-image
  .section-more-image {
    left: 60%;
    position: absolute;
    top: 0;
    width: 620px;
  }

}


// screen-large
@media #{$screen-large} {

  // screen-large:section-more-content
  .section-more-content {
    padding: ($spacing-large * 2);
    width: 50%;
  }


  // screen-large:section-more-image
  .section-more-image {
    left: 50%;
  }

}
