// react-table-settings.scss

// react-table-settings
// react-table-settings-button
// react-table-settings-background
// react-table-settings-menu
// react-table-settings-menu:override


// react-table-settings
.react-table-settings {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 5;
}


// react-table-settings-button
.react-table-settings-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: $spacing-smaller;
  height: 30px; // non-standard
  width: 30px; // non-standard
}

.react-table-settings-button i,
.react-table-settings-button svg {
  display: block;
  height: $size-icon-medium;
  width: $size-icon-medium;
}

.react-table-settings-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-table-settings-button svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}

.react-table-settings-button:hover svg * {
  fill: $color-accent;
}


// react-table-settings-background
.react-table-settings-background {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}


// react-table-settings-menu
.react-table-settings-menu {
  background: $color-white;
  box-shadow: $box-shadow-normal;
  border-radius: $border-radius-medium;
  padding: $spacing-medium;
  position: absolute;
  top: 0;
  right: ($spacing-smaller * 2) + 30px;
  width: 250px; // non-standard
}

.react-table-settings-menu h2 {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
  margin-bottom: $spacing-small;
}

.react-table-settings-menu ~ div svg * {
  fill: $color-accent;
}


// react-table-settings-menu:override
.react-table-settings-menu .ui-kit-form .input-checkbox + .input-checkbox {
  margin-top: $spacing-smaller;
}

// Uncomment this to make block labels
// .react-table-settings-menu .ui-kit-form .input-checkbox label {
//   cursor: pointer;
//   display: block;
// }
