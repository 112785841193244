// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// reports-basic
// reports-basic-skeleton
// reports-basic-tooltip
// reports-basic-legend


// reports-basic
.reports-basic {
  background: $color-grey-lighter;
  height: calc(100vh - (#{$size-header-desktop} + 1px));
  min-width: $desktop-xsmall;
  display: flex;
  flex-direction: column;
}

.reports-basic h2,
.reports-basic h3,
.reports-basic p,
.reports-basic ul,
.reports-basic li {
  margin: 0;
  padding: 0;
}

.reports-basic li {
  list-style-type: none;
}

.reports-basic .ui-kit-overlay {
  min-width: $desktop-xsmall;
}


// reports-basic-skeleton
.reports-basic-skeleton {
  background-color: transparent !important;
  margin-top: $spacing-smaller;
}

.reports-basic-skeleton.medium {
  padding: 5px 0 !important; // non-standard
}

.reports-basic-skeleton.large {
  padding: 8px 0 !important; // non-standard
}

.reports-basic-skeleton.small {
  padding: 4px 0 !important; // non-standard
}


// reports-basic-tooltip
.reports-basic-tooltip {
  background: $color-primary;
  border-radius: $border-radius-small;
  padding: $spacing-small;
}

.reports-basic-tooltip h4,
.reports-basic-tooltip ul {
  color: $color-white;
}

.reports-basic-tooltip h4 {
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
}

.reports-basic-tooltip h4 + ul {
  margin-top: $spacing-small;
}

.reports-basic-tooltip ul li {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  display: flex;
}

.reports-basic-tooltip ul li span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reports-basic-tooltip ul li span:nth-of-type(1) {
  flex: 1;
}

.reports-basic-tooltip ul li span:nth-of-type(2) {
  margin-left: $spacing-smaller;
}

.reports-basic-tooltip ul li + li {
  margin-top: $spacing-smaller;
}


// reports-basic-legend
.reports-basic-legend {
  margin-top: $spacing-medium;
}

.reports-basic-legend ul {
  display: flex;
  flex-wrap: wrap;
  margin: (-$spacing-smaller / 2) (-$spacing-medium / 2);
}

.reports-basic-legend li {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  display: flex;
  align-items: center;
  margin: ($spacing-smaller / 2) ($spacing-medium / 2);
}

.reports-basic-legend i {
  border-radius: $border-radius-large;
  flex-shrink: 0;
  width: $size-icon-medium;
  height: $size-icon-medium;
}

.reports-basic-legend span {
  margin-left: $spacing-smaller;
}

.reports-basic-legend .color-accent {
  background: $color-accent;
}

.reports-basic-legend .color-status-error {
  background: $color-status-error;
}

.reports-basic-legend .color-support-two {
  background: $color-support-two;
}

.reports-basic-legend .color-support-three {
  background: $color-support-three;
}

.reports-basic-legend .color-support-five {
  background: $color-support-five;
}

.reports-basic-legend .color-notifications-warning {
  background: $color-customer-not-attended;
}

.reports-basic-legend .color-status-success {
  background: $color-status-success;
}

.reports-basic-legend .color-grey-light {
  background: $color-grey-light;
}
