

@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-appointment-details-list {
  color: $color-primary;
  list-style: none;
  margin: 0;
  padding: 0 0 $spacing-small 0;
}

.patient-care-appointment-info-item {
  padding-bottom: $spacing-smaller;
}

.patient-care-appointment-info-item:last-child {
  padding-bottom: 0;
}

.patient-care-appointment-info-item svg * {
  fill: $color-primary;
}
