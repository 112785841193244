// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// button-settings


// button-settings
.button-settings {
  cursor: pointer;
  display: block;
  height: $size-icon-larger;
  position: relative;
  width: $size-icon-larger;
}

.button-settings svg {
  height: $size-icon-custom;
  width: $size-icon-custom;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-settings svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}

.button-settings:hover svg * {
  fill: $color-accent;
}
