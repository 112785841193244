// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-header


// sidebar-header
.sidebar-header {
  padding: $spacing-large $spacing-medium $spacing-medium;
  transition: box-shadow .3s ease-in-out;
  z-index: 3;
}

.sidebar-header h2 + p {
  margin-top: $spacing-smaller;
}

.sidebar-header h2 {
  font-size: $font-size-larger;
  font-weight: 900;
  line-height: round($font-size-larger * $ratio-small);
}

.sidebar-header p {
  align-items: center;
  display: flex;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-smedium);
  white-space: nowrap;
}

.sidebar-header p span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-header p i {
  margin-left: $spacing-smaller;
}

.sidebar-header p i,
.sidebar-header p i svg {
  display: block;
}

.sidebar-header p i svg {
  height: $size-icon-medium;
  width: auto;
}

.sidebar-header p a {
  font-size: $font-size-small;
  font-weight: 700;
  margin-left: $spacing-large;
}
