// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-content-filters
// reports-content-filters-select
// reports-content-filters-date
// reports-content-filters-buttons


// reports-content-filters
.reports-content-filters {
  align-items: center;
  display: flex;
  justify-content: space-between;
}


// reports-content-filters-select
.reports-content-filters-select {
  flex: 1;
  display: flex;
}

.reports-content-filters-select > div {
  flex: 1;
}

.reports-content-filters-select > div + div {
  margin-left: $spacing-small;
}


// reports-content-filters-date
.reports-content-filters-date {
  align-items: center;
  display: flex;
  margin-left: $spacing-small;
}

.reports-content-filters-date > div {
  width: 150px; // non-standard
}

.reports-content-filters-date > p {
  margin: 0 $spacing-small;
}


// reports-content-filters-buttons
.reports-content-filters-buttons {
  margin-left: $spacing-small;
}
