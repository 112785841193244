// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-nominations-chart-skeleton
// card-reports-nominations-chart-skeleton-row


// card-reports-nominations-chart-skeleton
.card-reports-nominations-chart-skeleton {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-medium;
}


// card-reports-nominations-chart-skeleton-row
.card-reports-nominations-chart-skeleton-row:nth-of-type(1) > div {
  margin-top: ($spacing-smaller / 2);
  margin-bottom: ($spacing-smaller / 2);
}

.card-reports-nominations-chart-skeleton-row:nth-of-type(2) {
  flex: 1;
  margin-top: $spacing-medium;
}

.card-reports-nominations-chart-skeleton-row:nth-of-type(3) {
  margin-top: $spacing-medium;
}

.card-reports-nominations-chart-skeleton-row:nth-of-type(3) > div {
  margin-top: 6px; // non-standard
  margin-bottom: 7px; // non-standard
}
