// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-classNameThree
// react-table:react-table-classNameThree:input-checkbox


// react-table:react-table-classNameThree
.react-table.react-table-classNameThree tr th,
.react-table.react-table-classNameThree tr td {
  padding-left: 0;
  padding-right: 0;
}

.react-table.react-table-classNameThree tr th.cell-checkbox,
.react-table.react-table-classNameThree tr td.cell-checkbox {
  width: 22px; // non-standard
}

.react-table.react-table-classNameThree tr th.cell-checkbox {
  padding-bottom: ($spacing-smaller / 2);
  padding-top: ($spacing-smaller / 2);
}

.react-table.react-table-classNameThree tr th.cell-checkbox ~ th.cell-main,
.react-table.react-table-classNameThree tr td.cell-checkbox ~ td.cell-main {
  padding-left: $spacing-smaller;
}


// react-table:react-table-classNameThree:input-checkbox
.react-table.react-table-classNameThree .input-checkbox {
  height: 22px; // non-standard
  width: 22px; // non-standard
  overflow: hidden;
}

.react-table.react-table-classNameThree .input-checkbox label {
  line-height: 22px; // non-standard
}

.react-table.react-table-classNameThree .input-checkbox span:before {
  top: 0;
}
