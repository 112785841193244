// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-info
// task-info:ui-kit-tooltip-dropdown


// task-info
.task-info {
  position: absolute;
  top: $spacing-small;
  right: $spacing-small;
}

.task-info + .task-header h2 {
  padding-right: ($spacing-smaller + 20px); // non-standard
}

.task-info i,
.task-info svg {
  display: block;
  height: 20px; // non-standard
  width: 20px; // non-standard
}

.task-info i:hover svg * {
  fill: $color-accent;
}

.task-info svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}


// task-info:ui-kit-tooltip-dropdown
.task-info .ui-kit-tooltip-dropdown {
  min-width: 180px; // non-standard
}
