// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// patient-care-norole-header-logo
.patient-care-norole-header-logo a {
  display: block;
  height: $spacing-larger;
}

.patient-care-norole-header-logo svg {
  height: auto;
  width: 210px;
}

// patient-care-norole-header-navigation
.patient-care-norole-header-navigation ul {
  display: inline-flex;
}

.patient-care-norole-header-navigation li {
  list-style-type: none;
}

.patient-care-norole-header-navigation li + li {
  margin-left: $spacing-medium;
}

.patient-care-norole-header-navigation a {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.patient-care-norole-header-navigation svg {
  height: $spacing-small;
  width: $spacing-small;
  display: block;
}

.patient-care-norole-header-navigation span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  color: #32335A;
}
