@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-block.remove-mfa-wrap {
    display: flex;
}

span.patient-care-filled-input.mfa-enabled {
    color: $color-status-success;
}

span.patient-care-filled-input.mfa-not-enabled {
    color: $color-status-error;
}