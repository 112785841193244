// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-manager-close


// task-manager-close
.task-manager-close {
  position: absolute;
  right: $spacing-small;
  top: $spacing-medium;
}
