// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-content-scroll


// reports-content-scroll
.reports-content-scroll {
  flex: 1;
  overflow-x: auto;
  padding: $spacing-medium;
}
