// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// styles for custom primary select
.patient-care-app .patient-care-select-dismiss {
    width: 100%;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__control {
    border-radius: $border-radius-large;
    border: 1;
    cursor: pointer;
    transition: background-color .3s;
    border-color: $color-accent;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__control:hover {
    border-color: $color-accent;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__dropdown-indicator svg {
    fill: $color-accent;
  }

  .patient-care-app .patient-care-select-dismiss .patient-care__value-container {
    padding: ($spacing-smaller - 3px) $spacing-larger;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__placeholder {
    color: $color-accent;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__indicators {
    left: -($spacing-larger - 10px);
    position: relative;
  }
  
  .patient-care-app .patient-care-select-dismiss .patient-care__indicator.patient-care__dropdown-indicator {
    color: $color-white;
  }

  .patient-care-app .patient-care-select-dismiss .patient-care__single-value {
    color: $color-accent;
    margin: 0;
    max-width: calc(100% - 80px);
  }
