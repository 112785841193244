// styles.scss

// dependencies
// section-connect
// section-connect-wrapper
// section-connect-blob
// section-connect-content
// section-connect-image
// screen-medium
// screen-medium:section-connect-content
// screen-medium:section-connect-image
// screen-custom
// screen-custom:section-connect-image


// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';


// section-connect
.section-connect {
  padding-bottom: ($spacing-medium * 2);
}


// section-connect-wrapper
.section-connect-wrapper {
  position: relative;
}


// section-connect-blob
.section-connect-blob {
  position: absolute;
  z-index: -1;
}

.section-connect-blob img {
  display: block;
  height: auto;
}


// section-connect-content
.section-connect-content {
  margin-top: $spacing-large;
}

.section-connect-content h1 {
  font-family: $font-family-serif;
  font-size: ($font-size-medium * 2);
  font-weight: 900;
  line-height: 40px; // non-standard
  margin-bottom: $spacing-medium;
}

.section-connect-content p {
  color: $color-grey-dark;
  font-size: $font-size-large;
  line-height: round($font-size-large * $ratio-medium);
}

.section-connect-content p + p {
  margin-top: $spacing-medium;
}

.section-connect-content a {
  margin-top: $spacing-large;
}

.section-connect-content a:hover {
  color: $color-white;
}


// section-connect-image
.section-connect-image img {
  height: auto;
  width: 100%;
  max-width: 276px; // non-standard
  margin: 0 auto;
}


// screen-medium
@media #{$screen-medium} {

  // screen-medium:section-connect-content
  .section-connect-content {
    margin-top: 0;
    max-width: $size-large;
  }

  .section-connect-content h1 {
    font-size: ($font-size-larger * 2);
    line-height: 60px; // non-standard
  }


  // screen-medium:section-connect-image
  .section-connect-image {
    width: 400px; // non-standard
    position: absolute;
    top: 50%;
    left: 648px; // non-standard
    transform: translate(0, -50%);
  }

  .section-connect-image img {
    margin: 0;
    max-width: 100%;
  }

}


// screen-custom
@media (min-width: 1080px) {

  // screen-custom:section-connect-image
  .section-connect-image {
    left: auto;
    right: 0;
  }

}
