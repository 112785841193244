// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// button-toggle


// button-toggle
.button-toggle {
  cursor: pointer;
  display: block;
  height: $size-icon-larger;
  position: relative;
  width: $size-icon-larger;
}

.button-toggle svg {
  height: $size-icon-medium;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size-icon-medium;
}

.button-toggle svg * {
  fill: $color-grey-dark;
  transition: fill .3s ease-in-out;
}
