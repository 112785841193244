@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.header-span-wrap {
    display: inline-block;
}

.select-all-checkbox span {
    color: $color-grey-dark;
    font-size: $font-size-small;
    font-weight: 700;
}

.patient-care-feature-content-table tbody td .default-check-col {
    padding: ($spacing-smaller + 2) $spacing-smaller;
}

.patient-care-feature-content-table .default-service-table-header td.extra {
    padding: 0 ($spacing-smaller - 1);
}

.patient-care-feature-content-table td.default-service-alert {
    text-align: center;
}

.patient-care-feature-content-table td.default-service-alert svg {
    fill: $color-status-error;
}