// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-sidebar
// reports-sidebar-expand
// reports-sidebar-contract


// reports-sidebar
.reports-sidebar {
  border-right: 1px solid $color-grey-light;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition: width .3s ease-in-out;
}


// reports-sidebar-expand
.reports-sidebar-expand {
  width: 220px; // non-standard
}

.reports-sidebar-expand ~ .reports-content {
  width: calc(100% - 220px); // non-standard
}

.reports-sidebar-expand .reports-sidebar-menu a span {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.reports-sidebar-expand .reports-sidebar-menu p {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}


// reports-sidebar-contract
.reports-sidebar-contract {
  width: 72px; // non-standard
}

.reports-sidebar-contract ~ .reports-content {
  width: calc(100% - 72px); // non-standard
}

.reports-sidebar-contract .reports-sidebar-menu a span {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.reports-sidebar-contract .reports-sidebar-menu p {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}
