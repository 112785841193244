// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-norole-content
.patient-care-norole-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$size-header-desktop});
  padding: 0 $spacing-small $spacing-small;
}

.patient-care-norole-content .norole-content-container {
  align-items: center;
  background: $color-grey-lighter;
  border-radius: $border-radius-medium;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-medium;
}

// patient-care-norole-content-wrapper
.patient-care-norole-content-wrapper {
  width: 468px;
}

// patient-care-norole-content-card
.patient-care-norole-content-card {
  background: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-normal;
  padding: $spacing-larger ($spacing-larger + $spacing-small);
}

.patient-care-norole-content-card h3 {
  font-weight: 700;
  line-height: $ratio-smedium;
}
  
.patient-care-norole-content-description {
  margin-top: $spacing-small;
}

.patient-care-norole-content-description p:last-of-type {
  margin-bottom: 0;
}

// patient-care-norole-content-navigation
.patient-care-norole-content-navigation {
  text-align: center;
  font-weight: bold;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-smedium);
  margin-top: $spacing-medium;
}
