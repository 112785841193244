@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-appointment-opener {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: $ratio-small;
  margin-top: $spacing-smaller;
}

.patient-care-appointment-opener svg * {
  fill: $color-primary;
}

.patient-care-appointment-opener i {
  position: relative;
  top: 1px;
}