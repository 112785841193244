// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// aside-visible


// aside-visible
.aside-visible {
  margin-right: $size-small;
}

.aside-visible .task-manager {
  transform: translateX(0);
}

.aside-visible .agenda-sidebar {
  transform: translateX(0);
}

.aside-visible .patient-care-main-holder {
  width: calc(100% - 220px); // FIX: for original layout
}

.aside-visible .patient-care-main-holder.full-width{
  width: calc(100% - 300px); ;
}