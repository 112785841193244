// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-calendar-filters-title


// skeleton-calendar-filters-title
.skeleton-calendar-filters-title {
  align-items: center;
  display: flex;
  height: $size-icon-larger;
}

.skeleton-calendar-filters-title i {
  animation: ui-kit-skeleton-animation 2s linear infinite;
  background: linear-gradient(90deg, rgba($color-black, 0) 0, rgba($color-black, 0) 30%, rgba($color-black, .04) 50%, rgba($color-black, 0) 70%, rgba($color-black, 0) 100%), $color-grey-lighter;
  background-size: 200% 200%;
  border-radius: $border-radius-small;
  flex-shrink: 0;
  height: $size-icon-large;
  margin-right: $spacing-smaller;
  width: $size-icon-large;
}
