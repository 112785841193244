// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// ui-kit:input-checkbox-avatar-1
// ui-kit:input-checkbox-avatar-2
// ui-kit:input-checkbox-avatar-3
// ui-kit:input-checkbox-avatar-4
// ui-kit:input-checkbox-avatar-5
// ui-kit:input-checkbox-avatar-6
// ui-kit:input-checkbox-avatar-7
// ui-kit:input-checkbox-avatar-8
// ui-kit:input-checkbox-avatar-9
// ui-kit:input-checkbox-avatar-10
// ui-kit:input-checkbox-avatar-11
// ui-kit:input-checkbox-avatar-12
// ui-kit:input-checkbox-avatar-13
// ui-kit:input-checkbox-avatar-14
// ui-kit:input-checkbox-avatar-15
// ui-kit:input-checkbox-avatar-16



// ui-kit:input-checkbox-avatar-1
.ui-kit-form .input-checkbox-avatar-1 input:checked + span::before {
  background-color: $color-avatar-bg-1;
  border-color: $color-avatar-bg-1;
}

.ui-kit-form .input-checkbox-avatar-1 input:focus + span::before {
  border-color: $color-avatar-bg-1;
}


// ui-kit:input-checkbox-avatar-2
.ui-kit-form .input-checkbox-avatar-2 input:checked + span::before {
  background-color: $color-avatar-bg-2;
  border-color: $color-avatar-bg-2;
}

.ui-kit-form .input-checkbox-avatar-2 input:focus + span::before {
  border-color: $color-avatar-bg-2;
}


// ui-kit:input-checkbox-avatar-3
.ui-kit-form .input-checkbox-avatar-3 input:checked + span::before {
  background-color: $color-avatar-bg-3;
  border-color: $color-avatar-bg-3;
}

.ui-kit-form .input-checkbox-avatar-3 input:focus + span::before {
  border-color: $color-avatar-bg-3;
}


// ui-kit:input-checkbox-avatar-4
.ui-kit-form .input-checkbox-avatar-4 input:checked + span::before {
  background-color: $color-avatar-bg-4;
  border-color: $color-avatar-bg-4;
}

.ui-kit-form .input-checkbox-avatar-4 input:focus + span::before {
  border-color: $color-avatar-bg-4;
}


// ui-kit:input-checkbox-avatar-5
.ui-kit-form .input-checkbox-avatar-5 input:checked + span::before {
  background-color: $color-avatar-bg-5;
  border-color: $color-avatar-bg-5;
}

.ui-kit-form .input-checkbox-avatar-5 input:focus + span::before {
  border-color: $color-avatar-bg-5;
}


// ui-kit:input-checkbox-avatar-6
.ui-kit-form .input-checkbox-avatar-6 input:checked + span::before {
  background-color: $color-avatar-bg-6;
  border-color: $color-avatar-bg-6;
}

.ui-kit-form .input-checkbox-avatar-6 input:focus + span::before {
  border-color: $color-avatar-bg-6;
}


// ui-kit:input-checkbox-avatar-7
.ui-kit-form .input-checkbox-avatar-7 input:checked + span::before {
  background-color: $color-avatar-bg-7;
  border-color: $color-avatar-bg-7;
}

.ui-kit-form .input-checkbox-avatar-7 input:focus + span::before {
  border-color: $color-avatar-bg-7;
}


// ui-kit:input-checkbox-avatar-8
.ui-kit-form .input-checkbox-avatar-8 input:checked + span::before {
  background-color: $color-avatar-bg-8;
  border-color: $color-avatar-bg-8;
}

.ui-kit-form .input-checkbox-avatar-8 input:focus + span::before {
  border-color: $color-avatar-bg-8;
}


// ui-kit:input-checkbox-avatar-9
.ui-kit-form .input-checkbox-avatar-9 input:checked + span::before {
  background-color: $color-avatar-bg-9;
  border-color: $color-avatar-bg-9;
}

.ui-kit-form .input-checkbox-avatar-9 input:focus + span::before {
  border-color: $color-avatar-bg-9;
}


// ui-kit:input-checkbox-avatar-10
.ui-kit-form .input-checkbox-avatar-10 input:checked + span::before {
  background-color: $color-avatar-bg-10;
  border-color: $color-avatar-bg-10;
}

.ui-kit-form .input-checkbox-avatar-10 input:focus + span::before {
  border-color: $color-avatar-bg-10;
}


// ui-kit:input-checkbox-avatar-11
.ui-kit-form .input-checkbox-avatar-11 input:checked + span::before {
  background-color: $color-avatar-bg-11;
  border-color: $color-avatar-bg-11;
}

.ui-kit-form .input-checkbox-avatar-11 input:focus + span::before {
  border-color: $color-avatar-bg-11;
}


// ui-kit:input-checkbox-avatar-12
.ui-kit-form .input-checkbox-avatar-12 input:checked + span::before {
  background-color: $color-avatar-bg-12;
  border-color: $color-avatar-bg-12;
}

.ui-kit-form .input-checkbox-avatar-12 input:focus + span::before {
  border-color: $color-avatar-bg-12;
}


// ui-kit:input-checkbox-avatar-13
.ui-kit-form .input-checkbox-avatar-13 input:checked + span::before {
  background-color: $color-avatar-bg-13;
  border-color: $color-avatar-bg-13;
}

.ui-kit-form .input-checkbox-avatar-13 input:focus + span::before {
  border-color: $color-avatar-bg-13;
}


// ui-kit:input-checkbox-avatar-14
.ui-kit-form .input-checkbox-avatar-14 input:checked + span::before {
  background-color: $color-avatar-bg-14;
  border-color: $color-avatar-bg-14;
}

.ui-kit-form .input-checkbox-avatar-14 input:focus + span::before {
  border-color: $color-avatar-bg-14;
}


// ui-kit:input-checkbox-avatar-15
.ui-kit-form .input-checkbox-avatar-15 input:checked + span::before {
  background-color: $color-avatar-bg-15;
  border-color: $color-avatar-bg-15;
}

.ui-kit-form .input-checkbox-avatar-15 input:focus + span::before {
  border-color: $color-avatar-bg-15;
}


// ui-kit:input-checkbox-avatar-16
.ui-kit-form .input-checkbox-avatar-16 input:checked + span::before {
  background-color: $color-avatar-bg-16;
  border-color: $color-avatar-bg-16;
}

.ui-kit-form .input-checkbox-avatar-16 input:focus + span::before {
  border-color: $color-avatar-bg-16;
}
