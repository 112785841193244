// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// column-branch-calendars-select
// column-branch-calendars-select:patient-care-select:ui-kit-skeleton


// column-branch-calendars-select
.column-branch-calendars-select {
  width: $size-small;
}


// column-branch-calendars-select:patient-care-select:ui-kit-skeleton
.column-branch-calendars-select .patient-care-select.ui-kit-skeleton {
  background-color: transparent;
  padding: 0
}
