// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content-table
// patient-care-feature-content-table:small
// patient-care-feature-content-table:link
// patient-care-feature-content-table:delete


// patient-care-feature-content-table
.patient-care-feature-content-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.patient-care-feature-content-table tr,
.patient-care-feature-content-table td {
  margin: 0;
  padding: 0;
}

.patient-care-feature-content-table td {
  border-bottom: 1px solid $color-grey-light;
}

.patient-care-feature-content-table td strong {
  display: block;
  overflow: hidden;
  padding: 10px 17px; // non-standard
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-feature-content-table td.last {
  padding-right: $spacing-large;
}

.patient-care-feature-content-table td.right {
  text-align: right;
}

.patient-care-feature-content-table thead td {
  background: $color-grey-lighter;
  color: $color-grey-dark;
  font-size: $font-size-small;
  font-weight: 700;
  overflow: hidden;
  padding-top: $spacing-smaller;
  padding-bottom: $spacing-smaller;
  padding-left: $spacing-smaller;
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-feature-content-table thead td.first {
  padding-left: $spacing-large;
}

.patient-care-feature-content-table thead td.extra {
  padding-left: 25px; // non-standard
}

.patient-care-feature-content-table tbody td {
  padding: 14px 8px; // non-standard
}

.patient-care-feature-content-table tbody td.first {
  padding-left: 16px; // non-standard
}


// patient-care-feature-content-table:small
.patient-care-feature-content-table .small {
  font-size: $font-size-small;
}


// patient-care-feature-content-table:link
.patient-care-feature-content-table .link {
  color: $color-accent;
  cursor: pointer;
}

.patient-care-feature-content-table .link svg * {
  fill: $color-accent;
}


// patient-care-feature-content-table:delete
.patient-care-feature-content-table .delete {
  border: 1px solid $color-grey-dark;
  border-radius: $border-radius-large;
  cursor: pointer;
  display: block;
  height: 22px; // non-standard
  overflow: hidden;
  position: relative;
  transition: border-color .3s, background-color .3s;
  margin-left: auto;
  width: 22px; // non-standard
}

.patient-care-feature-content-table .delete a {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.patient-care-feature-content-table .delete i {
  height: ($size-icon-medium / 2);
  width: ($size-icon-medium / 2);
}

.patient-care-feature-content-table .delete i svg * {
  fill: $color-grey-dark;
}

.patient-care-feature-content-table .delete:hover {
  background: $color-status-error;
  border-color: $color-status-error;
}

.patient-care-feature-content-table .delete:hover i svg * {
  fill: $color-white;
}
