// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature


// patient-care-feature
.patient-care-feature {
  height: calc(100vh - 80px);
  display: flex;
}

.patient-care-feature p {
  margin-bottom: 0;
}
