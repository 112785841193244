// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-tooltip


// reports-tooltip
.reports-tooltip {
  background: $color-primary;
  border-radius: $border-radius-small;
  padding: $spacing-small;
}

.reports-tooltip h4 {
  color: $color-white;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-small);
}

.reports-tooltip h4 + ul {
  margin-top: $spacing-small;
}

.reports-tooltip ul,
.reports-tooltip li {
  margin: 0;
  padding: 0;
}

.reports-tooltip li {
  color: $color-white;
  display: flex;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  list-style-type: none;
}

.reports-tooltip li span:nth-of-type(1) {
  flex: 1;
}

.reports-tooltip li span:nth-of-type(2) {
  margin-left: $spacing-smaller;
}

.reports-tooltip li + li {
  margin-top: $spacing-smaller;
}
