// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-body-header


// sidebar-body-header
.sidebar-body-header {
  position: relative;
}

.sidebar-body-header h3 + p {
  margin-top: $spacing-smaller;
}

.sidebar-body-header p + p {
  margin-top: round($font-size-large * $ratio-small);
}

.sidebar-body-header h3 {
  font-size: $font-size-large;
  font-weight: 900;
  line-height: round($font-size-large * $ratio-small);
}

.sidebar-body-header p {
  color: $color-grey-dark;
  font-size: $font-size-medium;
  line-height: round($font-size-medium * $ratio-medium);
}

.sidebar-body-header button {
  background-color: transparent;
  border: 0;
  color: $color-accent;
  cursor: pointer;
  font-size: $font-size-small;
  font-weight: 700;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: color .3s ease-in-out;
}
