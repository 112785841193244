// react-table.scss

// react-table
// react-table:spacing-medium


// react-table
.react-table {
  border: 0;
  border-spacing: 0;
  border-collapse: separate;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: $font-size-small;
  line-height: round($font-size-medium * $ratio-small);
  table-layout: fixed;
}

.react-table a {
  font-weight: 700;
}

.react-table p {
  margin: 0;
}

.react-table thead {
  position: relative;
  z-index: 2;
}

.react-table thead.sticky th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.react-table thead.grey th {
  background: $color-grey-lighter;
}

.react-table thead.white th {
  background: $color-white;
}

.react-table th,
.react-table td {
  padding: $spacing-smaller;
}

.react-table th {
  border-bottom: 1px solid $color-grey-light;
  color: $color-grey-dark;
  font-weight: 700;
  transition: color .3s ease-in-out;
}

.react-table th[title]:hover {
  color: $color-accent;
}

.react-table th div {
  align-items: center;
  display: flex;
}

.react-table th span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-table th i,
.react-table th svg {
  display: block;
  width: $size-icon-smaller;
  height: $size-icon-smaller;
}

.react-table th i {
  margin-left: ($spacing-smaller / 2);
}

.react-table th svg * {
  fill: $color-grey;
  transition: fill .3s ease-in-out;
}

.react-table th .sorting-up {
  color: $color-accent;
}

.react-table th .sorting-up svg *:nth-child(2) {
  fill: $color-accent;
}

.react-table th .sorting-down {
  color: $color-accent;
}

.react-table th .sorting-down svg *:nth-child(1) {
  fill: $color-accent;
}

.react-table th .summary {
  margin-top: $spacing-smaller;
  font-weight: 400;
  color: $color-primary;
}

.react-table th .summary i {
  height: 14px; // non-standard
  display: flex;
  margin: 0;
}

.react-table th .summary svg {
  width: $size-icon-medium;
  height: $size-icon-medium;
  flex-shrink: 0;
  margin-top: -1px; // non-standard
}

.react-table th .summary svg + svg {
  margin-left: $spacing-smaller;
}

.react-table th .summary svg * {
  fill: $color-grey-dark;
}

.react-table td {
  background: $color-white;
  border-bottom: 1px solid $color-grey-light;
  height: 63px; // non-standard
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-table td i.group {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.react-table td i.group span {
  margin: 3px ($spacing-smaller / 2); // non-standard
}

.react-table td i.group svg {
  display: block;
  height: $size-icon-medium;
  width: $size-icon-medium;
  flex-shrink: 0;
}

.react-table td i.group svg * {
  fill: $color-grey-dark;
}

.react-table td .nhs-service {
  display: flex;
  align-items: center;
}

.react-table td .nhs-service span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-table td .nhs-service i {
  margin-left: $spacing-smaller;
}

.react-table td .nhs-service i,
.react-table td .nhs-service i svg {
  display: block;
}

.react-table td .nhs-service i svg {
  height: $size-icon-medium;
  width: auto;
}

.react-table tr.highlighted td {
  background: $color-grey-lighter;
}


// react-table:spacing-medium
.react-table.spacing-medium th:first-of-type,
.react-table.spacing-medium td:first-of-type {
  padding-left: $spacing-medium;
}

.react-table.spacing-medium th:last-of-type,
.react-table.spacing-medium td:last-of-type {
  padding-right: $spacing-medium;
}
