@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-appointment-booking.patient-care-modal-full-height-holder {
    bottom: 0;
    position: absolute;
    right: 300px;
    top: 0;
    z-index: 9999;
}

.patient-care-appointment-booking.patient-care-modal-full-height-with-agenda-holder {
    bottom: 0;
    position: absolute;
    right: $size-large;
    top: 0;
    z-index: 9999;
}