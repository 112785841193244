// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// sidebar-body-section
// sidebar-body-section-empty


// sidebar-body-section
.sidebar-body-section {
  border-top: 1px solid $color-grey-light;
  padding: $spacing-medium 0;
}

.sidebar-body-section > div + div {
  margin-top: $spacing-medium;
}


// sidebar-body-section-empty
.sidebar-body-section-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-body-section-empty > .sidebar-body-content:last-of-type {
  flex: 1;
}
