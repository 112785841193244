// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-sidebar
// patient-care-feature-sidebar-background
// patient-care-feature-sidebar-wrapper
// patient-care-feature-sidebar-close
// patient-care-feature-sidebar-content
// patient-care-feature-sidebar:input-radio:indeterminate
// patient-care-feature-sidebar:input-checkbox:indeterminate


// patient-care-feature-sidebar
.patient-care-feature-sidebar {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.patient-care-feature-sidebar p {
  margin: 0;
}

.patient-care-feature-sidebar.header-shadow .sidebar-header {
  box-shadow: $box-shadow-normal;
}

.patient-care-feature-sidebar.footer-shadow .sidebar-footer {
  box-shadow: $box-shadow-top;
}


// patient-care-feature-sidebar-background
.patient-care-feature-sidebar-background {
  background: rgba($color-primary, .95);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}


// patient-care-feature-sidebar-wrapper
.patient-care-feature-sidebar-wrapper {
  background: $color-white;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 700px; // non-standard
}


// patient-care-feature-sidebar-close
.patient-care-feature-sidebar-close {
  position: absolute;
  right: $spacing-medium;
  top: $spacing-medium;
  z-index: 5;
}


// patient-care-feature-sidebar-content
.patient-care-feature-sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}


// patient-care-feature-sidebar:input-radio:indeterminate
// patient-care-feature-sidebar:input-checkbox:indeterminate
.patient-care-feature-sidebar .input-radio input[indeterminate='true'] + span::before,
.patient-care-feature-sidebar .input-checkbox input[indeterminate='true'] + span::before {
  border-color: $color-accent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KCiAgPHBhdGggZmlsbD0iIzQ3NjhmZCIgZD0iTTE4LDExSDZhMSwxLDAsMCwwLDAsMkgxOGExLDEsMCwwLDAsMC0yWiIgLz4KPC9zdmc+Cg==');
  background-position: 50% 50%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}
