// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-content


// patient-care-feature-content
.patient-care-feature-content {
  display: flex;
  flex-direction: column;
  transition: width .3s;
  width: 100%;
  overflow-x: auto;
}

.patient-care-feature-menu +
.patient-care-feature-content {
  width: calc(100% - 220px); // non-standard
}
