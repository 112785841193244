// patient-care-register-section
// screen-custom
// screen-custom:patient-care-register-section


// patient-care-register-section
.patient-care-register-section {
  padding: $spacing-large;
}

.patient-care-register-section h1,
.patient-care-register-section h2,
.patient-care-register-section h3,
.patient-care-register-section h4,
.patient-care-register-section p {
  margin: 0;
  padding: 0;
}

.patient-care-register-section img,
.patient-care-register-section svg {
  margin: 0;
  padding: 0;
  display: block;
}


// screen-custom
@media (max-width: $desktop-xsmall) {

  // screen-custom:patient-care-register-section
  .patient-care-register-section {
    padding-left: ($spacing-medium * 2);
    padding-right: ($spacing-medium * 2);
  }

}
