// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// skeleton-search-filter


// skeleton-search-filter
.skeleton-search-filter {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: $border-radius-large;
  height: $size-form-medium;
  position: relative;
  padding: 18px 18px 18px 42px; // non-standard
}

.skeleton-search-filter i,
.skeleton-search-filter svg {
  display: block;
  height: $size-icon-large;
  width: $size-icon-large;
}

.skeleton-search-filter i {
  position: absolute;
  top: 50%;
  left: 15px; // non-standard
  transform: translate(0, -50%);
}

.skeleton-search-filter svg * {
  fill: $color-grey-dark;
}
