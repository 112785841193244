// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:branches-list:cell-main
// react-table:branches-list:cell-small
// react-table:branches-list:cell-medium


// react-table:branches-list:cell-main
.react-table.branches-list tr th.cell-main,
.react-table.branches-list tr td.cell-main {
  width: 34%;
}


// react-table:branches-list:cell-small
.react-table.branches-list tr th.cell-small,
.react-table.branches-list tr td.cell-small {
  width: 100px; // non-standard
}


// react-table:branches-list:cell-medium
.react-table.branches-list tr th.cell-medium,
.react-table.branches-list tr td.cell-medium {
  width: 150px; // non-standard
}


.org-brn-with-breadcrumbs{
  padding: 32px 32px 0px 24px;
  background-color: #f7f7fa;
}

.org-brn-hdr{
  padding: 24px 32px 24px;
}
