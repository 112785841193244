@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-sidebar-list-item {
  color: $color-grey-dark;
  display: block;
  font-weight: 700;
  padding-bottom: $spacing-small;
}

.patient-care-sidebar-list-item.active {
  color: $color-accent;
}