// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// reports-basic-chart:revenue


// reports-basic-chart:revenue
.reports-basic-chart.revenue,
.reports-basic-chart.revenue .reports-basic-loader {
  height: 446px; // non-standard
}

.reports-basic-chart.revenue .recharts-xAxis,
.reports-basic-chart.revenue .recharts-yAxis {
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
}

.reports-basic-chart.revenue .recharts-xAxis text,
.reports-basic-chart.revenue .recharts-yAxis text {
  fill: $color-grey-dark !important;
}
