// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// card-reports-nominations-skeleton


// card-reports-nominations-skeleton
.card-reports-nominations-skeleton {
  background: $color-white;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-normal;
  padding: $spacing-medium;
}

.card-reports-nominations-skeleton > div:nth-of-type(1) {
  margin-top: ($spacing-smaller / 2);
}

.card-reports-nominations-skeleton > div:nth-of-type(2) {
  margin-top: $spacing-smaller + $spacing-smaller + ($spacing-smaller / 2);
}

.card-reports-nominations-skeleton > div:nth-of-type(3) {
  margin-top: ($spacing-smaller / 2) + $spacing-smaller + $spacing-smaller;
  margin-bottom: ($spacing-smaller / 2);
}
