// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// task-progress-bar
// task-progress-bar:status-completed
// task-progress-bar:status-partially-failed
// task-progress-bar:status-failed
// task-progress-bar:status-in-progress
// task-progress-bar:status-queued


// task-progress-bar
.task-progress-bar span,
.task-progress-bar span em {
  display: block;
  height: $size-icon-bull;
}

.task-progress-bar span {
  background: $color-grey-light;
  border-radius: $border-radius-small;
  overflow: hidden;
}

.task-progress-bar p {
  color: $color-grey-dark;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-smedium);
  margin: $spacing-smaller 0 0;
}


// task-progress-bar:status-completed
.task-progress-bar.status-completed span em {
  background: $color-status-success;
}


// task-progress-bar:status-partially-failed
.task-progress-bar.status-partially-failed span em {
  background: $color-category-five;
}


// task-progress-bar:status-failed
.task-progress-bar.status-failed span em {
  background: $color-status-error;
}


// task-progress-bar:status-in-progress
.task-progress-bar.status-in-progress span em {
  background: $color-accent;
}


// task-progress-bar:status-queued
.task-progress-bar.status-queued span em {
  background: transparent;
}
