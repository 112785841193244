@import '@patient-access/ui-kit/styles/utils/_variables.scss';

.patient-care-search-item-holder {
  cursor: pointer;
  line-height: $ratio-small;
  padding: $spacing-small;
  position: relative;
  transition: background-color .3s;
}

.patient-care-search-item-holder:last-child:after {
  background-color: transparent;
}

.patient-care-search-item-holder:hover {
  background-color: $color-grey-lighter;
}

.patient-care-search-item-holder.selected {
  background-color: $color-accent;
}

.patient-care-search-item-holder.selected span {
  color: $color-white;
}

.patient-care-search-item-holder.cancelled .patient-care-time,
.patient-care-search-item-holder.cancelled .patient-care-service,
.patient-care-search-item-holder.cancelled .patient-care-person,
.patient-care-search-item-holder.cancelled .patient-care-room {
  color: $color-grey;
}

.patient-care-search-item-holder.cancelled .patient-care-service {
  text-decoration: line-through;
}

.patient-care-search-item-holder.cancelled .patient-care-avatar-holder .ui-kit-avatar-one {
  background-color: $color-grey-light;
}

.patient-care-search-item-holder.cancelled .patient-care-avatar-holder span {
  text-transform: uppercase;
}

.patient-care-search-item-holder:after {
  background-color: $color-grey-light;
  bottom: 0;
  content: "";
  height: 1px;
  left: $spacing-small;
  position: absolute;
  right: 0;
  transform: translateY(50%);
  z-index: 1;
}

.patient-care-date-search-block .patient-care-search-item-holder:after {
  z-index: 0;
}

.patient-care-avatar-holder {
  left: $spacing-small;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.patient-care-info-search {
  padding-left: ($spacing-larger + $spacing-smaller);
}
