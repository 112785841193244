// dependencies
@import "@patient-access/ui-kit/styles/utils/_variables.scss";

// reports-content-nominations
// reports-content-nominations-lside
// reports-content-nominations-rside
// reports-content-prescriptions


// reports-content-nominations
.reports-content-nominations {
  display: flex;
}


// reports-content-nominations-lside
.reports-content-nominations-lside {
  flex-shrink: 0;
  width: 330px; // non-standard
}


// reports-content-nominations-rside
.reports-content-nominations-rside {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: $spacing-medium;
}


// reports-content-prescriptions
.reports-content-prescriptions {
  margin-top: $spacing-medium;
}


// reports-content-conversion-trend
.reports-content-conversion-trend {
  border-top: 1px solid $color-grey-light;
  display: flex;
  margin-top: $spacing-medium;
  padding-top: $spacing-medium;
}


// reports-content-conversion-trend-lside
.reports-content-conversion-trend-lside {
  flex-shrink: 0;
  width: 48%;
}


// reports-content-conversion-trend-rside
.reports-content-conversion-trend-rside {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 24px;
}
