@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

.patient-care-details-holder {
  background-color: $color-grey-lighter;
  min-height: 100%;
  padding: $spacing-large 0;
}

.patient-care-details-holder.data-changed {
  padding-bottom: $spacing-larger * 3 + $spacing-smaller / 2;
}

.patient-care-main .patient-care-details-holder .ui-kit-skeleton {
  background-color: transparent;
}

.patient-care-buttons-fixed-bottom {
  background-color: $color-white;
  bottom: 0;
  box-shadow: $box-shadow-top;
  left: 0;
  padding: $spacing-medium 0;
  position: fixed;
  right: 0;
  transition: right .3s;
  z-index: 1;
}

.patient-care-buttons-fixed-bottom.is-active-agenda {
  right: $size-small;
}

// tabs styles
.patient-care-app .ui-kit-tabs-list .ui-kit-tabs-item-selected,
.patient-care-app .ui-kit-tabs-list .ui-kit-tabs-item:hover {
  color: $color-accent;
}

.patient-care-app .ui-kit-tabs-list .ui-kit-tabs-item:after {
  background: $color-accent;
}

.patient-care-app .ui-kit-tabs-list .ui-kit-tabs-item {
  margin: 0 ($spacing-smaller / 2);

  @media #{$screen-desktop-medium} {
    margin: 0 ($spacing-smaller - 2px);
  }
}

// table styles
.patient-care-main .ui-kit-skeleton {
  background-color: $color-white;
  padding: $spacing-medium $spacing-large;
}

// table height styles
.patient-care-table-wrap-with-filters,
.patient-care-table-wrap-with-2-filters,
.patient-care-table-wrap-with-3-filters {
  background-color: $color-white;
  overflow: auto hidden;
  position: relative;

  @media (min-width: $desktop-small) {
    overflow: auto;
  }
}

.patient-care-table-wrap-with-filters .ui-kit-skeleton,
.patient-care-table-wrap-with-filters {
  height: calc(100vh - 180px);
}

.patient-care-table-wrap-with-2-filters .ui-kit-skeleton,
.patient-care-table-wrap-with-2-filters {
  height: calc(100vh - 248px);
}

.with-breadcrumbs .patient-care-table-wrap-with-2-filters .ui-kit-skeleton,
.with-breadcrumbs .patient-care-table-wrap-with-2-filters {
  height: calc(100vh - 228px);
}

.patient-care-table-wrap-with-3-filters .ui-kit-skeleton,
.patient-care-table-wrap-with-3-filters {
  height: calc(100vh - 296px);
}

.patient-care-table-wrap-with-filters .patient-care-table-holder,
.patient-care-table-wrap-with-2-filters .patient-care-table-holder,
.patient-care-table-wrap-with-3-filters .patient-care-table-holder {
  height: 100%;
  overflow-y: auto;
}

.patient-care-table-holder th,
.patient-care-table-holder td {
  font-size: $font-size-small;
}

.patient-care-table-holder th,
.patient-care-table-holder td {
  font-size: $font-size-small;
}

.patient-care-um-table .patient-care-cell {
  border-bottom: 1px solid $color-grey-light;
  border-right-width: 0;
  padding: ($spacing-small - 3px) $spacing-small;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patient-care-um-table .patient-care-cell.patient-care-first-cell {
  font-weight: 700;
  padding-left: $spacing-large;
}

.patient-care-table-link {
  color: $color-accent;
  cursor: pointer;
  font-weight: 700;
}

.patient-care-closed-days .patient-care-separator-small:last-child {
  margin-bottom: $spacing-medium;
}

.patient-care-days-wrap .patient-care-separator-small:last-child {
  display: none;
}

.patient-care-calendars-table .patient-care-custom-table-cell:last-child {
  text-align: right;
}

.patient-care-password-rules {
  margin-top: $spacing-small;
}

.patient-care-password-rules p {
  margin: 0;
  font-size: $font-size-small;
  line-height: round($font-size-small * $ratio-small);
  color: $color-status-error;
}

.patient-care-password-rules p + p {
  margin-top: $spacing-smaller;
}
