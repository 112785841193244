// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// patient-care-feature-menu


// patient-care-feature-menu
.patient-care-feature-menu {
  border-right: 1px solid $color-grey-light;
  flex-shrink: 0;
  width: 220px; // non-standard
}
