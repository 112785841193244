// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';

// react-table:react-table-services-list
// react-table:react-table-services-list:input-radio


// react-table:react-table-services-list
.react-table.react-table-services-list th,
.react-table.react-table-services-list td {
  padding-left: 0;
  padding-right: 0;
}

.react-table.react-table-services-list td.cell-main .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.react-table.react-table-services-list td.cell-main .container .input-radio {
  overflow: hidden;
}

.react-table.react-table-services-list td.cell-main .container a {
  margin-left: $spacing-smaller;
}


// react-table:react-table-services-list:input-radio
.react-table.react-table-services-list .input-radio label {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  line-height: 20px; // non-standard
  padding-left: 28px; // non-standard
}

.react-table.react-table-services-list .input-radio label span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-table.react-table-services-list .input-radio label span::before {
  top: 0;
}

.react-table.react-table-services-list .input-radio label i {
  margin-left: $spacing-smaller;
}

.react-table.react-table-services-list .input-radio label i,
.react-table.react-table-services-list .input-radio label i svg {
  display: block;
}

.react-table.react-table-services-list .input-radio label i svg {
  height: $size-icon-medium;
  width: auto;
}
