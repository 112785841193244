// dependencies
@import '@patient-access/ui-kit/styles/utils/_variables.scss';
@import 'helpers/_variables.scss';

// calendar-filters-title
// calendar-filters-title:button-add
// calendar-filters-title:button-toggle


// calendar-filters-title
.calendar-filters-title {
  padding-right: ($size-icon-larger);
  position: relative;
}

.calendar-filters-title:hover {
  padding-right: ($size-icon-larger * 2) + $spacing-smaller;
}

.calendar-filters-title label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-filters-title span {
  font-weight: 900;
}


// calendar-filters-title:button-add
.calendar-filters-title .button-add {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: $size-icon-larger + ($spacing-smaller / 2);
}

.calendar-filters-title:hover .button-add {
  opacity: 1;
}


// calendar-filters-title:button-toggle
.calendar-filters-title .button-toggle {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}
